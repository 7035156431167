import {
  Button,
  Col,
  Modal,
  Radio,
  RadioChangeEvent,
  Row,
  Space,
  Switch,
  Typography,
} from "antd";
import React, { useEffect, useState } from "react";
import "./AssessmentTableStyles.scss";
import TextArea from "antd/lib/input/TextArea";
import debounce from "lodash/debounce";
import NoData from "components/UI/NoData/NoData";

const ratingOptions = [
  { label: "", value: "Low" },
  { label: "", value: "Medium" },
  { label: "", value: "High" },
];

const colors = {
  Experiences: "#990000CC",
  Traits: "#2E4A79E5",
  Drivers: "#008D8B",
};

interface ExperienceItem {
  title: string;
  level: string;
  comment: string;
}

interface PropsType {
  open: boolean;
  setOpen: any;
  view: string;
  setView: any;
  candidateAssessment: any;
  checkIfDataIsPresent: (type: string) => boolean;
}

const GetRow = ({
  rowData,
  index,
  view,
  TableData,
  setTableData,
}: {
  rowData: ExperienceItem;
  index: number;
  view: string;
  TableData: ExperienceItem[];
  setTableData: any;
}) => {
  const { title, level, comment } = rowData;
  console.log("here is row data", rowData);

  // const onRadioChange = (index: number, value: string) => {
  //   const updatedData = [...TableData];
  //   updatedData[index].level = value;
  //   setTableData(updatedData);
  // };

  // const onCommentChange = (index: number, value: string) => {
  //   const updatedData = [...TableData];
  //   updatedData[index].comment = value;
  //   setTableData(updatedData);
  // };

  return (
    <>
      <Col
        span={5}
        style={{
          backgroundColor: colors[view],
          color: "white",
          height: "72px",
          borderRadius: "8px",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          textAlign: "center",
          fontSize: "12px",
          lineHeight: "16px",
          padding: "0px 3px",
        }}
      >
        {title}
      </Col>
      <Col span={8} style={{ marginTop: "-6px" }}>
        <Radio.Group
          className={`radio-group-with-gap ${view}`}
          size="large"
          options={ratingOptions}
          // onChange={(e) => onRadioChange(index, e.target.value)}
          value={level}
          optionType="button"
          buttonStyle="solid"
        />
      </Col>
      {/* <Col span={11}>
        <TextArea
          rows={3}
          placeholder="Experiences"
          bordered={false}
          value={comment}
          maxLength={110}
          // onChange={(e) => onCommentChange(index, e.target.value)}
          style={{
            minHeight: 60,
            width: "auto !important",
            resize: "none",
            backgroundColor: colors[view],
            borderRadius: "8px",
            color: "white",
            fontSize: "14px",
            lineHeight: "16px",
            display: "flex",
            padding: "2px 8px",
            flex: " 1 0 0",
          }}
        />
      </Col> */}
      <Col span={11}>
        <TextArea
          // className="scroll-bar"
          rows={3}
          // placeholder="Comments"
          bordered={false}
          value={comment}
          maxLength={view == "Drivers" ? 280 : 180}
          // onChange={(e) => onCommentChange(index, e.target.value)}
          disabled
          style={{
            cursor: "default",
            minHeight: view == "Drivers" ? 122 : 72,
            width: "auto !important",
            resize: "none",
            backgroundColor: colors[view],
            borderRadius: "8px",
            color: "white",
            fontSize: "12px",
            lineHeight: "16px",
            display: "flex",
            padding: "4px 8px",
            flex: " 1 0 0",
            overflow: "hidden",
            marginBottom: view == "Drivers" ? 10 : 0,
          }}
        />
      </Col>
    </>
  );
};

const AssessmentTableModal: React.FC<PropsType> = ({
  open,
  setOpen,
  view,
  candidateAssessment,
  setView,
  checkIfDataIsPresent,
}) => {
  // const [confirmLoading, setConfirmLoading] = useState(false);
  const [TableData, setTableData] = useState([]);

  useEffect(() => {
    if (view === "Experiences") {
      setTableData(candidateAssessment.experience);
    } else if (view === "Traits") {
      setTableData(candidateAssessment.traits);
    } else {
      setTableData(candidateAssessment.drivers);
    }
  }, [view]);

  // const handleOk = () => {
  //   setConfirmLoading(true);
  //   setTimeout(() => {
  //     setOpen(false);
  //     setConfirmLoading(false);
  //   }, 2000);
  // };

  const handleCancel = () => {
    setOpen(false);
  };

  // const hideAssessment = (checked: boolean) => {
  //   console.log(`switch to ${checked}`);
  // };

  const switchView = (toView: string) => {
    setView(toView);
  };

  const viewButtonsvisible = () => {
    let show = 3;
    if (candidateAssessment?.hideExperience === true) --show;
    if (candidateAssessment?.hideTraits === true) --show;
    if (candidateAssessment?.hideDrivers === true) --show;

    if (show < 2) {
      return false;
    } else return true;
  };

  return (
    <>
      <Modal
        width={900}
        title={<span style={{ fontSize: "18px" }}>{view}</span>}
        centered
        open={open}
        // onOk={handleOk}
        onCancel={handleCancel}
        // footer={ <>
        //   <Space>
        //     <Typography>Hide {view}</Typography>
        //     <Switch size="small" onChange={hideAssessment} />
        //   </Space>

        //   <Space>
        //     <Button key="back" shape="round" onClick={handleCancel}>
        //       Return
        //     </Button>

        //     <Button
        //       key="submit"
        //       type="primary"
        //       shape="round"
        //       loading={confirmLoading}
        //       onClick={handleOk}
        //     >
        //       Submit
        //     </Button>
        //   </Space>
        // </>}
        footer={null}
      >
        {viewButtonsvisible() && (
          <Row className="view-switches">
            {!candidateAssessment?.hideExperience &&
              checkIfDataIsPresent("experience") && (
                <Button
                  className={view === "Experiences" && "Experience"}
                  onClick={() => switchView("Experiences")}
                >
                  Experiences
                </Button>
              )}
            {!candidateAssessment?.hideTraits &&
              checkIfDataIsPresent("traits") && (
                <Button
                  className={view === "Traits" && "Traits"}
                  onClick={() => switchView("Traits")}
                >
                  Traits
                </Button>
              )}
            {!candidateAssessment?.hideDrivers &&
              checkIfDataIsPresent("drivers") && (
                <Button
                  className={view === "Drivers" && "Drivers"}
                  onClick={() => switchView("Drivers")}
                >
                  Drivers
                </Button>
              )}
          </Row>
        )}

        <Row className="table-row">
          {TableData?.length > 0 ? (
            <>
              <Col span={24} offset={6}>
                <Space size={47} style={{ marginBottom: "6px" }}>
                  <Typography>Low</Typography>
                  <Typography>Medium</Typography>
                  <Typography style={{ marginLeft: "-6px" }}>High</Typography>
                  <Typography style={{ marginLeft: "4px" }}>
                    Comments
                  </Typography>
                </Space>
              </Col>

              {TableData?.map((row, index) => {
                return (
                  <GetRow
                    key={index}
                    rowData={row}
                    index={index}
                    view={view}
                    TableData={TableData}
                    setTableData={setTableData}
                  />
                );
              })}
            </>
          ) : (
            <NoData message={`No required ${view} added`} />
          )}
        </Row>
      </Modal>
    </>
  );
};

export default AssessmentTableModal;
