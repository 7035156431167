import { Avatar, Button, Modal } from "antd";
import MyModal from "components/UI/Modal";
import React, { FC } from "react";
import { Link, useLocation } from "react-router-dom";
import "./style.scss";

interface InitialProps {
  showMatchModal: boolean;
  handleCancel: any;
  selectedCandidatesFromStore: any;
  dislikedCandidatesFromStore: any;
  selectedCandidate: any;
  showChatModal: any;
  chatNotifications: any;
  machesCandidatesFromStore: any;
  data: any;
  clickOnNotification: any;
  jobId: String;
  match?: any;
}
interface Notification {
  _id: string;
  userId: string;
  companyId: string;
  jobId: string;
  matchId: string;
  createdAt: any;
  updatedAt: any;
  isRead: boolean;
  type: string;
  notificationHeader: string;
  notificationBody: string;
  candidate: any;
  job: any; // job shuld contain the data of companies as well
  tabActivity: string; // this key will be generated by the backend (depending where this job came from)
}
const MatchModal: FC<InitialProps> = ({
  data,
  chatNotifications,
  showChatModal,
  showMatchModal,
  handleCancel,
  selectedCandidate,
  selectedCandidatesFromStore,
  dislikedCandidatesFromStore,
  machesCandidatesFromStore,
  clickOnNotification,
  jobId,
  match,
}) => {
  const location = useLocation();
  const isViewCandidateBtn = !location?.pathname?.includes("/view/");
  return (
    <>
      {showMatchModal && (
        <div className="model-close-btn">
          <Modal
            centered={true}
            style={{ borderRadius: 30 }}
            visible={showMatchModal}
            // onOk={handleOk}
            footer={null}
            onCancel={handleCancel}
          >
            <div className="bg-img">
              <p>It’s a Match!</p>
            </div>
            <div
              style={{ textAlign: "center", padding: "3rem 0" }}
              className="match-modal-btn"
            >
              <Avatar.Group
                maxCount={2}
                size="large"
                maxStyle={{ color: "#f56a00", backgroundColor: "#fde3cf" }}
              >
                <Avatar
                  src={
                    (machesCandidatesFromStore &&
                      machesCandidatesFromStore[0]?.company?.logoUrl) ||
                    (dislikedCandidatesFromStore &&
                      dislikedCandidatesFromStore[0]?.company?.logoUrl)
                  }
                  style={{ height: 120, width: 120 }}
                />

                <Avatar
                  src={selectedCandidate && selectedCandidate?.profilePicture}
                  style={{
                    backgroundColor: "#f56a00",
                    height: 120,
                    width: 120,
                    marginLeft: -16,
                  }}
                >
                  K
                </Avatar>
              </Avatar.Group>
              <br />
              <p
                style={{
                  padding: "1rem 3rem",
                  fontSize: "24px",
                  fontWeight: 600,
                  color: "#2E4A79",
                }}
              >
                You can chat now directly with the candidate. It's that simple!
              </p>
              <div
                style={{ display: "flex", justifyContent: "center", gap: 30 }}
              >
                {showChatModal && (
                  <MyModal
                    titleType="button"
                    enableCustomBtnIcon={true}
                    customBtnIcon={
                      chatNotifications.find(
                        (notification: Notification) =>
                          notification.matchId === selectedCandidate?._id
                      ) ? (
                        <Button
                          className="Modal-sms-btn"
                          onClick={() =>
                            clickOnNotification(selectedCandidate?._id, "chat")
                          }
                          style={{
                            fontSize: "16px",
                            backgroundColor: "#DAB137",
                            color: "white",
                          }}
                        >
                          Send a message
                        </Button>
                      ) : (
                        <Button
                          className="Modal-sms-btn"
                          style={{
                            fontSize: "16px",
                            backgroundColor: "#DAB137",
                            color: "white",
                          }}
                        >
                          Send a message
                        </Button>
                      )
                    }
                    onOK={() => {}}
                    width={700}
                    footer={null}
                    ModalTytle="huu"
                    titleBtnClass="ant-btn-info"
                    record={match ? match : selectedCandidate}
                    // record={
                    //   machesCandidatesFromStore[
                    //     machesCandidatesFromStore?.length - 1
                    //   ]
                    // }
                    candidate={selectedCandidate}
                    company={data?.getCompanyFromJob}
                  >
                    <div
                      style={{
                        paddingTop: 35,
                        paddingBottom: 35,
                      }}
                    ></div>
                  </MyModal>
                )}
                {isViewCandidateBtn && (
                  <Link
                    to={{
                      pathname: `/candidate-listings/view/${
                        // machesCandidatesFromStore[
                        //   machesCandidatesFromStore?.length - 1
                        // ]?.candidateListing?._id
                        selectedCandidate?._id
                      }`,
                      state: {
                        company: data?.getCompanyFromJob,
                        matchId:
                          machesCandidatesFromStore[
                            machesCandidatesFromStore?.length - 1
                          ]?._id,
                        // from: machesCandidatesFromStore,
                        jobId: jobId,
                        jobName:
                          machesCandidatesFromStore[
                            machesCandidatesFromStore?.length - 1
                          ]?.jobListing?.title,
                        companyName:
                          machesCandidatesFromStore[
                            machesCandidatesFromStore?.length - 1
                          ]?.company?.name,
                        // tabActiveKey,
                      },
                    }}
                  >
                    <Button
                      className="Modal-sms-btn"
                      style={{
                        fontSize: "16px",
                        backgroundColor: "#DAB137",
                        color: "white",
                      }}
                    >
                      View candidate
                    </Button>
                  </Link>
                )}
              </div>
            </div>
          </Modal>
        </div>
      )}
    </>
  );
};

export default MatchModal;
