import React, { useEffect, useState } from "react";
import { Button, Tooltip, Table, Space, Avatar } from "antd";
import Column from "antd/lib/table/Column";
import moment from "moment";
import { Link } from "react-router-dom";
import { DislikeOutlined, LikeOutlined, UserOutlined } from "@ant-design/icons";
import Buttons from "../../../../components/UI/Buttons";
import "./matchesTabs.css";
interface CandidateListingsProps {
  jobId: any;
  newCandidates: any;
  candidateLikeOrDisLikeHandler: any;
  setSelectedCandidate: any;
  tabActiveKey: string;
  handleChanage: any;
}

const NewCandidatesListing: React.FC<CandidateListingsProps> = ({
  jobId,
  newCandidates,
  candidateLikeOrDisLikeHandler,
  setSelectedCandidate,
  tabActiveKey,
  handleChanage,
}) => {
  console.log(newCandidates);
  return (
    <Table
      scroll={newCandidates?.length > 0 ? { x: 1300 } : {}}
      onChange={handleChanage}
      showSorterTooltip={false}
      locale={{
        emptyText: (
          <span>
            <p style={{ marginTop: "20px" }}>
              <Avatar icon={<UserOutlined />} />
            </p>
            <p>No candidate yet</p>
          </span>
        ),
      }}
      dataSource={newCandidates}
      size="small"
    >
      <Column
        title="Application"
        // fixed="left"
        // sorter={true}
        sortDirections={["descend", "ascend", "descend"]}
        defaultSortOrder="descend"
        sorter={(a: any, b: any) => {
          if (!a.updatedAt) a.updatedAt = "";
          if (!b.updatedAt) b.updatedAt = "";
          return a?.updatedAt - b?.updatedAt;
        }}
        dataIndex="updatedAt"
        key="updatedAt"
        render={(updatedAt, record: any) => (
          <>{moment(parseInt(updatedAt)).format("DD/MM/YY")}</>
        )}
      />
      <Column
        title="First name"
        // sorter={true}
        sortDirections={["descend", "ascend", "descend"]}
        // defaultSortOrder="descend"
        sorter={(a: any, b: any) => {
          if (!a.candidateListing?.firstName) a.candidateListing.firstName = "";
          if (!b.candidateListing?.firstName) b.candidateListing.firstName = "";
          return a.candidateListing?.firstName?.localeCompare(
            b.candidateListing?.firstName
          );
        }}
        dataIndex="candidateListing"
        key="First name"
        render={(candidate, record: any) => (
          <div className="ellipse">
            {/* <Link to={`/candidate-listings/view/${candidate._id}`}>
                {candidate.firstName + ' ' + candidate.lastName}
              </Link> */}
            <Link
              to={{
                pathname: `/candidate-listings/view/${candidate?._id}`,
                state: {
                  matchId: record?._id,
                  from: newCandidates.filter((c: any) => !c.recruiterAction),
                  jobId: jobId,
                  jobName: record?.jobListing?.title,
                  companyName: record?.company?.name,
                  tabActiveKey,
                  candidate,
                  record,
                },
              }}
            >
              {candidate?.firstName}
            </Link>
          </div>
        )}
      />
      <Column
        title="Last name"
        // sorter={true}

        dataIndex="candidateListing"
        key="Last name"
        sortDirections={["descend", "ascend", "descend"]}
        // defaultSortOrder="descend"
        sorter={(a: any, b: any) => {
          if (!a.candidateListing?.lastName) a.candidateListing.lastName = "";
          if (!b.candidateListing?.lastName) b.candidateListing.lastName = "";
          return a.candidateListing?.lastName.localeCompare(
            b.candidateListing?.lastName
          );
        }}
        render={(candidate, record: any) => (
          <div className="ellipse">
            {/* <Link to={`/candidate-listings/view/${candidate._id}`}>
                {candidate.firstName + ' ' + candidate.lastName}
              </Link> */}
            <Link
              to={{
                pathname: `/candidate-listings/view/${candidate?._id}`,
                state: {
                  matchId: record._id,
                  from: newCandidates.filter((c: any) => !c.recruiterAction),
                  jobId: jobId,
                  jobName: record?.jobListing?.title,
                  companyName: record?.company?.name,
                  tabActiveKey,
                },
              }}
            >
              {candidate?.lastName}
            </Link>
          </div>
        )}
      />
      {/* <Column
          title='Source'
          dataIndex='media'
          key='_id'
          render={media => <>{media}</>}
        /> */}
      <Column
        title="Position"
        width="300px"
        sortDirections={["descend", "ascend", "descend"]}
        // defaultSortOrder="descend"
        sorter={(a: any, b: any) => {
          if (!a.candidateListing?.position) a.candidateListing.position = "";
          if (!b.candidateListing?.position) b.candidateListing.position = "";
          return a.candidateListing?.position.localeCompare(
            b.candidateListing?.position
          );
        }}
        dataIndex="candidateListing"
        key="position"
        render={(candidate) => (
          <div
            className="ellipse"
            data-bs-toggle="tooltip"
            data-bs-placement="top"
            title={candidate?.experiences?.length > 0 && candidate?.position}
          >
            {candidate?.experiences?.length > 0 && candidate?.position}
          </div>
        )}
      />
      <Column
        title="Company"
        width="300px"
        // sorter={true}
        sortDirections={["descend", "ascend", "descend"]}
        // defaultSortOrder="descend"
        sorter={(a: any, b: any) => {
          const companyA = a?.candidateListing?.experiences?.[0]?.company || "";
          const companyB = b?.candidateListing?.experiences?.[0]?.company || "";

          return companyA.localeCompare(companyB);
        }}
        dataIndex="candidateListing"
        key="company"
        render={(candidate) => (
          <div
            className="ellipse"
            data-bs-toggle="tooltip"
            data-bs-placement="top"
            title={
              candidate?.experiences?.length > 0 &&
              candidate?.experiences[0]?.company
            }
          >
            {candidate?.experiences?.length > 0 &&
              candidate?.experiences[0]?.company}
          </div>
        )}
      />
      <Column
        title="Benchmark"
        // width="300px"
        sortDirections={["descend", "ascend", "descend"]}
        // defaultSortOrder="descend"
        sorter={(a: any, b: any) => {
          if (!a.benchmark) {
            a.benchmark = "";
          }
          if (!b.benchmark) {
            b.benchmark = "";
          }
          return a?.benchmark?.localeCompare(b?.benchmark);
        }}
        // sorter={true}
        dataIndex="benchmark"
        key="candidateListing?._id"
        render={(candidate, record: any) =>
          record?.benchmark ? (
            <div className="benchmark-plaque">{record?.benchmark}</div>
          ) : null
        }
      />
      {/* <Column
          title="Email"
          dataIndex="candidateListing"
          key="candidateListing._id"
          render={(candidate) => <>{candidate.user.email}</>}
        /> */}
      <Column
        title="Actions"
        width="100"
        fixed="right"
        dataIndex="candidateListing"
        key="candidateListing._id"
        render={(candidate, record: any) => (
          <>
            <Space>
              <Buttons
                type="btn-like mt-1"
                onClick={() => {
                  candidateLikeOrDisLikeHandler(record?._id, "like", null);
                  setSelectedCandidate(candidate);
                }}
                // toolTipTitle="Like"
              />
              <Buttons
                type="btn-dislike mt-1"
                onClick={() => {
                  candidateLikeOrDisLikeHandler(
                    record?._id,
                    "dislike",
                    null,
                    "new-candidate-disliked"
                  );
                  setSelectedCandidate(candidate);
                }}
                // toolTipTitle="Dislike"
              />
              {/* <Tooltip title="Like candidate">
                  <Button
                    className="ant-btn-green"
                    onClick={() => {
                      candidateLikeOrDisLikeHandler(record._id, "like", null);
                      setSelectedCandidate(candidate);
                    }}
                    shape="circle"
                    icon={<LikeOutlined />}
                  ></Button>
                </Tooltip> */}
              {/* <Tooltip title="Dislike candidate">
                  <Button
                    className="ant-btn-red"
                    shape="circle"
                    onClick={() =>
                      // ANCHOR: Set lastAction here
                      candidateLikeOrDisLikeHandler(
                        record._id,
                        "dislike",
                        null,
                        "new-candidate-disliked"
                      )
                    }
                    icon={<DislikeOutlined />}
                  ></Button>
                </Tooltip> */}
            </Space>
          </>
        )}
      />
    </Table>
  );
};

export default NewCandidatesListing;
