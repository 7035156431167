import { EditOutlined, PlusOutlined } from "@ant-design/icons";
import { Button, Card, Tooltip } from "antd";
import TextArea from "antd/lib/input/TextArea";
import Paragraph from "antd/lib/typography/Paragraph";
import Title from "antd/lib/typography/Title";
import React from "react";

interface SummaryCardPops {
  recruiter: any;
  setSummary: any;
  summary: string;
  isEditing: boolean;
}

const SummaryCard = ({
  recruiter,
  setSummary,
  summary,
  isEditing,
}: SummaryCardPops) => {
  const handleChange = (e: any) => {
    setSummary(e.target.value);
  };
  if (recruiter) {
    return (
      <Card bordered>
        <div className="summary mt-0 mb-0 pb-2">
          <Title
            level={4}
            className="mt-0 mb-0 primary-text"
            style={{ fontSize: "16px" }}
          >
            Summary{" "}
          </Title>
          <TextArea
            disabled={!isEditing}
            onChange={handleChange}
            value={summary}
            rows={
              summary?.length > 400
                ? 6
                : summary?.length > 310
                ? 5
                : summary?.length > 240
                ? 4
                : summary?.length > 177
                ? 3
                : summary?.length > 95
                ? 2
                : 1
            }
            placeholder="Please write your summary here."
            style={{
              border: "0px",
              boxShadow: "none",
              cursor: "auto",
              backgroundColor: "white",
              color: "black",
              resize: "none",
            }}
          />
        </div>
      </Card>
    );
  } else {
    return null;
  }
};

export default SummaryCard;
