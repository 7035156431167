import React, { useState, useEffect, useContext } from "react";
import { Avatar } from "antd";
import EDU_PLACEHOLDER from "assets/icons/DetailedProfile/educationPlaceholder.svg";
import EducationForm from "./EducationForm";
import { EditContext } from "../../../Context/EditContext";
import SameEducationEditForm from "./SameEducationEditForm";
import _ from "lodash";

interface PropsType {
  education: any;
  isRecruiterPage?: boolean;
  candidateId?: any;
}

const calculateYearsSpent = (
  startYear: string,
  endYear: string | null,
  isCurrent: boolean
): number => {
  if (!startYear) return 0;
  const start = parseInt(startYear.split("-")[0], 10);
  const end = isCurrent
    ? new Date().getFullYear()
    : endYear
    ? parseInt(endYear.split("-")[0], 10)
    : start;
  return end - start;
};

const calculateTotalYearsSpent = (experience: any): number => {
  const totalYears = calculateYearsSpent(
    experience.startYear,
    experience.endYear,
    experience.current
  );

  return totalYears;
};

const SingleEducation: React.FC<PropsType> = ({
  education,
  isRecruiterPage,
  candidateId,
}) => {
  const [imgError, setImgError] = useState(false);
  const [logo, setLogo] = useState(education?.logo);
  const editContext = useContext(EditContext);

  useEffect(() => {
    if (!education?.logo) {
      setImgError(true);
    }
  }, [education]);

  const handleImageError = () => {
    setImgError(true);
    return false;
  };

  const Styles = {
    root: {
      paddingLeft: 0,
      display: "inline-block",
      maxWidth: 400,
      flex: "85%",
    },
    wrapper: {
      display: "flex",
      justifyContent: "space-between",
      marginBottom: 26,
      marginTop: "-8px",
    },
    year: {
      fontSize: 14,
      marginTop: 0,
      marginBottom: 0,
      color: "#495057",
    },
    position: {
      fontWeight: 500,
      fontSize: 14,
      marginTop: 0,
      marginBottom: 0,
      color: "#495057",
    },
    company: {
      fontWeight: 600,
      fontSize: 16,
      marginTop: 0,
      marginBottom: 0,
      color: "black",
    },
    location: {
      fontWeight: 400,
      fontSize: 14,
      marginTop: 0,
      marginBottom: 0,
      color: "#495057",
    },
    avatar: {
      backgroundColor: "#F5F7F9",
    },
    placeholderAvatar: {
      backgroundColor: "#ccc",
      marginRight: "2px",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    totalYears: {
      fontSize: "14px",
      fontWeight: "400",
      color: "rgba(0,0,0,.5)",
      marginTop: "-1px",
    },
  };

  const totalYearsSpent = calculateTotalYearsSpent(education);

  if (!education?.mergedData) {
    education.mergedData = [education];
  }
  if (education?.mergedData && !editContext?.edit) {
    education.mergedData = education.mergedData.sort((s: any) =>
      s?.current ? -1 : 1
    );
  }

  if (education.current) {
    education.endYear = "Present";
  }

  useEffect(() => {
    if (education?.logo?.length > 4) {
      setImgError(false);
      setLogo(education?.logo);
    }
  }, [education?.logo]);

  education = _.cloneDeep(education);

  return (
    <>
      {education?.mergedData?.map((education: any, i: any) => {
        if (i > 0) {
          return null;
        }
        return (
          <div
            key={i}
            style={{
              ...Styles.wrapper,
              flexDirection: editContext?.edit ? "column" : "row",
              alignItems: editContext?.edit ? "start" : "end",
            }}
          >
            {!editContext?.edit && (
              <div style={{ ...Styles.root, wordWrap: "break-word" }}>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "start",
                    gap: "16px",
                  }}
                >
                  {!imgError ? (
                    <Avatar
                      style={{
                        ...Styles.avatar,
                        backgroundImage: `url(${logo})`,
                        backgroundSize: "contain",
                        backgroundPosition: "center",
                        backgroundRepeat: "no-repeat",
                        objectFit: "contain",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        marginTop: "3px",
                        margin: "5px",
                        flexShrink: "0",
                      }}
                      size={48}
                      // src={logo}
                      onError={handleImageError}
                    />
                  ) : (
                    <span
                      style={{
                        ...Styles.placeholderAvatar,
                        flexShrink: 0,
                        height: "48px",
                        width: "48px",
                        margin: "5px",
                        backgroundColor: "#f9f6f7",
                        borderRadius: "50%",
                      }}
                    >
                      <img
                        style={{
                          height: "30px",
                          width: "30px",
                          background: "#f9f6f7",
                        }}
                        src={EDU_PLACEHOLDER}
                        alt="placeholder"
                      />
                    </span>
                  )}
                  <div style={{ marginLeft: "-6px" }}>
                    <p style={Styles.company}>{education?.schoolName}</p>
                    {
                      <>
                        <span
                          style={{
                            color: "rgba(0,0,0,0.5)",
                            fontSize: "14px",
                            fontWeight: "400",
                          }}
                        >
                          {education.startYear} {education.endYear && "-"}{" "}
                          {education.endYear}
                        </span>
                        <p style={Styles.position}>{education?.degree}</p>
                        <div className="">
                          <span
                            style={{
                              color: "#424952",
                              fontSize: "14px",
                              fontWeight: "400",
                            }}
                          >
                            {education?.location?.city &&
                            education?.location?.country
                              ? `${education?.location?.city}, ${education?.location?.country}`
                              : education?.location?.city &&
                                !education?.location?.country
                              ? `${education?.location?.city}`
                              : ""}
                          </span>
                        </div>
                      </>
                    }
                  </div>
                </div>
              </div>
            )}

            {editContext?.edit ? (
              <>
                <EducationForm
                  education={education}
                  candidateId={candidateId}
                />
                {education?.mergedData?.length > 0 &&
                  education.mergedData.map((d: any, i: any) => {
                    d = _.cloneDeep(d);
                    if (d?._id == education?._id) {
                      return null;
                    }
                    return (
                      <SameEducationEditForm key={i} education={d} index={i} />
                    );
                  })}
              </>
            ) : (
              <></>
            )}
            <div style={{ flex: 1 }} />
          </div>
        );
      })}

      {education?.mergedData?.length > 1 && !editContext?.edit
        ? education?.mergedData?.map((d: any, i: any) => {
            if (i == 0) {
              return null;
            }
            return (
              <div
                style={{
                  display: "flex",
                  gap: "10px",
                  marginTop: "12px",
                  marginLeft: "66px",
                  marginBottom: "10px",
                }}
                key={i}
              >
                <div style={{ marginTop: "10px" }}>
                  <div
                    style={{
                      height: "11px",
                      width: "11px",
                      backgroundColor: "#418394",
                      borderRadius: "100%",
                    }}
                  />
                  <div
                    style={{
                      height: "36px",
                      width: "1.2px",
                      backgroundColor: "#418394",
                      marginTop: "-4px",
                      marginBottom: "-4px",
                      marginLeft: "4.6px",
                    }}
                  />
                  <div
                    style={{
                      height: "11px",
                      width: "11px",
                      backgroundColor: "#418394",
                      borderRadius: "100%",
                    }}
                  />
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "2px",
                    marginTop: "1px",
                    marginLeft: "16px",
                  }}
                >
                  <span
                    style={{
                      color: "rgba(0,0,0,0.5)",
                      fontSize: "14px",
                      fontWeight: "400",
                    }}
                  >
                    {d.startYear} - {d.endYear}
                  </span>
                  <div style={Styles.position}>{d?.degree}</div>
                  <div className="">
                    <span
                      style={{
                        color: "#424952",
                        fontSize: "14px",
                        fontWeight: "400",
                      }}
                    >
                      {d?.location?.city && d?.location?.country
                        ? `${d?.location?.city}, ${d?.location?.country}`
                        : d?.location?.city && !d?.location?.country
                        ? `${d?.location?.city}`
                        : ""}
                    </span>
                  </div>
                </div>
              </div>
            );
          })
        : ""}
    </>
  );
};

export default SingleEducation;
