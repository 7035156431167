import React from "react";

interface PropsType {
  comment: any;
}

const Styles = {
  root: {},
  commentBox: {
    paddingLeft: 16,
    paddingRight: 16,
    paddingTop: 8,
    paddingBottom: 8,
    backgroundColor: "rgba(188, 150, 35, 0.03)",
    marginTop: 10,
    borderRadius: 9,
    marginBottom: 17,
    boxShadow: "0px 0px 9px rgba(0, 0, 0, 0.14)",
    border: "solid 0.5px #DADADA",
  },
  body: {
    margin: 0,
    color: "#495057",
    fontSize: 12,
    fontStyle: "italic",
  },
  heading: {
    margin: 0,
    color: "#012653",
    fontSize: 14,
    fontWeight: 400,
  },
};

const LanguageComment: React.FC<PropsType> = ({ comment }) => {
  const formattedComment = comment?.replace(/\n/g, "<br />");
  return (
    <div style={Styles.commentBox}>
      <p style={Styles.heading}>Comments:</p>
      {/* <p style={Styles.body}>{comment}</p> */}
      <div
        style={Styles.body}
        dangerouslySetInnerHTML={{ __html: formattedComment }}
      />
    </div>
  );
};

export default LanguageComment;
