import React, { useContext, useEffect, useRef, useState } from "react";
import {
  Row,
  Col,
  Card,
  Tabs,
  Button,
  Tooltip,
  Skeleton,
  Table,
  Space,
  notification,
  Modal,
  Avatar,
  Typography,
  Badge,
  Input,
  Select,
  Form,
  InputRef,
} from "antd";
import type {
  ColumnsType,
  FilterValue,
  SorterResult,
} from "antd/es/table/interface";
import type { TabsProps } from "antd";
import type { TableProps } from "antd";

import { Fade } from "react-awesome-reveal";
import Column from "antd/lib/table/Column";
import moment from "moment-timezone";
import { Link, useLocation } from "react-router-dom";
import Icon, { DislikeOutlined, UserOutlined } from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import { MatchsState } from "store/matchs/types";
import {
  useRecruiterActionMutation,
  useGetCompanyDetailFromJobQuery,
} from "../../../../graphql/generated/graphql";
import {
  MoveToDisLikedCandidates,
  MoveToDisLikedFromLikedCandidates,
  MoveToLikedCandidates,
  MoveToLikedFromDisLikedCandidates,
  MoveToDisLikedFromMatchedCandidates,
  MoveToSelectedFromRejectedCandidates,
  StoreDislikedCandidates,
  StoreLikedCandidates,
  StoreMatchedCandidates,
  StoreNewCandidates,
  MoveToMatchedCandidates,
  MoveToMatchedFromDislikeCandidates,
  MoveToMatchedFromSelectedCandidates,
  StoreSelectedCandidates,
  StoreSelectedCandidatesLoad,
  StoreRejectedCandidates,
  LoadRejectedCandidates,
  StoreHiredCandidates,
  LoadHiredCandidates,
} from "store/matchs/actions";

import PageTitle from "components/UI/PageTitle/PageTitle";
import NewCandidatesListing from "./NewCandidatesListing";
import DisLikedCandidatesListing from "./DisLikedCandidatesListing";
import MyModal from "components/UI/Modal";
import Buttons from "../../../../components/UI/Buttons";
import {
  DeleteAllNotifications,
  DeleteNotification,
} from "store/notifications/actions";
import { useMutation } from "react-apollo";
import { removeMultipleNotifications } from "components/TopBar/partials/NotificationDropdown/notifications";
import { TabActvContext } from "../context/tabActvContext";
import "./matchesTabs.css";
import "./matchTabs.scss";
import Approve1 from "./Iconsvg/approve-1.svg";
import Approve from "./Iconsvg/approve.svg";
import Dislike1 from "./Iconsvg/dislike-1.svg";
import Delete from "./Iconsvg/delete.svg";
import Hire1 from "./Iconsvg/hire-1.svg";
import Like from "./Iconsvg/like.svg";
//active table svg
import candidateHover from "./Iconsvg/candidateHover.svg";
import ActiveMatch from "./Iconsvg/likeHover.svg";
import ApproveHover from "./Iconsvg/approveHover.svg";
import dislikeHover from "./Iconsvg/dislikeHover.svg";
import selectHover from "./Iconsvg/selectHover.svg";
import deleteHover from "./Iconsvg/deleteHover.svg";
import hireHover from "./Iconsvg/hireHover.svg";

import Candidate from "./Iconsvg/candidate.svg";
import Searchbar from "./Searchbar";
import form from "antd/lib/form";
import MatchModal from "components/MatchModal/MatchModal";
const { TabPane } = Tabs;

interface DataType {
  key: string;
  firstName: string;
  lastName: string;
  position: string;
  company: string;
}

interface Notification {
  _id: string;
  userId: string;
  companyId: string;
  jobId: string;
  matchId: string;
  createdAt: any;
  updatedAt: any;
  isRead: boolean;
  type: string;
  notificationHeader: string;
  notificationBody: string;
  candidate: any;
  job: any; // job shuld contain the data of companies as well
  tabActivity: string; // this key will be generated by the backend (depending where this job came from)
  // depending on the tabActive key we will choose the redux jobs we will send to the candidates.
}
interface CandidateListingsProps {
  jobId: any;
  newCandidatesAgainstAJob: any;
  likesAgainstAJob: any;
  disLikesAgainstAJob: any;
  matchesAgainstAJob: any;
  selectedCandidates: any;
  rejectedCandidatesLoaded: any;
  hiredCandidatesLoaded: any;
  // favouritesCandidatesLoaded: any;
}

const MatchesTabs: React.FC<CandidateListingsProps> = ({
  jobId,
  newCandidatesAgainstAJob,
  likesAgainstAJob,
  disLikesAgainstAJob,
  matchesAgainstAJob,
  selectedCandidates,
  rejectedCandidatesLoaded,
  hiredCandidatesLoaded,
  // favouritesCandidatesLoaded,
}) => {
  const [selectedNotifLength, setSelectedNotifLength] = useState(0);
  const location: any = useLocation();
  const TabKeyFromLocalStorage = localStorage.getItem("keytab");
  const [tabActiveKey, setTabActiveKey] = useState(
    TabKeyFromLocalStorage ? TabKeyFromLocalStorage : "1"
  );
  const { setCompanyData } = useContext(TabActvContext);
  //state for active icon condition
  const [iconActive, setIcon] = useState(TabKeyFromLocalStorage);

  const dispatch = useDispatch();

  const [showMatchModal, setShowMatchModal] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [currentMatch, setCurrentMatch] = useState({});
  const { notifications: notify } = useSelector(
    (state: any) => state.notifications
  );

  const [matchesNotifications, setmatchesNotifications] = useState([]); //this will contain all the notifications regarding the matches
  const [chatNotifications, setchatNotifications] = useState([]); //this will contain all the notifications regarding the chat

  const [notificationBlipCount, setNotificationBlipCount] = useState(0);

  const [hireNotification, setHireNotification] = useState([]);
  const [rejectedNotification, setRejectedNotification] = useState([]);
  const [selectedNotification, setSelectedNotification] = useState([]);
  const [matchtabNotification, setMatchTabNotification] = useState([]);
  const [dislikeNotification, setDislikeNotification] = useState([]);
  const [newCandidatesNotification, setNewCandidatesNotification] = useState(
    []
  );
  const [notifications, setNotifications] = useState([]);

  const [filteredInfo, setFilteredInfo] = useState<
    Record<string, FilterValue | null>
  >({});
  const [sortedInfo, setSortedInfo] = useState<SorterResult<DataType>>({});

  const handleChange: TableProps<DataType>["onChange"] = (
    pagination,
    filters,
    sorter
  ) => {
    setFilteredInfo(filters);
    setSortedInfo(sorter as SorterResult<DataType>);
  };

  useEffect(() => {
    setNotifications(notify);
  }, [notify]);

  useEffect(() => {
    const matchesNotifications = notifications.filter(
      (notification: Notification) => notification.type === "match"
    );
    setmatchesNotifications([...matchesNotifications]);

    const chatNotifications = notifications.filter(
      (notification: Notification) => notification.type === "chat"
    );
    setchatNotifications([...chatNotifications]);
    // we will only save the match notifications in this arrray

    return () => {};
  }, [notifications]);

  useEffect(() => {
    //hire tab notification
    const hireNotify = notifications.filter(
      (notification: Notification) =>
        notification?.tabActivity == "7" &&
        notification?.job?._id == jobId &&
        (notification?.type === "match" || notification?.type === "chat")
    );
    setHireNotification(hireNotify);

    //reject tab notification
    const rejectNotify = notifications.filter(
      (notification: Notification) =>
        notification?.tabActivity == "6" &&
        notification?.job?._id == jobId &&
        (notification?.type === "match" || notification?.type === "chat")
    );
    setRejectedNotification(rejectNotify);

    //select tab notification
    const selectNotify = notifications?.filter(
      (notification: Notification) =>
        notification?.tabActivity == "5" &&
        notification?.job?._id == jobId &&
        (notification?.type === "match" || notification?.type === "chat")
    );
    setSelectedNotification([...selectNotify]);
    //match tab notification
    const matchNotify = notifications.filter(
      (notification: Notification) =>
        notification?.tabActivity == "4" &&
        notification?.job?._id == jobId &&
        (notification?.type === "match" || notification?.type === "chat")
    );
    setMatchTabNotification(matchNotify);
    //dislike notification
    const dislikeNotify = notifications.filter(
      (notification: Notification) =>
        notification?.tabActivity == "2" &&
        notification?.job?._id == jobId &&
        (notification?.type === "match" || notification?.type === "chat")
    );
    setDislikeNotification(dislikeNotify);

    //newCandidate notification

    const newCandidateNotify = notifications.filter(
      (notification: Notification) =>
        notification?.tabActivity == "1" &&
        notification?.job?._id == jobId &&
        (notification?.type === "match" || notification?.type === "chat")
    );
    setNewCandidatesNotification(newCandidateNotify);

    return () => {};
  }, [notifications]);

  const {
    data,
    loading: companyLoading,
    error,
  } = useGetCompanyDetailFromJobQuery({
    variables: {
      jobId: jobId,
    },
  });
  setCompanyData(data);

  const [addRecruiterActionMutation, { loading }] =
    useRecruiterActionMutation();
  // const { data, error }: any = useUserMessagesQuery();

  const newCandidatesFromStore = useSelector(
    (state: MatchsState) => state.matchs?.newCandidates
  );
  const dislikedCandidatesFromStore = useSelector(
    (state: MatchsState) => state.matchs?.disLikedCandidates
  );
  const likedCandidatesFromStore = useSelector(
    (state: MatchsState) => state.matchs?.likedCandidates
  );
  const machesCandidatesFromStore = useSelector(
    (state: MatchsState) => state.matchs?.matchedCandidates
  );

  const selectedCandidatesFromStore = useSelector(
    (state: MatchsState) => state.matchs?.selectedCandidates
  );
  const rejectedCandidates = useSelector(
    (state: MatchsState) => state.matchs?.rejectedCandidates
  );
  const hiredCandidates = useSelector(
    (state: MatchsState) => state.matchs?.hiredCandidates
  );

  const [selectedCandidate, setSelectedCandidate] = useState({
    firstName: "",
    profilePicture: "",
    company: {
      logoUrl: "",
    },
    _id: "",
  });
  //lables for table
  const label1 = (
    <Space
      className="spaceGap_NewCandidate"
      onClick={() => {
        handleClearNotification("1");
      }}
    >
      {iconActive == "1" ? (
        <img
          className="design-custom"
          src={candidateHover}
          alt=""
          width={24}
          height={24}
        />
      ) : (
        <img
          className="design1"
          src={Candidate}
          alt=""
          width={24}
          height={24}
        />
      )}
      <Typography className="designing1 ">New candidates</Typography>
      <Typography
        className={`candidate_count_design ${
          newCandidatesFromStore?.length === 0 ? "hidden" : ""
        }`}
      >
        {newCandidatesFromStore?.length > 0 && newCandidatesFromStore?.length}
      </Typography>
    </Space>
  );
  const label2 = (
    <Space
      className="spaceGap"
      onClick={() => {
        handleClearNotification("2");
      }}
    >
      {iconActive == "2" ? (
        <img
          className="design-custom"
          src={dislikeHover}
          alt=""
          width={24}
          height={24}
        />
      ) : (
        <img className="design1" src={Dislike1} alt="" width={24} height={24} />
      )}
      <Typography className="designing3">My dislikes</Typography>
      <Typography
        className={`candidate_count_design ${
          dislikedCandidatesFromStore?.length === 0 ? "hidden" : ""
        }`}
      >
        {dislikedCandidatesFromStore?.length > 0 &&
          dislikedCandidatesFromStore?.length}
      </Typography>
    </Space>
  );
  const label3 = (
    <Space
      className="myLikesTab spaceGap"
      onClick={() => {
        handleClearNotification("3");
      }}
    >
      {iconActive == "3" ? (
        <img
          className="design-custom"
          src={ActiveMatch}
          alt=""
          width={24}
          height={24}
        />
      ) : (
        <img className="design1" src={Like} alt="" width={24} height={24} />
      )}
      <Typography className="designing3">My likes</Typography>

      <Typography
        className={`candidate_count_design ${
          likedCandidatesFromStore?.length === 0 ? "hidden" : ""
        }`}
      >
        {likedCandidatesFromStore?.length > 0 &&
          likedCandidatesFromStore?.length}
      </Typography>

      {/* <Badge className="badge-design" count={4} /> */}
    </Space>
  );

  const label4 = (
    <Space
      className="spaceGap"
      onClick={() => {
        handleClearNotification("4");
      }}
    >
      {iconActive == "4" ? (
        <img
          className="design-custom"
          src={ApproveHover}
          alt=""
          width={24}
          height={24}
        />
      ) : (
        <img className="design1" src={Approve} alt="" width={24} height={24} />
      )}
      <Typography className="designing3">Matches</Typography>
      <Typography
        className={`candidate_count_design ${
          machesCandidatesFromStore?.length === 0 ? "hidden" : ""
        }`}
      >
        {machesCandidatesFromStore?.length > 0 &&
          machesCandidatesFromStore?.length}
      </Typography>
    </Space>
  );

  const label5 = (
    <Space
      className="spaceGap"
      onClick={() => {
        handleClearNotification("5");
      }}
    >
      {iconActive == "5" ? (
        <img
          className="design-custom"
          src={selectHover}
          alt=""
          width={24}
          height={24}
        />
      ) : (
        <img className="design1" src={Approve1} alt="" width={24} height={24} />
      )}
      <Typography className="designing3">Selected</Typography>
      <Typography
        className={`candidate_count_design ${
          selectedCandidatesFromStore?.length === 0 ? "hidden" : ""
        }`}
      >
        {selectedCandidatesFromStore?.length > 0 &&
          selectedCandidatesFromStore?.length}
      </Typography>
    </Space>
  );

  const label6 = (
    <Space
      className="spaceGap"
      onClick={() => {
        handleClearNotification("6");
      }}
    >
      {iconActive == "6" ? (
        <img
          className="design-custom"
          src={deleteHover}
          alt=""
          width={24}
          height={24}
        />
      ) : (
        <img className="design1" src={Delete} alt="" width={24} height={24} />
      )}
      <Typography className="designing3 ">Rejected</Typography>
      <Typography
        className={`candidate_count_design ${
          rejectedCandidates?.length === 0 ? "hidden" : ""
        }`}
      >
        {rejectedCandidates?.length > 0 && rejectedCandidates?.length}
      </Typography>
    </Space>
  );
  const label7 = (
    <Space
      className="d-7 spaceGap"
      onClick={() => {
        handleClearNotification("7");
      }}
    >
      {iconActive == "7" ? (
        <img
          className="design-custom"
          src={hireHover}
          alt=""
          width={24}
          height={24}
        />
      ) : (
        <img className="design1" src={Hire1} alt="" width={24} height={24} />
      )}
      <Typography className="designing3">Hired</Typography>
      <Typography
        className={`candidate_count_design ${
          hiredCandidates?.length === 0 ? "hidden" : ""
        }`}
      >
        {hiredCandidates?.length > 0 && hiredCandidates?.length}
      </Typography>
    </Space>
  );
  //ANCHOR: Add lastAction here as well
  const candidateLikeOrDisLikeHandler = (
    matchId: any,
    action: any,
    from: any,
    lastAction?: any
  ) => {
    addRecruiterActionMutation({
      variables: {
        matchId: matchId,
        action: action,
        from: from,
        lastAction,
      },
    })
      .then(({ data }: any) => {
        // console.log({data})
        if (data?.recruiterAction == null) {
          notification["error"]({
            message: "something is not right!",
            description: <div>{"Something went wrong. Please try again"}</div>,
            duration: 3,
            onClose: () => setHasError(false),
          });
          return;
        }
        if (from) {
          if (from == "fromLiked")
            dispatch(
              MoveToDisLikedFromLikedCandidates(data?.recruiterAction._id)
            );
          else if (
            from == "fromSelected" &&
            data?.recruiterAction.recruiterAction === "like"
          ) {
            dispatch(
              MoveToMatchedFromSelectedCandidates(data?.recruiterAction._id)
            );
          } else if (from == "fromSelectedtoRejected") {
            dispatch(StoreRejectedCandidates(data?.recruiterAction._id));
          } else if (from == "fromDisLiked") {
            if (data?.recruiterAction?.candidateAction == "like") {
              // TODO: PopupCongrats, you have found a match
              setShowMatchModal(true);
              dispatch(
                MoveToMatchedFromDislikeCandidates(data?.recruiterAction._id)
              );
            } else {
              dispatch(
                MoveToLikedFromDisLikedCandidates(data?.recruiterAction._id)
              );
            }
          } else if (from == "fromMatched")
            dispatch(
              MoveToDisLikedFromMatchedCandidates(data?.recruiterAction._id)
            );
          else if (from == "fromMatchedToSelected")
            dispatch(StoreSelectedCandidates(data?.recruiterAction._id));
          else if (from == "fromSelectedtoHired")
            dispatch(StoreHiredCandidates(data?.recruiterAction._id));
          else if (from == "fromRejectedToSelected")
            dispatch(
              MoveToSelectedFromRejectedCandidates(data?.recruiterAction._id)
            );
        } else {
          // New Candidates Tab Operations
          if (
            action == "like" &&
            data?.recruiterAction.candidateAction !== "like"
          )
            dispatch(MoveToLikedCandidates(data?.recruiterAction._id));
          if (
            action == "like" &&
            data?.recruiterAction.candidateAction == "like"
          ) {
            // TODO: PopupCongrats, you have found a match
            setShowMatchModal(true);
            dispatch(MoveToMatchedCandidates(data?.recruiterAction._id));
          } else if (action == "dislike")
            dispatch(MoveToDisLikedCandidates(data?.recruiterAction._id));
        }
        let actionMessage = "";
        switch (action) {
          case "like":
            actionMessage = "liked";
            break;
          case "dislike":
            actionMessage = "disliked";
            break;
          case "selected":
            actionMessage = "selected";
            break;
          case "rejected":
            actionMessage = "rejected";
            break;
          case "hired":
            actionMessage = "hired";
            break;
          default:
            break;
        }
        notification["success"]({
          message: `You have ${actionMessage} ${data?.recruiterAction.candidateListing.firstName} ${data?.recruiterAction.candidateListing.lastName}`,
          duration: 2,
          // onClose: () =>
          //   history.push(`/job-listings/view/${data?.recruiterAction?._id}`),
          // description: `You have ${actionMessage} the candidate ${data?.recruiterAction.candidateListing.firstName} ${data?.recruiterAction.candidateListing.lastName}.`,
        });
      })
      .catch((err) => {
        console.log({ err });

        const errors = err.graphQLErrors.map((el: any) => el.message);
        notification["error"]({
          message: "Unable to create job",
          description: errors.map((er: any) => <div key={er}>{er}</div>),
          duration: 3,
          onClose: () => setHasError(false),
        });
      });
  };

  const handleOk = () => {
    setShowMatchModal(false);
  };

  const handleCancel = () => {
    setShowMatchModal(false);
  };

  const blipNotifyHandler = (recordId: any) => {
    const display = notifications?.find(
      (notif: Notification) => notif?.matchId === recordId
    );

    return display ? "" : "none";
  };

  const { search } = location;

  useEffect(() => {
    dispatch(StoreNewCandidates(newCandidatesAgainstAJob));
    dispatch(StoreDislikedCandidates(disLikesAgainstAJob));
    dispatch(StoreLikedCandidates(likesAgainstAJob));
    dispatch(StoreMatchedCandidates(matchesAgainstAJob));
    dispatch(StoreSelectedCandidatesLoad(selectedCandidates));
    dispatch(LoadRejectedCandidates(rejectedCandidatesLoaded));
    dispatch(LoadHiredCandidates(hiredCandidatesLoaded));
  }, [dispatch, location.key]);
  useEffect(() => {
    setSelectedNotifLength(selectedCandidatesFromStore?.length);
  }, [selectedCandidatesFromStore]);
  const sendMessage = (e: any) => {
    e.preventDefault();
  };

  const handleTabActiveKey = (activeKey: string) => {
    setTabActiveKey(activeKey);
  };

  const [setMultipleNotificationsToRead, { data: data1 }] = useMutation(
    removeMultipleNotifications
  );
  const clickOnNotification = (matchId: string, type: string) => {
    const foundNotification = notifications
      .filter(
        (notif: Notification) => notif.type == type && notif.matchId == matchId
      )
      .map((notif: Notification) => notif._id);

    if (foundNotification.length) {
      setMultipleNotificationsToRead({
        variables: {
          notificationsArray: [...foundNotification],
        },
      })
        .then((data: any) => {
          dispatch(DeleteAllNotifications(foundNotification));
        })
        .catch((err) => {
          console.log("An error occured while deleting all notifications");
          console.log({ err });
        });
    }
  };

  const handleClearNotification = (tab: any) => {
    const res = notifications?.filter((notification: Notification) => {
      return (
        notification?.tabActivity == tab &&
        notification?.job?._id == jobId &&
        (notification?.type === "match" || notification?.type === "chat")
      );
    });
    const resIds = res?.map((r: any) => r?._id);
    setMultipleNotificationsToRead({
      variables: {
        notificationsArray: [...resIds],
      },
    })
      .then((data: any) => {
        dispatch(DeleteAllNotifications(res));
        const updatedNotifications = notifications?.filter((noti: any) => {
          return !noti?.tabActivity == tab;
        });
        setNotifications(updatedNotifications);
        if (tab == "1") {
          setNewCandidatesNotification([]);
        } else if (tab == "2") {
          setDislikeNotification([]);
        } else if (tab == "4") {
          setmatchesNotifications([]);
        } else if (tab == "5") {
          setSelectedNotification([]);
        } else if (tab == "6") {
          setRejectedNotification([]);
        } else if (tab == "7") {
          setHireNotification([]);
        }
      })
      .catch((err) => {
        console.log("An error occured while deleting all notifications");
        console.log({ err });
      });
  };

  const [tabColor, setTabColor] = useState("tab-pane-count");

  useEffect(() => {
    const found = matchesAgainstAJob.filter((doc: any) =>
      matchesNotifications.some(
        (notification: Notification) =>
          notification.jobId === jobId &&
          notification.tabActivity === "4" &&
          notification.matchId === doc._id
      )
    );

    if (found.length) {
      setTabColor("tab-pane-count-red");
    } else {
      setTabColor("tab-pane-count");
    }
    return () => {};
  }, [matchesNotifications, matchesAgainstAJob]);
  const onChange = (key: string) => {
    localStorage.setItem("keytab", key);
    setIcon(key);
  };

  const items: TabsProps["items"] = [
    {
      key: "1",
      label: (
        <Badge
          style={{
            marginTop: "-16px",
            // marginRight: "-4px",
            position: "absolute",
            backgroundColor: "#BC0000",
            borderColor: "#BC0000",
          }}
          count={
            newCandidatesNotification?.length > 0 ? (
              <span className="blip"></span>
            ) : (
              ""
            )
          }
        >
          {" "}
          {label1}
        </Badge>
      ),
      children: (
        <span className="tab-pane-count">
          <NewCandidatesListing
            jobId={jobId}
            newCandidates={newCandidatesFromStore}
            setSelectedCandidate={setSelectedCandidate}
            candidateLikeOrDisLikeHandler={candidateLikeOrDisLikeHandler}
            tabActiveKey={tabActiveKey}
            handleChanage={handleChange}
          />
        </span>
      ),
    },
    {
      key: "2",
      label: (
        <Badge
          style={{
            marginTop: "-16px",
            // marginRight: "-4px",
            position: "absolute",
            backgroundColor: "#BC0000",
            borderColor: "#BC0000",
          }}
          count={
            dislikeNotification?.length > 0 ? (
              <span className="blip"></span>
            ) : (
              ""
            )
          }
        >
          {label2}
        </Badge>
      ),

      children: (
        <DisLikedCandidatesListing
          setCurrentMatch={setCurrentMatch}
          jobId={jobId}
          dislikedCandidates={dislikedCandidatesFromStore}
          candidateLikeOrDisLikeHandler={candidateLikeOrDisLikeHandler}
          setSelectedCandidate={setSelectedCandidate}
          tabActiveKey={tabActiveKey}
          company={data?.getCompanyFromJob}
          handleOnchange={handleChange}
        />
      ),
    },
    {
      key: "3",
      label:
        // <Badge
        //   style={{
        //     marginTop: "-9px",
        //     marginRight: "-22px",
        //     backgroundColor: "#990000",
        //     borderColor: "#990000",
        //   }}
        //   count={
        //     likedCandidatesFromStore.length > 0
        //       ? likedCandidatesFromStore.length
        //       : ""
        //   }
        // >
        label3,
      // </Badge>
      children: (
        <Table
          scroll={likedCandidatesFromStore?.length > 0 ? { x: 1300 } : {}}
          onChange={handleChange}
          showSorterTooltip={false}
          locale={{
            emptyText: (
              <span>
                <p style={{ marginTop: "20px" }}>
                  <Avatar icon={<UserOutlined />} />
                </p>
                <p>No candidate yet</p>
              </span>
            ),
          }}
          dataSource={likedCandidatesFromStore}
          size="small"
        >
          <Column
            title="Application"
            width="100"
            // fixed="left"
            dataIndex="updatedAt"
            sortDirections={["descend", "ascend", "descend"]}
            defaultSortOrder="descend"
            sorter={(a: any, b: any) => {
              if (!a.updatedAt) a.updatedAt = "";
              if (!b.updatedAt) b.updatedAt = "";
              return a.updatedAt - b.updatedAt;
            }}
            key="updatedAt"
            render={(updatedAt, record: any) => (
              <>{moment(parseInt(updatedAt)).format("DD/MM/YY")}</>
            )}
          />
          <Column
            title="First name"
            sortDirections={["descend", "ascend", "descend"]}
            // defaultSortOrder="descend"
            sorter={(a: any, b: any) => {
              let result = a.candidateListing?.firstName.localeCompare(
                b.candidateListing?.firstName
              );
              // let result = -1;
              if (result === null) result = -1;
              return result == -1 ? 1 : -1;
            }}
            dataIndex="candidateListing"
            key="First name"
            // sorter={true}
            render={(candidate, record: any) => (
              <div className="ellipse">
                <Link
                  to={{
                    pathname: `/candidate-listings/view/${candidate?._id}`,
                    state: {
                      company: data?.getCompanyFromJob,
                      matchId: record?._id,
                      from: likedCandidatesFromStore,
                      jobId: jobId,
                      jobName: record?.jobListing?.title,
                      companyName: record?.company?.name,
                      tabActiveKey,
                    },
                  }}
                >
                  {candidate?.firstName}
                </Link>
              </div>
            )}
          />
          <Column
            title="Last name"
            dataIndex="candidateListing"
            sortDirections={["descend", "ascend", "descend"]}
            // defaultSortOrder="descend"
            sorter={(a: any, b: any) => {
              if (!a.candidateListing?.lastName)
                a.candidateListing.lastName = "";
              if (!b.candidateListing?.lastName)
                b.candidateListing.lastName = "";
              return a.candidateListing?.lastName.localeCompare(
                b.candidateListing?.lastName
              );
            }}
            // sorter={true}
            key="Last name"
            render={(candidate, record: any) => (
              <div className="ellipse">
                <Link
                  to={{
                    pathname: `/candidate-listings/view/${candidate?._id}`,
                    state: {
                      company: data?.getCompanyFromJob,
                      matchId: record?._id,
                      from: likedCandidatesFromStore,
                      jobId: jobId,
                      jobName: record?.jobListing?.title,
                      companyName: record?.company?.name,
                      tabActiveKey,
                    },
                  }}
                >
                  {candidate?.lastName}
                </Link>
              </div>
            )}
          />
          <Column
            title="Position"
            width="300px"
            sortDirections={["descend", "ascend", "descend"]}
            // defaultSortOrder="descend"
            sorter={(a: any, b: any) => {
              if (!a.candidateListing?.position)
                a.candidateListing.position = "";
              if (!b.candidateListing?.position)
                b.candidateListing.position = "";
              return a.candidateListing?.position.localeCompare(
                b.candidateListing?.position
              );
            }}
            dataIndex="candidateListing"
            key="position"
            render={(candidate) => (
              <div
                className="ellipse"
                data-bs-toggle="tooltip"
                data-bs-placement="top"
                title={
                  candidate?.experiences?.length > 0 && candidate?.position
                }
              >
                {candidate?.experiences?.length > 0 && candidate?.position}
              </div>
            )}
          />
          <Column
            title="Company"
            width="300px"
            // sorter={true}
            sortDirections={["descend", "ascend", "descend"]}
            // defaultSortOrder="descend"
            sorter={(a: any, b: any) => {
              const companyA =
                a?.candidateListing?.experiences?.[0]?.company || "";
              const companyB =
                b?.candidateListing?.experiences?.[0]?.company || "";

              return companyA.localeCompare(companyB);
            }}
            dataIndex="candidateListing"
            key="company"
            render={(candidate) => (
              <div
                className="ellipse"
                data-bs-toggle="tooltip"
                data-bs-placement="top"
                title={
                  candidate?.experiences?.length > 0 &&
                  candidate?.experiences[0]?.company
                }
              >
                {candidate?.experiences?.length > 0 &&
                  candidate?.experiences[0]?.company}
              </div>
            )}
          />
          <Column
            title="Benchmark"
            // width="300px"
            sortDirections={["descend", "ascend", "descend"]}
            // defaultSortOrder="descend"
            sorter={(a: any, b: any) => {
              if (!a.benchmark) {
                a.benchmark = "";
              }
              if (!b.benchmark) {
                b.benchmark = "";
              }
              return a?.benchmark?.localeCompare(b?.benchmark);
            }}
            // sorter={true}
            dataIndex="benchmark"
            key="candidateListing?._id"
            render={(candidate, record: any) =>
              record?.benchmark ? (
                <div className="benchmark-plaque">{record?.benchmark}</div>
              ) : null
            }
          />
          <Column
            title="Actions"
            width="100"
            fixed="right"
            dataIndex="candidateListing"
            key="candidateListing?._id"
            render={(candidate, record: any) => (
              <>
                <Space>
                  {/* <Tooltip title="Dislike"> */}
                  <Button
                    className="ant-btn-red"
                    shape="circle"
                    onClick={() =>
                      candidateLikeOrDisLikeHandler(
                        record?._id,
                        "dislike",
                        "fromLiked"
                      )
                    }
                    icon={<DislikeOutlined />}
                  ></Button>
                  {/* </Tooltip> */}
                </Space>
              </>
            )}
          />
        </Table>
      ),
    },
    {
      key: "4",
      label: (
        <Badge
          style={{
            marginTop: "-16px",
            // marginRight: "-4px",
            position: "absolute",
            backgroundColor: "#BC0000",
            borderColor: "#BC0000",
          }}
          count={
            matchtabNotification?.length > 0 ? (
              <span className="blip"></span>
            ) : (
              ""
            )
          }
        >
          {label4}
        </Badge>
      ),
      children: (
        <Table
          scroll={matchesAgainstAJob?.length > 0 ? { x: 1300 } : {}}
          onChange={handleChange}
          showSorterTooltip={false}
          locale={{
            emptyText: (
              <span>
                <p style={{ marginTop: "20px" }}>
                  <Avatar icon={<UserOutlined />} />
                </p>
                <p>No candidate yet</p>
              </span>
            ),
          }}
          dataSource={machesCandidatesFromStore}
          size="small"
        >
          <Column
            title="Application"
            width="100"
            // fixed="left"
            sortDirections={["descend", "ascend", "descend"]}
            defaultSortOrder="descend"
            sorter={(a: any, b: any) => {
              if (!a.updatedAt) a.updatedAt = "";
              if (!b.updatedAt) b.updatedAt = "";
              return a.updatedAt - b.updatedAt;
            }}
            // sorter={true}
            dataIndex="updatedAt"
            key="updatedAt"
            render={(updatedAt, record: any) => (
              <>{moment(parseInt(updatedAt)).format("DD/MM/YY")}</>
            )}
          />
          <Column
            title="First name"
            dataIndex="candidateListing"
            key="First name"
            sortDirections={["descend", "ascend", "descend"]}
            // defaultSortOrder="descend"
            sorter={(a: any, b: any) => {
              if (!a.candidateListing?.firstName)
                a.candidateListing.firstName = "";
              if (!b.candidateListing?.firstName)
                b.candidateListing.firstName = "";
              return a.candidateListing?.firstName.localeCompare(
                b.candidateListing?.firstName
              );
            }}
            render={(candidate, record: any) => (
              <div
                style={{
                  flexDirection: "row",
                  justifyContent: "space-between",
                }}
                className="ellipse"
              >
                <img
                  style={{
                    display: blipNotifyHandler(record?._id),
                    height: 7,
                    width: 7,
                    marginRight: 5,
                    marginBottom: 10,
                  }}
                  src={require("./blip.png")}
                />

                <Link
                  to={{
                    pathname: `/candidate-listings/view/${candidate?._id}`,
                    state: {
                      company: data?.getCompanyFromJob,
                      matchId: record?._id,
                      from: machesCandidatesFromStore,
                      jobId: jobId,
                      jobName: record?.jobListing?.title,
                      companyName: record?.company?.name,
                      tabActiveKey,
                    },
                  }}
                  onClick={() => clickOnNotification(record?._id, "match")}
                >
                  {candidate?.firstName}
                </Link>
              </div>
            )}
          />
          <Column
            title="Last name"
            dataIndex="candidateListing"
            key="Last name"
            sortDirections={["descend", "ascend", "descend"]}
            // defaultSortOrder="descend"
            sorter={(a: any, b: any) => {
              if (!a.candidateListing?.lastName)
                a.candidateListing.lastName = "";
              if (!b.candidateListing?.lastName)
                b.candidateListing.lastName = "";
              return a.candidateListing?.lastName.localeCompare(
                b.candidateListing?.lastName
              );
            }}
            // sorter={true}

            render={(candidate, record: any) => (
              <div className="ellipse">
                <Link
                  to={{
                    pathname: `/candidate-listings/view/${candidate?._id}`,
                    state: {
                      company: data?.getCompanyFromJob,
                      matchId: record?._id,
                      from: machesCandidatesFromStore,
                      jobId: jobId,
                      jobName: record?.jobListing?.title,
                      companyName: record?.company?.name,
                      tabActiveKey,
                    },
                  }}
                  onClick={() => clickOnNotification(record?._id, "match")}
                >
                  {candidate?.lastName}
                </Link>
              </div>
            )}
          />
          <Column
            title="Position"
            width="250px"
            sortDirections={["descend", "ascend", "descend"]}
            // defaultSortOrder="descend"
            sorter={(a: any, b: any) => {
              if (!a.candidateListing?.position)
                a.candidateListing.position = "";
              if (!b.candidateListing?.position)
                b.candidateListing.position = "";
              return a.candidateListing?.position.localeCompare(
                b.candidateListing?.position
              );
            }}
            // sorter={true}
            dataIndex="candidateListing"
            key="position"
            render={(candidate) => (
              <div
                className="ellipse"
                data-bs-toggle="tooltip"
                data-bs-placement="top"
                title={
                  candidate?.experiences?.length > 0 && candidate?.position
                }
              >
                {candidate?.experiences?.length > 0 && candidate?.position}
              </div>
            )}
          />
          <Column
            title="Company"
            width="250px"
            sortDirections={["descend", "ascend", "descend"]}
            // defaultSortOrder="descend"
            sorter={(a: any, b: any) => {
              const companyA =
                a?.candidateListing?.experiences?.[0]?.company || "";
              const companyB =
                b?.candidateListing?.experiences?.[0]?.company || "";

              return companyA.localeCompare(companyB);
            }}
            // sorter={true}
            dataIndex="candidateListing"
            key="company"
            render={(candidate) => (
              <div
                className="ellipse"
                data-bs-toggle="tooltip"
                data-bs-placement="top"
                title={
                  candidate?.experiences?.length > 0 &&
                  candidate?.experiences[0]?.company
                }
              >
                {candidate?.experiences?.length > 0 &&
                  candidate?.experiences[0]?.company}
              </div>
            )}
          />
          <Column
            title="Benchmark"
            // width="300px"
            sortDirections={["descend", "ascend", "descend"]}
            // defaultSortOrder="descend"
            sorter={(a: any, b: any) => {
              if (!a.benchmark) {
                a.benchmark = "";
              }
              if (!b.benchmark) {
                b.benchmark = "";
              }
              return a?.benchmark?.localeCompare(b?.benchmark);
            }}
            // sorter={true}
            dataIndex="benchmark"
            key="candidateListing?._id"
            render={(candidate, record: any) =>
              record?.benchmark ? (
                <div className="benchmark-plaque">{record?.benchmark}</div>
              ) : null
            }
          />
          <Column
            title="Actions"
            width="100"
            fixed="right"
            dataIndex="candidateListing"
            key="candidateListing?._id"
            render={(candidate, record: any) => (
              <>
                <Space>
                  <Buttons
                    type="btn-dislike"
                    onClick={
                      // we will call two functions here.
                      () => {
                        clickOnNotification(record?._id, "match");
                        candidateLikeOrDisLikeHandler(
                          record?._id,
                          "dislike",
                          "fromMatched",
                          "match-disliked"
                        );
                      }
                    }
                    // toolTipTitle="Dislike"
                  />

                  <Space>
                    <Buttons
                      type="btn-approve"
                      onClick={() => {
                        clickOnNotification(record?._id, "match");
                        candidateLikeOrDisLikeHandler(
                          record?._id,
                          "selected",
                          "fromMatchedToSelected"
                        );
                      }}
                      // toolTipTitle="Select"
                    />
                  </Space>
                  <Space>
                    {/* <Tooltip title="Chat"> */}
                    <div>
                      <MyModal
                        titleType="button"
                        // btnIcon={<WechatOutlined />}
                        enableCustomBtnIcon={true}
                        customBtnIcon={
                          chatNotifications?.find(
                            (notification: Notification) =>
                              notification?.matchId === record?._id
                          ) ? (
                            <div className="blip-parent">
                              <span className="blip"></span>
                              <Buttons
                                type="btn-chat-blip"
                                // enableToolTip={false}
                                // toolTipTitle="Chat"
                                onClick={() =>
                                  clickOnNotification(record?._id, "chat")
                                }
                              />
                            </div>
                          ) : (
                            <Buttons
                              type="btn-chat-matched"
                              // enableToolTip={false}
                              // toolTipTitle="Chat"
                            />
                          )
                        }
                        onOK={() => {}}
                        width={700}
                        footer={null}
                        ModalTytle="huu"
                        titleBtnClass="ant-btn-info"
                        record={record}
                        candidate={candidate}
                        company={data?.getCompanyFromJob}
                      >
                        <div
                          style={{
                            paddingTop: 35,
                            paddingBottom: 35,
                          }}
                        ></div>
                      </MyModal>
                    </div>
                    {/* </Tooltip> */}
                  </Space>
                </Space>
              </>
            )}
          />
        </Table>
      ),
    },
    {
      key: "5",

      label: (
        <div>
          <Badge
            style={{
              marginTop: "-2px",
              marginRight: "16px",
              backgroundColor: "#BC0000",
              borderColor: "#BC0000",
            }}
            count={
              selectedNotification?.length > 0 ? (
                <span className="blip"></span>
              ) : (
                ""
              )
            }
          ></Badge>
          {label5}
        </div>
      ),
      children: (
        <Table
          scroll={selectedCandidatesFromStore?.length > 0 ? { x: 1300 } : {}}
          onChange={handleChange}
          showSorterTooltip={false}
          locale={{
            emptyText: (
              <span>
                <p style={{ marginTop: "20px" }}>
                  <Avatar icon={<UserOutlined />} />
                </p>
                <p>No candidate yet</p>
              </span>
            ),
          }}
          dataSource={selectedCandidatesFromStore}
          size="small"
        >
          <Column
            title="Application"
            width="100"
            // fixed="left"
            // sorter={true}
            sortDirections={["descend", "ascend", "descend"]}
            defaultSortOrder="descend"
            sorter={(a: any, b: any) => {
              if (!a.createdAt) a.createdAt = "";
              if (!b.createdAt) b.createdAt = "";
              return a.updatedAt - b.updatedAt;
            }}
            dataIndex="updatedAt"
            key="updatedAt"
            render={(updatedAt, record: any) => (
              <>{moment(parseInt(updatedAt)).format("DD/MM/YY")}</>
            )}
          />
          <Column
            title="First name"
            sortDirections={["descend", "ascend", "descend"]}
            // defaultSortOrder="descend"
            sorter={(a: any, b: any) => {
              if (!a.candidateListing?.firstName)
                a.candidateListing.firstName = "";
              if (!b.candidateListing?.firstName)
                b.candidateListing.firstName = "";
              return a.candidateListing?.firstName.localeCompare(
                b.candidateListing?.firstName
              );
            }}
            // sorter={true}
            dataIndex="candidateListing"
            key="First name"
            render={(candidate, record: any) => (
              <div
                className="ellipse"
                style={{ marginTop: "10px", marginBottom: "10px" }}
              >
                <Link
                  to={{
                    pathname: `/candidate-listings/view/${candidate?._id}`,
                    state: {
                      company: data?.getCompanyFromJob,
                      matchId: record?._id,
                      from: selectedCandidatesFromStore,
                      jobId: jobId,
                      jobName: record?.jobListing?.title,
                      companyName: record?.company?.name,
                      tabActiveKey,
                    },
                  }}
                >
                  {candidate?.firstName}
                </Link>
              </div>
            )}
          />
          <Column
            title="Last name"
            sortDirections={["descend", "ascend", "descend"]}
            // defaultSortOrder="descend"
            sorter={(a: any, b: any) => {
              if (!a.candidateListing?.lastName)
                a.candidateListing.lastName = "";
              if (!b.candidateListing?.lastName)
                b.candidateListing.lastName = "";
              return a.candidateListing?.lastName.localeCompare(
                b.candidateListing?.lastName
              );
            }}
            // sorter={true}
            dataIndex="candidateListing"
            key="Last name"
            render={(candidate, record: any) => (
              <div className="ellipse">
                <Link
                  to={{
                    pathname: `/candidate-listings/view/${candidate?._id}`,
                    state: {
                      company: data?.getCompanyFromJob,
                      matchId: record?._id,
                      from: selectedCandidatesFromStore,
                      jobId: jobId,
                      jobName: record?.jobListing?.title,
                      companyName: record?.company?.name,
                      tabActiveKey,
                    },
                  }}
                >
                  {candidate?.lastName}
                </Link>
              </div>
            )}
          />
          <Column
            title="Position"
            width="250px"
            sortDirections={["descend", "ascend", "descend"]}
            // defaultSortOrder="descend"
            sorter={(a: any, b: any) => {
              if (!a.candidateListing?.position)
                a.candidateListing.position = "";
              if (!b.candidateListing?.position)
                b.candidateListing.position = "";
              return a.candidateListing?.position.localeCompare(
                b.candidateListing?.position
              );
            }}
            // sorter={true}
            dataIndex="candidateListing"
            key="position"
            render={(candidate) => (
              <div
                className="ellipse"
                data-bs-toggle="tooltip"
                data-bs-placement="top"
                title={
                  candidate?.experiences?.length > 0 && candidate?.position
                }
              >
                {candidate?.experiences?.length > 0 && candidate?.position}
              </div>
            )}
          />
          <Column
            title="Company"
            width="250px"
            sortDirections={["descend", "ascend", "descend"]}
            // defaultSortOrder="descend"
            sorter={(a: any, b: any) => {
              const companyA =
                a?.candidateListing?.experiences?.[0]?.company || "";
              const companyB =
                b?.candidateListing?.experiences?.[0]?.company || "";

              return companyA.localeCompare(companyB);
            }}
            // sorter={true}
            dataIndex="candidateListing"
            key="company"
            render={(candidate) => (
              <div
                className="ellipse"
                data-bs-toggle="tooltip"
                data-bs-placement="top"
                title={
                  candidate?.experiences?.length > 0 &&
                  candidate?.experiences[0]?.company
                }
              >
                {candidate?.experiences?.length > 0 &&
                  candidate?.experiences[0]?.company}
              </div>
            )}
          />
          <Column
            title="Benchmark"
            // width="300px"
            sortDirections={["descend", "ascend", "descend"]}
            // defaultSortOrder="descend"
            sorter={(a: any, b: any) => {
              if (!a.benchmark) {
                a.benchmark = "";
              }
              if (!b.benchmark) {
                b.benchmark = "";
              }
              return a?.benchmark?.localeCompare(b?.benchmark);
            }}
            // sorter={true}
            dataIndex="benchmark"
            key="candidateListing?._id"
            render={(candidate, record: any) =>
              record?.benchmark ? (
                <div className="benchmark-plaque">{record?.benchmark}</div>
              ) : null
            }
          />

          <Column
            title="Actions"
            width="100"
            fixed="right"
            dataIndex="candidateListing"
            key="candidateListing?._id"
            render={(candidate, record: any) => (
              <>
                <Space className="mt-0 custom-gap">
                  <Buttons
                    type="btn-hire"
                    onClick={() => {
                      candidateLikeOrDisLikeHandler(
                        record?._id,
                        "hired",
                        "fromSelectedtoHired"
                      );
                    }}
                    // toolTipTitle="Hire"
                  />
                  <Buttons
                    type="btn-reject"
                    onClick={() => {
                      candidateLikeOrDisLikeHandler(
                        record?._id,
                        "rejected",
                        "fromSelectedtoRejected"
                      );
                    }}
                    // toolTipTitle="Reject"
                  />
                  {/* <Tooltip title="Chat"> */}
                  <div>
                    <MyModal
                      titleType="button"
                      enableCustomBtnIcon={true}
                      customBtnIcon={
                        chatNotifications.find(
                          (notification: Notification) =>
                            notification.matchId === record?._id
                        ) ? (
                          <div className="blip-parent">
                            <span className="blip"></span>
                            <Buttons
                              type="btn-chat-blip"
                              // toolTipTitle="Chat"
                              onClick={() =>
                                clickOnNotification(record?._id, "chat")
                              }
                            />
                          </div>
                        ) : (
                          <Buttons
                            type="btn-chat"
                            //  toolTipTitle="Chat"
                          />
                        )
                      }
                      onOK={() => {}}
                      width={700}
                      footer={null}
                      ModalTytle="huu"
                      titleBtnClass="ant-btn-info"
                      record={record}
                      candidate={candidate}
                      company={data?.getCompanyFromJob}
                    >
                      <div
                        style={{
                          paddingTop: 35,
                          paddingBottom: 35,
                        }}
                      ></div>
                    </MyModal>
                  </div>
                  {/* </Tooltip> */}
                </Space>
              </>
            )}
          />
        </Table>
      ),
    },
    {
      key: "6",
      label: (
        // <div style={{ position: "relative" }}>
        <Badge
          style={{
            marginTop: "-16px",
            // marginRight: "-4px",
            position: "absolute",
            backgroundColor: "#BC0000",
            borderColor: "#BC0000",
          }}
          count={
            rejectedNotification.length > 0 ? (
              <span className="blip"></span>
            ) : (
              ""
            )
          }
        >
          {label6}
        </Badge>
      ),
      children: (
        <Table
          scroll={rejectedCandidates?.length > 0 ? { x: 1300 } : {}}
          onChange={handleChange}
          showSorterTooltip={false}
          locale={{
            emptyText: (
              <span>
                <p style={{ marginTop: "20px" }}>
                  <Avatar icon={<UserOutlined />} />
                </p>
                <p>No candidate yet</p>
              </span>
            ),
          }}
          dataSource={rejectedCandidates}
          size="small"
        >
          <Column
            width="100"
            // fixed="left"
            // sorter={true}
            sortDirections={["descend", "ascend", "descend"]}
            defaultSortOrder="descend"
            sorter={(a: any, b: any) => {
              if (!a.createdAt) a.createdAt = "";
              if (!b.createdAt) b.createdAt = "";
              return a.updatedAt - b.updatedAt;
            }}
            title="Application"
            dataIndex="updatedAt"
            key="updatedAt"
            render={(updatedAt, record: any) => (
              <>{moment(parseInt(updatedAt)).format("DD/MM/YY")}</>
            )}
          />
          <Column
            title="First name"
            dataIndex="candidateListing"
            sortDirections={["descend", "ascend", "descend"]}
            // defaultSortOrder="descend"
            sorter={(a: any, b: any) => {
              if (!a.candidateListing?.firstName)
                a.candidateListing.firstName = "";
              if (!b.candidateListing?.firstName)
                b.candidateListing.firstName = "";
              return a.candidateListing?.firstName.localeCompare(
                b.candidateListing?.firstName
              );
            }}
            // sorter={true}
            key="First name"
            render={(candidate, record: any) => (
              <div className="ellipse">
                <Link
                  to={{
                    pathname: `/candidate-listings/view/${candidate?._id}`,
                    state: {
                      company: data?.getCompanyFromJob,
                      matchId: record?._id,
                      from: rejectedCandidates,
                      jobId: jobId,
                      jobName: record?.jobListing?.title,
                      companyName: record?.company?.name,
                      tabActiveKey,
                    },
                  }}
                >
                  {candidate?.firstName}
                </Link>
              </div>
            )}
          />
          <Column
            title="Last name"
            dataIndex="candidateListing"
            sortDirections={["descend", "ascend", "descend"]}
            // defaultSortOrder="descend"
            sorter={(a: any, b: any) => {
              if (!a.candidateListing?.lastName)
                a.candidateListing.lastName = "";
              if (!b.candidateListing?.lastName)
                b.candidateListing.lastName = "";
              return a.candidateListing?.lastName.localeCompare(
                b.candidateListing?.lastName
              );
            }}
            // sorter={true}
            key="Last name"
            render={(candidate, record: any) => (
              <div className="ellipse">
                <Link
                  to={{
                    pathname: `/candidate-listings/view/${candidate?._id}`,
                    state: {
                      company: data?.getCompanyFromJob,
                      matchId: record?._id,
                      from: rejectedCandidates,
                      jobId: jobId,
                      jobName: record?.jobListing?.title,
                      companyName: record?.company?.name,
                      tabActiveKey,
                    },
                  }}
                >
                  {candidate?.lastName}
                </Link>
              </div>
            )}
          />
          <Column
            title="Position"
            width="300px"
            // sorter={true}
            sortDirections={["descend", "ascend", "descend"]}
            // defaultSortOrder="descend"
            sorter={(a: any, b: any) => {
              if (!a.candidateListing?.position)
                a.candidateListing.position = "";
              if (!b.candidateListing?.position)
                b.candidateListing.position = "";
              return a.candidateListing?.position.localeCompare(
                b.candidateListing?.position
              );
            }}
            dataIndex="candidateListing"
            key="position"
            render={(candidate) => (
              <div
                className="ellipse"
                data-bs-toggle="tooltip"
                data-bs-placement="top"
                title={
                  candidate?.experiences?.length > 0 && candidate?.position
                }
              >
                {candidate?.experiences?.length > 0 && candidate?.position}
              </div>
            )}
          />
          <Column
            title="Company"
            width="300px"
            sortDirections={["descend", "ascend", "descend"]}
            // defaultSortOrder="descend"
            sorter={(a: any, b: any) => {
              const companyA =
                a?.candidateListing?.experiences?.[0]?.company || "";
              const companyB =
                b?.candidateListing?.experiences?.[0]?.company || "";

              return companyA.localeCompare(companyB);
            }}
            dataIndex="candidateListing"
            key="company"
            render={(candidate) => (
              <div
                className="ellipse"
                data-bs-toggle="tooltip"
                data-bs-placement="top"
                title={
                  candidate?.experiences?.length > 0 &&
                  candidate?.experiences[0]?.company
                }
              >
                {candidate?.experiences?.length > 0 &&
                  candidate?.experiences[0]?.company}
              </div>
            )}
          />
          {/* <Column
title="Email"
dataIndex="candidateListing"
key="candidateListing?._id"
render={(candidate) => <>{candidate?.user.email}</>}
/> */}
          <Column
            title="Actions"
            width="100"
            fixed="right"
            dataIndex="candidateListing"
            key="candidateListing?._id"
            render={(candidate, record: any) => (
              <>
                <Space>
                  {/* <Tooltip title="Reselect candidate">
<Button
  className="ant-btn star-btn"
  onClick={() =>
    candidateLikeOrDisLikeHandler(
      record?._id,
      "selected",
      "fromRejectedToSelected"
    )
  }
  shape="circle"
  icon={<CheckOutlined />}
></Button>
</Tooltip> */}
                  <Buttons
                    type="btn-approve"
                    onClick={() => {
                      candidateLikeOrDisLikeHandler(
                        record?._id,
                        "selected",
                        "fromRejectedToSelected"
                      );
                    }}
                    // toolTipTitle="Reselect"
                  />
                  {/* <Tooltip title="Chat"> */}
                  <div>
                    <MyModal
                      titleType="button"
                      // btnIcon={<WechatOutlined />}
                      enableCustomBtnIcon={true}
                      customBtnIcon={
                        chatNotifications.find(
                          (notification: Notification) =>
                            notification.matchId === record?._id
                        ) ? (
                          <div className="blip-parent">
                            <span className="blip"></span>
                            <Buttons
                              type="btn-chat-blip"
                              // toolTipTitle="Chat"
                              onClick={() =>
                                clickOnNotification(record?._id, "chat")
                              }
                            />
                          </div>
                        ) : (
                          <Buttons
                            type="btn-chat"
                            //  toolTipTitle="Chat"
                          />
                        )
                      }
                      onOK={() => {}}
                      width={700}
                      footer={null}
                      ModalTytle="huu"
                      titleBtnClass="ant-btn-info"
                      record={record}
                      candidate={candidate}
                      company={data?.getCompanyFromJob}
                    >
                      <div
                        style={{
                          paddingTop: 35,
                          paddingBottom: 35,
                        }}
                      ></div>
                    </MyModal>
                  </div>
                  {/* </Tooltip> */}
                </Space>
              </>
            )}
          />
        </Table>
      ),
    },
    {
      key: "7",
      label: (
        <Badge
          style={{
            marginTop: "-16px",
            // marginRight: "-4px",
            position: "absolute",
            backgroundColor: "#BC0000",
            borderColor: "#BC0000",
          }}
          count={
            hireNotification?.length > 0 ? <span className="blip"></span> : ""
          }
        >
          {label7}
        </Badge>
      ),
      children: (
        <Table
          scroll={hiredCandidates?.length > 0 ? { x: 1300 } : {}}
          onChange={handleChange}
          showSorterTooltip={false}
          locale={{
            emptyText: (
              <span>
                <p style={{ marginTop: "20px" }}>
                  <Avatar icon={<UserOutlined />} />
                </p>
                <p>No candidate yet</p>
              </span>
            ),
          }}
          dataSource={hiredCandidates}
          size="small"
        >
          <Column
            width="100"
            // fixed="left"
            title="Application"
            dataIndex="updatedAt"
            sortDirections={["descend", "ascend", "descend"]}
            defaultSortOrder="descend"
            sorter={(a: any, b: any) => {
              if (!a.updatedAt) a.updatedAt = "";
              if (!b.updatedAt) b.updatedAt = "";
              return a.updatedAt - b.updatedAt;
            }}
            key="updatedAt"
            render={(updatedAt, record: any) => (
              <>{moment(parseInt(updatedAt)).format("DD/MM/YY")}</>
            )}
          />
          <Column
            title="First name"
            dataIndex="candidateListing"
            sortDirections={["descend", "ascend", "descend"]}
            // defaultSortOrder="descend"
            sorter={(a: any, b: any) => {
              if (!a.candidateListing?.firstName)
                a.candidateListing.firstName = "";
              if (!b.candidateListing?.firstName)
                b.candidateListing.firstName = "";
              return a.candidateListing?.firstName.localeCompare(
                b.candidateListing?.firstName
              );
            }}
            key="First name"
            render={(candidate, record: any) => (
              <div className="ellipse">
                <Link
                  to={{
                    pathname: `/candidate-listings/view/${candidate?._id}`,
                    state: {
                      company: data?.getCompanyFromJob,
                      matchId: record?._id,
                      from: hiredCandidates,
                      jobId: jobId,
                      jobName: record?.jobListing?.title,
                      companyName: record?.company?.name,
                      tabActiveKey,
                    },
                  }}
                >
                  {candidate?.firstName}
                </Link>
              </div>
            )}
          />
          <Column
            title="Last name"
            sortDirections={["descend", "ascend", "descend"]}
            // defaultSortOrder="descend"
            sorter={(a: any, b: any) => {
              if (!a.candidateListing?.lastName)
                a.candidateListing.lastName = "";
              if (!b.candidateListing?.lastName)
                b.candidateListing.lastName = "";
              return a.candidateListing?.lastName.localeCompare(
                b.candidateListing?.lastName
              );
            }}
            // sorter={true}
            dataIndex="candidateListing"
            key="Last name"
            render={(candidate, record: any) => (
              <div className="ellipse">
                <Link
                  to={{
                    pathname: `/candidate-listings/view/${candidate?._id}`,
                    state: {
                      company: data?.getCompanyFromJob,
                      matchId: record?._id,
                      from: hiredCandidates,
                      jobId: jobId,
                      jobName: record?.jobListing?.title,
                      companyName: record?.company?.name,
                      tabActiveKey,
                    },
                  }}
                >
                  {candidate?.lastName}
                </Link>
              </div>
            )}
          />
          <Column
            title="Position"
            width="300px"
            sortDirections={["descend", "ascend", "descend"]}
            // defaultSortOrder="descend"
            sorter={(a: any, b: any) => {
              if (!a.candidateListing?.position)
                a.candidateListing.position = "";
              if (!b.candidateListing?.position)
                b.candidateListing.position = "";
              return a.candidateListing.position?.localeCompare(
                b.candidateListing?.position
              );
            }}
            dataIndex="candidateListing"
            key="position"
            render={(candidate) => (
              <div
                className="ellipse"
                data-bs-toggle="tooltip"
                data-bs-placement="top"
                title={
                  candidate?.experiences?.length > 0 && candidate?.position
                }
              >
                {candidate?.experiences?.length > 0 && candidate?.position}
              </div>
            )}
          />
          <Column
            title="Company"
            width="300px"
            sortDirections={["descend", "ascend", "descend"]}
            // defaultSortOrder="descend"
            sorter={(a: any, b: any) => {
              const companyA =
                a?.candidateListing?.experiences?.[0]?.company || "";
              const companyB =
                b?.candidateListing?.experiences?.[0]?.company || "";

              return companyA.localeCompare(companyB);
            }}
            dataIndex="candidateListing"
            key="company"
            render={(candidate) => (
              <div
                className="ellipse"
                data-bs-toggle="tooltip"
                data-bs-placement="top"
                title={
                  candidate?.experiences?.length > 0 &&
                  candidate?.experiences[0]?.company
                }
              >
                {candidate?.experiences?.length > 0 &&
                  candidate?.experiences[0]?.company}
              </div>
            )}
          />
          <Column
            title="Benchmark"
            // width="300px"
            sortDirections={["descend", "ascend", "descend"]}
            // defaultSortOrder="descend"
            sorter={(a: any, b: any) => {
              if (!a.benchmark) {
                a.benchmark = "";
              }
              if (!b.benchmark) {
                b.benchmark = "";
              }
              return a?.benchmark?.localeCompare(b?.benchmark);
            }}
            // sorter={true}
            dataIndex="benchmark"
            key="candidateListing?._id"
            render={(candidate, record: any) =>
              record?.benchmark ? (
                <div className="benchmark-plaque">{record?.benchmark}</div>
              ) : null
            }
          />
          <Column
            title="Actions"
            width="100"
            fixed="right"
            dataIndex="candidateListing"
            key="candidateListing?._id"
            render={(candidate, record: any) => (
              <>
                <Space>
                  {/* <Tooltip title="Chat"> */}
                  <div>
                    {chatNotifications.find(
                      (notification: Notification) =>
                        notification.matchId === record?._id
                    ) ? (
                      <MyModal
                        titleType="button"
                        enableCustomBtnIcon={true}
                        customBtnIcon={
                          <div className="blip-parent">
                            <span className="blip"></span>
                            <Buttons
                              type="btn-chat-blip"
                              enableToolTip={false}
                              // toolTipTitle="Chat"
                              onClick={() =>
                                clickOnNotification(record?._id, "chat")
                              }
                            />
                          </div>
                        }
                        onOK={() => {}}
                        width={700}
                        footer={null}
                        ModalTytle="huu"
                        titleBtnClass="ant-btn-info"
                        record={record}
                        candidate={candidate}
                        company={data?.getCompanyFromJob}
                      >
                        <div
                          style={{
                            paddingTop: 35,
                            paddingBottom: 35,
                          }}
                        ></div>
                      </MyModal>
                    ) : (
                      <MyModal
                        titleType="button"
                        enableCustomBtnIcon={true}
                        customBtnIcon={
                          <Buttons
                            type="btn-chat"
                            //  toolTipTitle="Chat"
                          />
                        }
                        onOK={() => {}}
                        width={700}
                        footer={null}
                        ModalTytle="huu"
                        titleBtnClass="ant-btn-info"
                        record={record}
                        candidate={candidate}
                        company={data?.getCompanyFromJob}
                      >
                        <div
                          style={{
                            paddingTop: 35,
                            paddingBottom: 35,
                          }}
                        ></div>
                      </MyModal>
                    )}
                  </div>
                  {/* </Tooltip> */}
                </Space>
              </>
            )}
          />
        </Table>
      ),
    },
  ];

  const { Option } = Select;
  const { Search } = Input;
  const searchFormRef = useRef<any>(null);
  const [form] = Form.useForm();

  const searchTextRef = useRef<InputRef>(null);
  const [searchOptions, setSearchOptions] = useState({
    searchText: "",
    searchType: "All",
    tags: [],
  });

  const dateFormat = "YYYY-MM-DD";
  const [tagsList, setTagsList] = useState<any>([]);
  const [advanceFilters, setAdvanceFilters] = useState({
    source: "",
    dtFrom: moment().format(dateFormat),
    dtFromMoment: moment(),
    dtTo: moment().format(dateFormat),
    dtToMoment: moment(),
  });

  const [showChatModal, setShowChatModal] = useState(true);
  return (
    <>
      {showMatchModal && (
        <MatchModal
          jobId={jobId}
          showMatchModal={showMatchModal}
          handleCancel={handleCancel}
          selectedCandidatesFromStore={selectedCandidatesFromStore}
          dislikedCandidatesFromStore={dislikedCandidatesFromStore}
          machesCandidatesFromStore={machesCandidatesFromStore}
          selectedCandidate={selectedCandidate}
          showChatModal={showChatModal}
          chatNotifications={chatNotifications}
          data={data}
          clickOnNotification={clickOnNotification}
          match={currentMatch}
        />
      )}
      <Row justify="center" className="pb-4" gutter={16}>
        <Col span={24} className="custom-card" style={{ padding: "0px 12px" }}>
          <Fade>
            <Card>
              {!newCandidatesFromStore ||
              !likedCandidatesFromStore ||
              !dislikedCandidatesFromStore ||
              !machesCandidatesFromStore ||
              !selectedCandidatesFromStore ||
              !rejectedCandidates ||
              !hiredCandidates ? (
                <Skeleton />
              ) : (
                <>
                  <div className="tabdesigning custom-tabs">
                    <Tabs
                      className="designing"
                      type="card"
                      defaultActiveKey={tabActiveKey}
                      items={items}
                      onChange={onChange}
                    />
                  </div>
                </>
              )}
            </Card>
          </Fade>
        </Col>
      </Row>
    </>
  );
};

export default MatchesTabs;
