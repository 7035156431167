import React, { useState, useEffect } from "react";
import ImgCrop from "antd-img-crop";
import {
  Row,
  Col,
  Form,
  Input,
  Select,
  Button,
  Upload,
  message,
  Space,
  Checkbox,
  Slider,
  TreeSelect,
  Modal,
  Drawer,
  Divider,
  Typography,
  Card,
} from "antd";
import { List } from "antd";
import useGoogle from "react-google-autocomplete/lib/usePlacesAutocompleteService";
import uploadSvg from "../../../../assets/icons/icons/upload.svg";
import "./JobForm.scss";
import { Editor } from "@tinymce/tinymce-react";
import axios from "axios";
import Compressor from "compressorjs";
import { useLocation } from "react-router-dom";
import {
  CloseOutlined,
  PlusCircleOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import DeleteIcon from "../../../../assets/icons/DetailedProfile/delete.svg";
import TargetProfile from "./TargetProfile";
import TextArea from "antd/lib/input/TextArea";
import { compressImage } from "utils";
const { Option } = Select;
const { TreeNode } = TreeSelect;
interface Props {
  onFinish?: any;
  salaryRange: any;
  setSalaryRange: any;
  imageData?: any;
  setRecruiters: any;
  setHideSalary: any;
  setisAnonymous: any;
  companies: any;
  currencies: any;
  contractTypes: any;
  countries: any;
  skills: any;
  submitting: boolean;
  jobFields: [];
  inspiringCompanies: [];
  job?: any;
  hideSalary?: any;
  isAnonymous?: any;
  recruiters?: any;
  hasError?: boolean;
  isLoading?: boolean;
  draftLoader?: boolean;
  setDraftLoader?: any;
  editMode?: boolean;
  setAnonymousCompanyDescription?: any;
  setJobDescription?: any;
  setComments: any;
  setUploadMobileBanner: any;
  uploadMobileBanner: any;
  uploadBanner: any;
  setUploadBanner: any;
  addAssessment: any;
  // setCompany: any;
  handleSaveJobAsDraft: any;
}
const JobForm: React.FC<Props> = ({
  onFinish,
  isLoading,
  draftLoader,
  setDraftLoader,
  editMode,
  hasError,
  submitting,
  companies,
  currencies,
  contractTypes,
  countries,
  skills,
  jobFields,
  inspiringCompanies,
  job,
  hideSalary,
  isAnonymous,
  recruiters,
  salaryRange,
  setSalaryRange,
  setRecruiters,
  setHideSalary,
  setisAnonymous,
  setAnonymousCompanyDescription,
  setJobDescription,
  setComments,
  setUploadMobileBanner,
  uploadMobileBanner,
  uploadBanner,
  setUploadBanner,
  addAssessment,
  imageData,
  // setCompany,
  handleSaveJobAsDraft,
}) => {
  const location: any = useLocation();
  const [form]: any = Form.useForm();
  const [companyId, setCompanyId] = useState(null);
  const [selectedRecruiters, setSelectedRecruiters] = useState([]);
  const [imgLoad, setImgLoad] = useState(false);
  const [fileList, setFileList] = useState<any>([]);
  const [fileListBanner, setFileListBanner] = useState<any>([]);
  const [progress, setProgress] = useState(0);
  const [defaultFileList, setDefaultFileList] = useState([]);
  const [imagePreview, setImagePreview] = useState("");
  const [imagePreviewModel, setImagePreviewModal] = useState(false);
  const [imagePreviewBanner, setImagePreviewBanner] = useState("");
  const [imagePreviewModelBanner, setImagePreviewModalBanner] = useState(false);
  const [selectedCurrency, setSelectedCurrency] = useState<any>(null);
  const [isSaveDisabled, setIsSaveDisabled] = useState(true);

  const marks = {
    0: `${selectedCurrency?.symbol || "$"}0`,
    5000: `${selectedCurrency?.symbol || "$"}5k`,
    10000: `${selectedCurrency?.symbol || "$"}10k`,
    15000: `${selectedCurrency?.symbol || "$"}15k`,
    20000: `${selectedCurrency?.symbol || "$"}20k`,
    25000: `${selectedCurrency?.symbol || "$"}25k`,
    30000: `${selectedCurrency?.symbol || "$"}30k`,
    35000: `${selectedCurrency?.symbol || "$"}35k`,
    40000: {
      label: <strong>{`${selectedCurrency?.symbol || "$"}40k`}</strong>,
    },
  };

  const { placePredictions, getPlacePredictions, isPlacePredictionsLoading } =
    useGoogle({
      apiKey: "AIzaSyACXA9qapZNvq51WpF69eIH908sX_efNMs",
      options: {
        types: ["geocode"],
      },
    });
  const [value, setValue] = useState("");

  useEffect(() => {
    if (job?.currency) {
      setSelectedCurrency(job.currency);
    }
  }, [job?.currency]);

  const handleChangeCurrency = (value: any, option: any) => {
    const updatedCurrency = currencies.find((item: any) => item.name === value);
    updatedCurrency && setSelectedCurrency(updatedCurrency);
  };

  const onChangeCompanySelection = (companyId: any) => {
    // console.log("Selected Value: ", companyId);
    setCompanyId(companyId);
    // setCompany(companyId);
    form.resetFields(["recruiters", "leadRecruiter"]);
    setRecruiters([]);
    setSelectedRecruiters([]);
  };
  const MIN_IMAGE_SIZE = 3 * 1000 * 1000; // 3MB
  const MAX_IMAGE_SIZE = 10 * 1000 * 1000; // 10MB

  const beforeUpload = (file: any) => {
    setImgLoad(true);
    const img = [
      "image/gif",
      "image/jpeg",
      "image/png",
      "image/jpg",
      "image/svg",
      "image/svg+xml",
    ];

    const isImg = img.includes(file.type);
    if (!isImg) {
      setImgLoad(false);
      message.error("You can only upload gif, jpeg, png, svg files!");
      return false;
    }

    if (file.size < MIN_IMAGE_SIZE) {
      setImgLoad(false);
      message.error("File size is too small (Minimum is 3MB)!");
      return false; // Prevent file from being uploaded
    }

    if (file.size > MAX_IMAGE_SIZE) {
      setImgLoad(false);
      message.error("File size exceeds the limit (10MB)!");
      return false; // Prevent file from being uploaded
    }

    return true; // Allow file upload
  };

  const onRemove = (file: any) => {
    if (file) {
      setImgLoad(false);
      setFileList([]);
      setUploadMobileBanner(null);
    }
  };
  const onRemoveBanner = (file: any) => {
    // console.log(file);
    if (file) {
      setFileListBanner([]);
      setUploadBanner(null);
    }
  };

  const onPreview = async (file: any) => {
    setImagePreviewModal(true);
  };
  const onPreviewBanner = async (file: any) => {
    setImagePreviewModalBanner(true);
  };

  const salaryChangeHandler = (value: any) => {
    // console.log("Selected Slider Value: ", value);
    setSalaryRange(value);
  };

  const onRecruiterSelected = (value: any, label: any) => {
    const fullList: any = label.map((selected: any) => {
      return { value: selected.value, label: selected.children };
    });
    // console.log("====>>>>>> ", fullList);
    setSelectedRecruiters(fullList);
  };

  const handleAnonymousCompanyDescriptionEditorChange = (
    content: any,
    editor: any
  ) => {
    // console.log("Content was updated:", content);
    setAnonymousCompanyDescription(content);
  };

  const handleEditorChange = (content: any, editor: any) => {
    // console.log("Content was updated:", content);
    setJobDescription(content);
  };

  const handleCommentChange = (content: any, editor: any) => {
    setComments(content);
  };

  const resizeFile = (file: any): Promise<File | Blob> =>
    new Promise((resolve, reject) => {
      new Compressor(file, {
        quality: 0.6,
        success(result: File | Blob) {
          // console.log({ result });
          resolve(result);
        },
        error(err) {
          console.log(err.message);
          reject(err);
        },
      });
    });

  const jobFieldsTreeList = () => {
    const parentJobFields = jobFields.filter(
      (jobField: any) => jobField.parent == null
    );
    const childJobFields = jobFields.filter(
      (jobField: any) => jobField.parent !== null
    );
    return parentJobFields.map((parentJobField: any) => {
      return (
        <TreeNode
          key={parentJobField._id}
          value={parentJobField.title}
          title={parentJobField.title}
        >
          {childJobFields
            .filter(
              (jobField: any) => jobField.parent._id === parentJobField._id
            )
            .map((child: any) => (
              <TreeNode
                key={child._id}
                value={child.title}
                title={child.title}
              />
            ))}
        </TreeNode>
      );
    });
  };
  useEffect(() => {
    //clearin form fields on post a job modal
    if (location?.state == "clearFields") {
      // console.log("Clearing");
      form.setFieldsValue({
        title: "",
        companyId: null,
        description: "",
        countries: [],
        experiance: "",
        requiredSkills: [],
        recruiters: [],
        leadRecruiter: null,
        fields: [],
        contractType: null,
        salaryRange: "",
        targetCompanies: [],
        language: "en",
        jobBanner: "",
      });

      setFileList([]);
      setUploadMobileBanner(null);
      setFileListBanner([]);
      setUploadBanner(null);
    }
  }, [location]);

  useEffect(() => {
    if (job) {
      // console.log("Found job in the form : ", job);
      form.setFieldsValue({
        title: job.title,
        companyId: job?.company?.name,
        description: job.description,
        countries: job.countries.map((c: any) => c.name),
        experiance: job.experience,
        requiredSkills: job.requiredSkills.map((rs: any) => rs.title),
        recruiters: job.recruiters.map((rec: any) => rec.email),
        leadRecruiter: job.leadRecruiter.email,
        fields: job.fields.map((c: any) => c.title),
        contractType: job.contractType.name,
        salaryRange: job.salaryPackage,
        currencyId: job?.currency?.name,
        targetCompanies: job.targetCompanies.map((c: any) => c.name),
        language: job.language || "en",
        mobileBanner: job.mobileBanner,
        jobBanner: job.banner,
        total: job.yearlyCompensation?.total,
        base: job.yearlyCompensation?.total,
        onTargetEarnings: job.yearlyCompensation?.onTargetEarnings,
        shortTermIncentives: job.yearlyCompensation?.shortTermIncentives,
        longTermIncentives: job.yearlyCompensation?.longTermIncentives,
        other: job.yearlyCompensation?.other,
        comments: job.yearlyCompensation?.comments,
      });

      // setRecruiters(job.recruiters)
      setRecruiters(job.company.users);
      setSelectedRecruiters(
        job?.company?.users.map((item: any) => ({
          value: item._id,
          label: item.email,
        }))
      );
      setSalaryRange(parseInt(job.salaryPackage));

      if (job.mobileBanner) {
        setFileList([
          {
            uid: job._id,
            name: job.name,
            status: "done",
            url: job.mobileBanner ? job.mobileBanner : "",
          },
        ]);
        setUploadMobileBanner(job.mobileBanner);
        setImagePreview(job.mobileBanner);
      }

      if (job.banner) {
        setFileListBanner([
          {
            uid: job._id,
            name: "No image",
            status: "done",
            url: job.banner ? job.banner : "",
          },
        ]);
        // setUploadBanner((prev: any) => ({ ...prev, url: job.banner }));
        setImagePreviewBanner(job.banner);
      }
    } else {
      /* Setting English by default */
      form.setFieldsValue({
        language: "en",
        currencyId: "USD",
      });
    }
  }, [job]);

  useEffect(() => {
    if (companies) {
      const companyObj = companies.find(
        (company: any) => company.name == companyId
      );
      // console.log("===> ", companyObj);
      setRecruiters(companyObj.users);
    }
  }, [companyId]);

  const onValuesChange = () => {
    setIsSaveDisabled(false);
  };

  useEffect(() => {
    setTimeout(() => {
      setIsSaveDisabled(true);
    }, 1500);
  }, []);

  const dataURIToBlob = (dataURI: any) => {
    const splitDataURI = dataURI.split(",");
    const byteString =
      splitDataURI[0].indexOf("base64") >= 0
        ? atob(splitDataURI[1])
        : decodeURI(splitDataURI[1]);
    const mimeString = splitDataURI[0].split(":")[1].split(";")[0];
    const ia = new Uint8Array(byteString.length);
    for (let i = 0; i < byteString.length; i++)
      ia[i] = byteString.charCodeAt(i);
    return new Blob([ia], { type: mimeString });
  };

  const uploadImage = async (options: any) => {
    const { onSuccess, onError, file, onProgress } = options;
    const image = await resizeFile(file);
    const fmData = new FormData();
    const config = {
      headers: { "content-type": "multipart/form-data" },
      onUploadProgress: (event: any) => {
        const percent = Math.floor((event.loaded / event.total) * 100);
        setProgress(percent);
        if (percent === 100) {
          setTimeout(() => setProgress(0), 1000);
        }
        onProgress({ percent: (event.loaded / event.total) * 100 });
      },
    };
    fmData.append("upload_preset", "cqdbrmkw");
    fmData.append("file", image);
    try {
      const res = await axios.post(
        `${process.env.REACT_APP_BASE_REST_URL}/upload/job/assets`,
        fmData,
        config
      );

      onSuccess("Ok");
      // console.log("server res: ", res);
      setImagePreview(res.data.secure_url);
      setUploadMobileBanner(res.data.secure_url);
      return true;
    } catch (err) {
      console.log("Eroor: ", err);
      const error = new Error("Some error");
      onError({ err });
      return false;
    }
  };

  const uploadImageBanner = async (options: any) => {
    const { onSuccess, onError, file, onProgress } = options;
    // console.log(file);
    const image = await resizeFile(file);
    const fmData = new FormData();
    const config = {
      headers: { "content-type": "multipart/form-data" },
      onUploadProgress: (event: any) => {
        const percent = Math.floor((event.loaded / event.total) * 100);
        setProgress(percent);
        if (percent === 100) {
          setTimeout(() => setProgress(0), 1000);
        }
        onProgress({ percent: (event.loaded / event.total) * 100 });
      },
    };
    fmData.append("upload_preset", "cqdbrmkw");
    fmData.append("file", image);
    try {
      const res = await axios.post(
        `${process.env.REACT_APP_BASE_REST_URL}/upload/job/assets`,
        fmData,
        config
      );

      onSuccess("Ok");
      // console.log("server res: ", res);
      setImagePreviewBanner(res.data.secure_url);
      setUploadBanner(res.data.secure_url);
      return true;
    } catch (err) {
      console.log("Error: ", err);
      const error = new Error("Some error");
      onError({ err });
      return false;
    }
  };

  const handleOnChange = async (e: any) => {
    setIsSaveDisabled(false);
    setImgLoad(true);

    const img = [
      "image/gif",
      "image/jpeg",
      "image/png",
      "image/jpg",
      "image/svg",
      "image/svg+xml",
    ];

    const isImg = img.includes(e.file.type);

    if (isImg) {
      const latestFile = e.file.originFileObj;

      if (latestFile) {
        try {
          // Compress the image
          const compressedFile = await compressImage(latestFile);

          // Replace the original file with the compressed file in the fileList
          const updatedFileList = e.fileList.map((file: any) =>
            file.uid === e.file.uid
              ? { ...file, originFileObj: compressedFile }
              : file
          );

          setFileList(updatedFileList);
          form.setFieldsValue({
            mobileBanner: updatedFileList,
          });
        } catch (error) {
          console.error("Image compression failed:", error);
          setFileList([]);
          form.setFieldsValue({
            mobileBanner: null,
          });
        }
      }
    } else {
      setFileList([]);
      form.setFieldsValue({
        mobileBanner: null,
      });
    }
  };

  const handleOnChangeBanner = async (e: any) => {
    // console.log(file, fileList, event);
    setIsSaveDisabled(false);
    setImgLoad(true);

    const img = [
      "image/gif",
      "image/jpeg",
      "image/png",
      "image/jpg",
      "image/svg",
      "image/svg+xml",
    ];

    const isImg = img.includes(e.file.type);

    if (isImg) {
      const latestFile = e.file.originFileObj;

      if (latestFile) {
        try {
          // Compress the image
          const compressedFile = await compressImage(latestFile);

          // Replace the original file with the compressed file in the fileList
          const updatedFileList = e.fileList.map((file: any) =>
            file.uid === e.file.uid
              ? { ...file, originFileObj: compressedFile }
              : file
          );

          setFileListBanner(updatedFileList);
          form.setFieldsValue({
            jobBanner: updatedFileList,
          });

          if (e.file.status === "done") {
            // console.log(e.file);
            setImagePreviewBanner(e.file.response.secure_url);
            setUploadBanner({
              ...uploadBanner,
              loading: false,
              url: e.file.response.secure_url,
            });
          }
        } catch (error) {
          console.error("Image compression failed:", error);
        }
      }
    } else {
      setFileListBanner([]);
      form.setFieldsValue({
        jobBanner: null,
      });
    }
  };

  const saveAsDraftHandler = () => {
    form
      .validateFields(["title", "companyId", "recruiters", "leadRecruiter"])
      .then(() => {
        handleSaveJobAsDraft(form.getFieldsValue(true), true);
      })
      .catch(() => {
        form.scrollToField("title");
      });
  };
  return (
    <>
      <Form
        name="registration-form"
        layout="vertical"
        form={form}
        onFinish={onFinish}
        onValuesChange={onValuesChange}
        scrollToFirstError
      >
        <Row justify="center" gutter={[40, 16]}>
          <Col span={12}>
            <Form.Item
              name="title"
              label={<span style={{ fontSize: 18 }}>Job title</span>}
              rules={[
                {
                  required: true,
                  message: "Please input the job title",
                },
              ]}

              // hasFeedback
            >
              <Input
                type="text"
                placeholder="VP Human Resources,Digital Transformation Director..."
                maxLength={45}
                defaultValue={job && job.name}
              />
            </Form.Item>
            <Form.Item
              name="companyId"
              className="add-job-company-field"
              label={<span style={{ fontSize: 18 }}>Company</span>}
              rules={[
                {
                  required: true,
                  message: "Select a company",
                },
              ]}
              // hasFeedback
            >
              <Select
                showSearch
                placeholder={
                  <span style={{ fontSize: "14px" }}>
                    Alstom, Singapore Airlines, General Electric...
                  </span>
                }
                // optionFilterProp="children"
                onChange={onChangeCompanySelection}
                // onFocus={onFocus}
                // onBlur={onBlur}
                // onSearch={onSearch}
                filterOption={(input, option: any) =>
                  option.children
                    ?.toLowerCase()
                    .indexOf(input?.toLowerCase()) >= 0
                }
              >
                {companies &&
                  companies.map((company: any) => {
                    return (
                      <Option key={company._id} value={company.name}>
                        {company.name}
                      </Option>
                    );
                  })}
              </Select>
            </Form.Item>

            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <Form.Item
                name="mobileBanner"
                label={
                  <span style={{ fontSize: 18 }}>Banner on mobile app</span>
                }
                rules={[
                  {
                    required: true,
                    message: "Upload mobile banner",
                  },
                ]}
                style={{ width: "100%" }}
              >
                <ImgCrop
                  beforeCrop={beforeUpload}
                  rotate
                  grid
                  quality={1}
                  aspect={3 / 1}
                  minZoom={0}
                  cropperProps={{
                    restrictPosition: false,
                  }}
                >
                  <Upload
                    accept="image/*"
                    // beforeUpload={beforeUpload}
                    customRequest={uploadImage}
                    onChange={handleOnChange}
                    listType="picture-card"
                    onPreview={onPreview}
                    onRemove={onRemove}
                    fileList={fileList}
                    className="image-upload-grid"
                  >
                    {fileList.length >= 1 ? null : (
                      <div className="fonts_weight" style={{ padding: "20px" }}>
                        <span className="fonts_weight_bold">
                          <img src={uploadSvg} alt="+" />
                          <br /> Click to upload
                        </span>{" "}
                        or drag and drop <br />
                        Authorized formats: PNG, JPG, or GIF - max size 10MB min
                        3MB
                      </div>
                    )}
                  </Upload>
                </ImgCrop>
              </Form.Item>
              <Form.Item
                style={{ width: "100%" }}
                name="jobBanner"
                label={
                  <span style={{ fontSize: 18 }}>
                    Banner on the web landing page
                  </span>
                }
                rules={[
                  {
                    required: true,
                    message: "Upload web banner",
                  },
                ]}
                validateStatus={
                  fileListBanner.length >= 1 ? "success" : "error"
                }
              >
                {/* <ImgCrop
                  beforeCrop={beforeUpload}
                  rotate
                  grid
                  aspect={3 / 1}
                  minZoom={0}
                  cropperProps={{
                    restrictPosition: false,
                  }}
                > */}
                <ImgCrop
                  beforeCrop={beforeUpload}
                  rotate
                  quality={1}
                  grid
                  aspect={5.91 / 1}
                  minZoom={0}
                  cropperProps={{
                    restrictPosition: false,
                  }}
                >
                  <Upload
                    accept="image/*"
                    // customRequest={uploadImageBanner}
                    // beforeUpload={beforeUpload}
                    onChange={handleOnChangeBanner}
                    listType="picture-card"
                    onPreview={onPreviewBanner}
                    onRemove={onRemoveBanner}
                    action={`${process.env.REACT_APP_BASE_REST_URL}/upload/jobs/assets`}
                    data={{
                      upload_preset: "cqdbrmkw",
                    }}
                    fileList={fileListBanner}
                    className="image-upload-grid"
                  >
                    {fileListBanner.length >= 1 ? null : (
                      <div className="fonts_weight" style={{ padding: "20px" }}>
                        <span className="fonts_weight_bold">
                          <img src={uploadSvg} alt="+" />
                          <br /> Click to upload
                        </span>{" "}
                        or drag and drop <br />
                        Authorized formats: PNG, JPG, or GIF - max size 10MB min
                        3MB
                      </div>
                    )}
                  </Upload>
                </ImgCrop>
              </Form.Item>
            </div>
            <Form.Item
              name="description"
              label={<span style={{ fontSize: 18 }}>Job description</span>}
              rules={[
                {
                  required: true,
                  message: "Please input your job description!",
                },
              ]}
              // hasFeedback
            >
              <Editor
                apiKey="bgow6d7d89zjmvftzkn8p8gzzhkzyd551egy09ik6j400toe"
                init={{
                  height: 1300,
                  menubar: true,
                  plugins: [
                    "advlist autolink link image lists charmap print preview hr anchor pagebreak spellchecker",
                    "searchreplace wordcount visualblocks visualchars code fullscreen insertdatetime media nonbreaking",
                    "save table contextmenu directionality emoticons template paste textcolor",
                  ],
                  toolbar:
                    "insertfile undo redo | styleselect | bold italic | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | link image | print preview media fullpage | forecolor backcolor emoticons",
                }}
                onEditorChange={handleEditorChange}
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <div className="section-container">
              <Typography className="section-title" style={{ fontSize: 18 }}>
                Job details
              </Typography>
              <Form.Item
                name="countries"
                label={<span style={{ fontSize: 16 }}>Job location(s)</span>}
                rules={[
                  {
                    required: true,
                    message: "Please select a location!",
                  },
                ]}
                className="section-input"
                // hasFeedback
              >
                <Select
                  showSearch
                  placeholder="&nbsp; San-Francisco, USA"
                  mode="multiple"
                  // optionFilterProp="children"
                  onSearch={(value: string) => {
                    getPlacePredictions({ input: value });
                    setValue(value);
                    // console.log(evt)
                  }}
                  loading={isPlacePredictionsLoading}
                  // onFocus={onFocus}
                  // onBlur={onBlur}
                  // onSearch={onSearch}
                  filterOption={(input, option: any) =>
                    option.children
                      ?.toLowerCase()
                      .indexOf(input?.toLowerCase()) >= 0
                  }
                >
                  {placePredictions &&
                    placePredictions.map((company: any) => {
                      return (
                        <Option
                          key={company.description}
                          value={company.description}
                        >
                          {company.description}
                        </Option>
                        // <Option key={company._id} value={company.name}>
                        //   {company.name}
                        // </Option>
                      );
                    })}
                </Select>
              </Form.Item>
              {/* <Form.Item
              name="experiance"
              label="Required experiance"
              rules={[
                {
                  required: true,
                  message: "Please select a value for experiance!",
                },
              ]}
              // hasFeedback
            >
              <Select
                placeholder="Select required experiance"
                filterOption={(input, option: any) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
              >
                <Option value="1-3">1-3</Option>
                <Option value="3-5">3-5</Option>
              </Select>
            </Form.Item> */}

              <Form.Item
                name="requiredSkills"
                label={<span style={{ fontSize: 16 }}>Expected skills</span>}
                rules={[
                  {
                    required: true,
                    message: "Please select a value for skills!",
                  },
                ]}
                className="section-input"
                // hasFeedback
              >
                <Select
                  showSearch
                  placeholder="&nbsp; Strategy, Project management, People management..."
                  mode="tags"
                  maxTagTextLength={35}
                  filterOption={(input, option: any) =>
                    option.children
                      ?.toLowerCase()
                      .indexOf(input?.toLowerCase()) >= 0
                  }
                >
                  {skills &&
                    skills.map((skill: any) => (
                      <Option key={skill._id} value={skill.title}>
                        {skill.title}
                      </Option>
                    ))}
                </Select>
              </Form.Item>
              <Form.Item
                name="recruiters"
                label={<span style={{ fontSize: 16 }}>Recruiting team</span>}
                rules={[
                  {
                    required: true,
                    message: "Please select a value for recruiters!",
                  },
                ]}
                className="section-input"
                // hasFeedback
              >
                <Select
                  showSearch
                  disabled={!companyId && !job}
                  mode="multiple"
                  placeholder="&nbsp; John Doe, Lynn Roboson, Jim Tan..."
                  filterOption={(input, option: any) =>
                    option.children
                      ?.toLowerCase()
                      .indexOf(input?.toLowerCase()) >= 0
                  }
                  onChange={onRecruiterSelected}
                >
                  {recruiters &&
                    recruiters?.map((user: any) => (
                      <Option key={user.email} value={user.email}>
                        {user.email}
                      </Option>
                    ))}
                </Select>
              </Form.Item>
              <Form.Item
                name="leadRecruiter"
                label={<span style={{ fontSize: 16 }}>Lead recruiter</span>}
                rules={[
                  {
                    required: true,
                    message: "Please select a value for lead recruiter!",
                  },
                ]}
                className="section-input add-job-lead-recruiter-field"
                // hasFeedback
              >
                <Select
                  showSearch
                  disabled={selectedRecruiters?.length == 0 && !job}
                  placeholder={
                    <div style={{ fontSize: "14px", marginTop: "-2px" }}>
                      &nbsp; John Doe
                    </div>
                  }
                  filterOption={(input, option: any) =>
                    option.children
                      ?.toLowerCase()
                      .indexOf(input?.toLowerCase()) >= 0
                  }
                >
                  {selectedRecruiters &&
                    selectedRecruiters.map((user: any) => (
                      <Option key={user._id} value={user.value}>
                        {user.label}
                      </Option>
                    ))}
                </Select>
              </Form.Item>
              <Form.Item
                name="fields"
                label={<span style={{ fontSize: 16 }}>Job fields</span>}
                rules={[
                  {
                    required: true,
                    message: "Please select job fields!",
                  },
                ]}
                className="section-input add-job-form-job-field"
                // hasFeedback
              >
                <TreeSelect
                  showSearch
                  style={{ width: "100%" }}
                  // value={this.state.value}
                  dropdownStyle={{
                    maxHeight: 400,
                    overflow: "auto",
                  }}
                  placeholder="&nbsp;Marketing, Finance, Operations..."
                  allowClear
                  multiple
                  treeDefaultExpandAll
                  virtual={false}
                  // onChange={this.onChange}
                >
                  {jobFieldsTreeList()}
                </TreeSelect>
              </Form.Item>
            </div>
            <div className="section-container">
              <Typography className="section-title" style={{ fontSize: 18 }}>
                Yearly compensation offer(in K){" "}
              </Typography>
              <Row>
                {/* <Col span={12}>
                  <Form.Item
                    name="contractType"
                    label="Contract type"
                    rules={[
                      {
                        required: true,
                        message: "Please select a contract type!",
                      },
                    ]}
                    className="section-input"
                    // hasFeedback
                  >
                    <Select
                      showSearch
                      placeholder="Select contract type"
                      filterOption={(input, option: any) =>
                        option.children
                          ?.toLowerCase()
                          .indexOf(input?.toLowerCase()) >= 0
                      }
                    >
                      {contractTypes &&
                        contractTypes.map((contractType: any) => (
                          <Option
                            key={contractType._id}
                            value={contractType.name}
                          >
                            {contractType.name}
                          </Option>
                        ))}
                    </Select>
                  </Form.Item>
                </Col> */}
                <Col span={11}>
                  {/* feedback is not needed since by default USD is selected */}
                  <Form.Item
                    name="currencyId"
                    label={<span style={{ fontSize: 14 }}>Currency</span>}
                    className="section-input add-job-currency-field"
                    rules={[
                      {
                        required: true,
                        message: "Please input the Currency",
                      },
                    ]}
                  >
                    <Select
                      showSearch
                      placeholder="Select a currency"
                      // defaultValue={"5eb0fa5158969aa26cb139be"} // default USD key
                      filterOption={(input, option: any) =>
                        option.children
                          ?.toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                      onChange={handleChangeCurrency}
                    >
                      {currencies &&
                        currencies.map((currency: any) => (
                          <Option key={currency._id} value={currency.name}>
                            {currency.name.toUpperCase()}
                          </Option>
                        ))}
                    </Select>
                  </Form.Item>
                </Col>
                <Col span={12} offset={1}>
                  <Form.Item
                    name="total"
                    label={<span style={{ fontSize: 14 }}>Total</span>}
                    className="section-input"
                    rules={[
                      {
                        required: true,
                        message: "Please input total compensation",
                      },
                    ]}

                    // hasFeedback
                  >
                    <Input
                      type="number"
                      min={0}
                      max={99999}
                      placeholder="0"
                      defaultValue={job && job.yearlyCompensation?.total}
                    />
                  </Form.Item>
                </Col>
                <Col span={11}>
                  <Form.Item
                    name="base"
                    label={<span style={{ fontSize: 14 }}>Base</span>}
                    className="section-input"

                    // hasFeedback
                  >
                    <Input
                      type="number"
                      min={0}
                      max={99999}
                      placeholder="0"
                      defaultValue={job && job.yearlyCompensation?.base}
                    />
                  </Form.Item>
                </Col>
                <Col span={12} offset={1}>
                  <Form.Item
                    name="onTargetEarnings"
                    label={
                      <span style={{ fontSize: 14 }}>On target earnings</span>
                    }
                    className="section-input"

                    // hasFeedback
                  >
                    <Input
                      type="number"
                      min={0}
                      max={99999}
                      placeholder="0"
                      defaultValue={
                        job && job.yearlyCompensation?.onTargetEarnings
                      }
                    />
                  </Form.Item>
                </Col>
                <Col span={11}>
                  <Form.Item
                    name="shortTermIncentives"
                    label={
                      <span style={{ fontSize: 14 }}>
                        Short term incentives
                      </span>
                    }
                    className="section-input"

                    // hasFeedback
                  >
                    <Input
                      type="number"
                      min={0}
                      max={99999}
                      placeholder="0"
                      defaultValue={
                        job && job.yearlyCompensation?.shortTermIncentives
                      }
                    />
                  </Form.Item>
                </Col>
                <Col span={12} offset={1}>
                  <Form.Item
                    name="longTermIncentives"
                    label={
                      <span style={{ fontSize: 14 }}>Long term incentives</span>
                    }
                    className="section-input"

                    // hasFeedback
                  >
                    <Input
                      type="number"
                      min={0}
                      max={99999}
                      placeholder="0"
                      defaultValue={
                        job && job.yearlyCompensation?.longTermIncentives
                      }
                      // suffix={"k"}
                    />
                  </Form.Item>
                </Col>
                <Col span={11}>
                  <Form.Item
                    name="other"
                    label={<span style={{ fontSize: 14 }}>Other</span>}
                    className="section-input"

                    // hasFeedback
                  >
                    <Input
                      type="text"
                      placeholder="0"
                      maxLength={45}
                      defaultValue={job && job.yearlyCompensation?.other}
                    />
                  </Form.Item>
                </Col>
                <Col span={12} offset={1}>
                  <Form.Item
                    name="hideSalary"
                    label={
                      <span style={{ fontSize: 14 }}>
                        Hide Compensation offer
                      </span>
                    }
                    className="section-input"
                  >
                    <p>
                      <Checkbox
                        // name="hideSalary"
                        checked={hideSalary}
                        // disabled={this.state.disabled}
                        onChange={setHideSalary}
                        style={{ marginTop: "10px" }}
                      >
                        {/* {"Hide salary package"} */}
                      </Checkbox>
                    </p>
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Form.Item
                    name="comments"
                    label={<span style={{ fontSize: 14 }}>Comment</span>}
                    className="section-input editorCol"

                    // hasFeedback
                  >
                    {/* <TextArea
                      rows={4}
                      placeholder="comments"
                      defaultValue={job && job.yearlyCompensation?.comments}
                      style={{ height: 120, resize: "none" }}
                    /> */}

                    <Editor
                      // value={job && job.yearlyCompensation?.comments}
                      apiKey="bgow6d7d89zjmvftzkn8p8gzzhkzyd551egy09ik6j400toe"
                      init={{
                        height: 300,
                        menubar: false,
                        // placeholder: "comments",
                        plugins: [
                          "advlist autolink link image lists charmap print preview hr anchor pagebreak spellchecker",
                          "searchreplace wordcount visualblocks visualchars code fullscreen insertdatetime media nonbreaking",
                          "save table contextmenu directionality emoticons template paste textcolor",
                        ],
                        inline: true,
                        toolbar:
                          "insertfile undo redo | styleselect | bold italic | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | link image | print preview media fullpage | forecolor backcolor emoticons",
                      }}
                      onEditorChange={handleCommentChange}
                    />
                  </Form.Item>
                </Col>
              </Row>
              {/* <Form.Item name="salaryRange" label="Salary Range">
                <Slider
                  onChange={salaryChangeHandler}
                  marks={marks}
                  step={5000}
                  min={0}
                  max={40000}
                  tipFormatter={(value: any) => (
                    <span>
                      {parseFloat(value)
                        .toFixed(2)
                        .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")}
                    </span>
                  )}
                  tooltipVisible={false}
                  defaultValue={salaryRange}
                  value={salaryRange}
                />
              </Form.Item> */}
            </div>
            <Form.Item
              name="ReferenceTargetProfile"
              label={
                <span style={{ fontSize: 18 }}>Reference target profile</span>
              }
            >
              <Space style={{ display: "flex" }} direction="vertical" size={1}>
                <TargetProfile
                  setIsSaveDisabled={setIsSaveDisabled}
                  label="experience"
                  addAssessment={addAssessment}
                  editAssessment={job?.assessment?.experience}
                />
                <TargetProfile
                  setIsSaveDisabled={setIsSaveDisabled}
                  label="traits"
                  addAssessment={addAssessment}
                  editAssessment={job?.assessment?.traits}
                />
              </Space>
            </Form.Item>
            <Form.Item
              name="targetCompanies"
              label={<span style={{ fontSize: "18px" }}>Target companies</span>}
              // rules={[
              //   {
              //     required: true,
              //     message: "Please select a target company!",
              //   },
              // ]}
              // hasFeedback
            >
              <Select
                showSearch
                placeholder="&nbsp; Alstom, Singapore Airlines, General Electric..."
                mode="tags"
                maxTagTextLength={40}
                filterOption={(input, option: any) =>
                  option.children
                    ?.toLowerCase()
                    .indexOf(input?.toLowerCase()) >= 0
                }
              >
                {inspiringCompanies &&
                  inspiringCompanies.map((inspiringCompany: any) => (
                    <Option
                      key={inspiringCompany._id}
                      value={inspiringCompany.name}
                    >
                      {inspiringCompany.name}
                    </Option>
                  ))}
              </Select>
            </Form.Item>
            <Form.Item
              name="language"
              className="add-job-language-field"
              label={<span style={{ fontSize: "18px" }}>Select language</span>}
              /* no need for required since 'en' is selected by default now  */
              rules={[
                {
                  required: true,
                  message: "Please select a language!",
                },
              ]}
            >
              <Select
                showSearch
                placeholder="French or English"
                /* value for language is "en" by default so need for setting defaultValue prop */
                filterOption={(input, option: any) =>
                  option.children
                    ?.toLowerCase()
                    .indexOf(input?.toLowerCase()) >= 0
                }
              >
                <Option key={"english"} value={"en"}>
                  English
                </Option>
                <Option key={"french"} value={"fr"}>
                  French
                </Option>
              </Select>
            </Form.Item>
            <Form.Item name="isDraft"></Form.Item>

            <>
              <Form.Item>
                <div className="text-right">
                  <Space style={{ justifyContent: "end" }} size="large">
                    <Button
                      className="ant-btn-draft btn_submit_job"
                      htmlType="button"
                      shape="round"
                      disabled={isSaveDisabled}
                      loading={draftLoader}
                      onClick={saveAsDraftHandler}
                      style={{ fontSize: "14px" }}
                    >
                      Save a Draft
                    </Button>
                    {hasError || (isLoading && !draftLoader) ? (
                      <Button
                        className="ant-btn-amber btn_submit_job"
                        shape="round"
                        disabled={true}
                        loading={true}
                        style={{ fontSize: "14px" }}
                      >
                        {/* {editMode || location?.state?.operation == "edit"
                          ? "Save"
                          : "Post job"} */}
                        Save
                      </Button>
                    ) : (
                      <Button
                        className="ant-btn-amber btn_submit_job"
                        htmlType="submit"
                        shape="round"
                        disabled={isSaveDisabled}
                        loading={isLoading && !draftLoader}
                        style={{ fontSize: "14px" }}
                      >
                        {/* {editMode || location?.state?.operation == "edit"
                          ? "Save"
                          : "Post job"} */}
                        Save
                      </Button>
                    )}
                  </Space>
                </div>
              </Form.Item>
            </>
          </Col>
        </Row>

        <Modal
          visible={imagePreviewModel}
          title={"Preview image"}
          centered
          footer={null}
          onCancel={() => {
            setImagePreviewModal(false);
          }}
        >
          <img
            alt="example"
            style={{ width: 475, height: 475 }}
            src={imagePreview}
          />
        </Modal>
        <Modal
          className="preview-modal"
          centered
          visible={imagePreviewModelBanner}
          title={"Preview image"}
          footer={null}
          onCancel={() => {
            setImagePreviewModalBanner(false);
          }}
        >
          <img
            alt="example"
            style={{ width: "100%", height: 475 }}
            src={imagePreviewBanner}
          />
        </Modal>
      </Form>
    </>
  );
};

export default JobForm;
