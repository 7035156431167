import { PlusCircleOutlined } from "@ant-design/icons";
import { Col, DatePicker, Form, Input, Row, Select, Switch } from "antd";
import moment from "moment";
import React, { useContext, useEffect, useState } from "react";
import HandleLogo from "./HandleLogo";
import useGoogle from "react-google-autocomplete/lib/usePlacesAutocompleteService";
import EXP_PLACEHOLDER from "assets/icons/DetailedProfile/experiencePlaceholder.svg";
import DELETE_ICON from "assets/icons/DetailedProfile/delete.svg";
import { Option } from "antd/lib/mentions";
import _ from "lodash";
import { EditContext } from "containers/Candidates/Profile/Context/EditContext";

interface ExperienceFormProps {
  experience: any;
  candidateId?: any;
  isNestedExp?: any;
}

const ExperienceForm = ({
  experience,
  candidateId,
  isNestedExp,
}: ExperienceFormProps) => {
  const [values, setValues] = useState<any>({});
  const [form] = Form.useForm();
  const [current, setCurrent] = useState(false);
  const [uploadPicture, setUploadPicture] = useState("");
  const editContext = useContext<any>(EditContext);
  const { setData, setUpdatedData } = editContext;
  const [location, setLocation] = useState(
    experience?.current ? undefined : experience?.location
  );

  const { placePredictions, getPlacePredictions, isPlacePredictionsLoading } =
    useGoogle({
      apiKey: "AIzaSyACXA9qapZNvq51WpF69eIH908sX_efNMs",
      options: {
        types: ["geocode"],
      },
    });
  useEffect(() => {
    experience?.current !== "" &&
      experience?.current !== null &&
      experience?.current !== undefined &&
      setCurrent(experience?.current);
  }, [experience?.current]);
  useEffect(() => {
    const experienceData = {
      ...experience,
      startYear: experience?.startYear && moment(experience.startYear),
      endYear: experience?.endYear && moment(experience.endYear),
      current: !!experience?.current,
    };
    if (
      typeof experienceData?.endYear === "string" &&
      experienceData?.endYear?.includes("NaN")
    ) {
      experienceData.endYear = undefined;
    }
    setValues(experienceData);
    form.setFieldsValue(experienceData);
  }, [experience.index, experience]);

  useEffect(() => {
    setLocation(experience?.location);
  }, [experience]);
  useEffect(() => {
    setUploadPicture(experience?.logo);
  }, [experience?.logo]);

  if (current) {
    form.setFieldsValue({ endYear: "" }); // Set endYear to an empty string
  }

  useEffect(() => {
    if (
      typeof experience?.endYear === "string" &&
      experience?.endYear?.includes("NaN")
    ) {
      experience.endYear = undefined;
      form.setFieldValue("endYear", undefined);
    }
  }, [experience?.endYear, form.getFieldValue("endYear")]);

  const updateCompany = (oldCompany: any, company: any, exp: any) => {
    exp = exp?.map((e: any) => {
      if (e?.company == oldCompany) {
        e.company = company;
      }
      return e;
    });

    setUpdatedData({
      ...editContext?.updatedData,
      experiences: { ...exp },
    });
    setData({
      ...editContext?.candidate,
      experiences: { ...exp },
    });
  };

  const handleSave = (v: any) => {
    const newVal = { ...values, ...v };
    const exp = _.cloneDeep(
      editContext?.updatedData?.experiences?.length
        ? editContext?.updatedData?.experiences
        : editContext?.candidate?.experiences
    );
    const newExp = {
      _id: experience?._id,
      position: newVal?.position,
      company: newVal?.company,
      startYear: newVal?.startYear?._d
        ? new Date(newVal?.startYear?._d)?.getFullYear()?.toString()
        : null,
      endYear: newVal?.endYear?._d
        ? new Date(newVal?.endYear?._d)?.getFullYear()?.toString()
        : null,
      current: newVal?.current,
      location: newVal?.location,
      logo: newVal?.logo,
    };
    if (experience?.company != newExp?.company) {
      updateCompany(experience?.company, newExp?.company, exp);
    }
    const updatedData = exp?.map((e: any) => {
      if (
        isNestedExp &&
        e?.company === experience?.company &&
        e?._id === experience?._id
      ) {
        return newExp;
      } else if (!isNestedExp && e?._id === experience?._id) {
        return newExp;
      } else {
        return e;
      }
    });

    setUpdatedData({
      ...editContext?.updatedData,
      experiences: updatedData,
    });
    setData({
      ...editContext?.candidate,
      experiences: updatedData,
    });
  };

  const handleDelete = () => {
    let exp = editContext?.candidate.experiences || [];
    exp = exp?.filter((e: any) => {
      if (
        e?.position == experience?.position &&
        e?.company == experience?.company &&
        e?._id == experience?._id
      ) {
        // null;
      } else {
        return e;
      }
    });
    setUpdatedData({
      ...editContext?.updatedData,
      experiences: exp,
    });
    setData({
      ...editContext?.candidate,
      experiences: exp,
    });
  };
  const addNew = () => {
    let exp = editContext?.candidate.experiences || [];
    exp = [
      ...exp,
      { company: experience?.company, _id: Math.random().toString() },
    ];
    setUpdatedData({
      ...editContext?.updatedData,
      experiences: exp,
    });
    setData({
      ...editContext?.candidate,
      experiences: exp,
    });
  };
  useEffect(() => {
    editContext.setValidationForms((s: any) => [...s, form]);
  }, [form]);
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "space-between",
        width: "100%",
        gap: "20px",
      }}
    >
      <div
        style={{ width: "100%", gap: "12px" }}
        className="experience-container"
      >
        {!isNestedExp && (
          <div>
            <HandleLogo
              candidateId={candidateId}
              uploadPicture={
                uploadPicture?.length ? uploadPicture : EXP_PLACEHOLDER
              }
              handleSave={handleSave}
              setUploadPicture={setUploadPicture}
              form={form}
            />
          </div>
        )}
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            width: "100%",
            maxWidth: "400px",
          }}
        >
          <Form
            form={form}
            layout="vertical"
            name="recruiter-experience"
            onValuesChange={(v) => {
              setValues({ ...values, ...v });
              handleSave(v);
            }}
            style={{ width: "100%" }}
          >
            <Row
              justify="center"
              className="pb-0"
              gutter={16}
              style={{ display: "flex", justifyContent: "space-between" }}
            >
              <Col span={12}>
                <Form.Item
                  style={{ marginBottom: "6px" }}
                  label={
                    <span
                      style={{
                        fontSize: "14px",
                        fontWeight: "400",
                        color: "#495057",
                      }}
                    >
                      Start
                    </span>
                  }
                  name="startYear"
                  rules={[
                    {
                      required: true,
                      validator: (_, value) => {
                        if (!value) {
                          return Promise.reject("Start date is required!");
                        }

                        if (moment(value).isAfter(moment())) {
                          return Promise.reject(
                            "Start date cannot be a future date!"
                          );
                        }
                        return Promise.resolve();
                      },
                    },
                  ]}
                >
                  <DatePicker
                    size="small"
                    picker="year"
                    placeholder="Select date"
                    style={{ height: "26px" }}
                  />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  style={{ marginBottom: "6px" }}
                  label={
                    <span
                      style={{
                        fontSize: "14px",
                        fontWeight: "400",
                        color: "#495057",
                      }}
                    >
                      End
                    </span>
                  }
                  name="endYear"
                  dependencies={["current", "startYear"]}
                  rules={[
                    {
                      required: current == false,
                      message: "End date is required",
                    },
                    ({ getFieldValue }) => ({
                      validator(_, value) {
                        const startDate = getFieldValue("startYear");
                        if (
                          value &&
                          startDate &&
                          moment(value).isBefore(moment(startDate))
                        ) {
                          return Promise.reject(
                            new Error("End date cannot be before start date!")
                          );
                        }
                        return Promise.resolve();
                      },
                    }),
                  ]}
                >
                  <DatePicker
                    size="small"
                    picker="year"
                    placeholder="Select date"
                    style={{ height: "26px" }}
                    disabled={values.current ? true : false}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row justify="space-between" className="pb-0" gutter={16}>
              <Col span={16}>
                <Form.Item
                  style={{ marginBottom: "0px", marginLeft: "4px" }}
                  label={
                    <span
                      style={{
                        fontSize: "14px",
                        fontWeight: "400",
                        color: "#495057",
                      }}
                    >
                      Currently working there
                    </span>
                  }
                />
              </Col>
              <Col span={5}>
                <Form.Item style={{ marginBottom: "0px" }} name="current">
                  <Switch
                    size="small"
                    onChange={(e) => setCurrent(e)}
                    checked={!!values?.current}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Form.Item
              style={{
                marginBottom: "6px",
                display: isNestedExp ? "none" : "block",
                marginTop: "-22px",
              }}
              name="company"
              required
              rules={[
                {
                  required: true,
                  message: "Company name is required",
                },
              ]}
            >
              <Input
                size="small"
                style={{ height: "28px" }}
                placeholder="Enter your company name"
              />
            </Form.Item>
            <Form.Item
              style={{
                marginBottom: "6px",
                marginTop: isNestedExp ? "-22px" : "0px",
              }}
              name="position"
              required
              rules={[
                {
                  required: true,
                  message: "Position is required",
                },
              ]}
            >
              <Input
                size="small"
                placeholder="Enter your job title"
                style={{ height: "28px" }}
              />
            </Form.Item>
            <Form.Item
              name="locations"
              style={{
                color: "black",
                height: "28px",
                maxHeight: "28px",
                minHeight: "28px",
              }}
              rules={[
                {
                  required: true,
                  message: "Please select a location!",
                },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    const loc = getFieldValue("locations");
                    if (loc?.length < 2) {
                      return Promise.reject(
                        new Error("Location field is required")
                      );
                    }
                    return Promise.resolve();
                  },
                }),
              ]}
              className="myprofile-location myprofile-location-input min-h-28px"
              initialValue={
                experience?.location?.city || experience?.location?.country
                  ? experience?.location?.city +
                    " " +
                    experience?.location?.country
                  : undefined
              }
            >
              <Select
                size="small"
                style={{ height: "28px", maxHeight: "28px", minHeight: "28px" }}
                showSearch
                placeholder={
                  <span style={{ fontSize: "14px" }}>
                    Indicate your location
                  </span>
                }
                onSearch={(value: string) => {
                  getPlacePredictions({ input: value });
                }}
                onChange={(value) => {
                  const city = value?.split(", ")[0];
                  const country = value?.split(", ")[1];
                  const updatedLocation = { country, city };
                  form.setFieldValue("location", updatedLocation);
                  setLocation(updatedLocation);
                  handleSave({ location: updatedLocation });
                }}
                loading={isPlacePredictionsLoading}
                filterOption={(input, option: any) =>
                  option.children
                    ?.toLowerCase()
                    .indexOf(input?.toLowerCase()) >= 0
                }
              >
                {placePredictions &&
                  placePredictions.map((company: any) => {
                    return (
                      <Option
                        key={company.description}
                        value={company.description}
                      >
                        {company.description}
                      </Option>
                    );
                  })}
              </Select>
            </Form.Item>
          </Form>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            marginLeft: "auto",
          }}
        >
          {!isNestedExp && (
            <span onClick={addNew} style={{ cursor: "pointer" }}>
              <PlusCircleOutlined
                style={{ fontSize: "18px", marginLeft: "1px" }}
              />
            </span>
          )}
          <span onClick={handleDelete} style={{ cursor: "pointer" }}>
            <img
              src={DELETE_ICON}
              alt="DELETE_ICON"
              style={{ height: "20px", width: "20px" }}
            />
          </span>
        </div>
      </div>
    </div>
  );
};

export default ExperienceForm;
