import React, { useEffect, useState } from "react";
import { Button, notification, Space, Tooltip, Typography } from "antd";
import {
  ApproveIcon,
  LikedIcon,
  LikeIcon,
  DislikedIcon,
  HiredIcon,
  SelectedIcon,
  NewCandidateIcon,
  MatchedIcon,
  RejectedIcon,
} from "assets/icons/DetailedProfile";
import ChatIcon from "assets/icons/DetailedProfile/chat.svg";
import ChatIconBlip from "assets/icons/DetailedProfile/chat-blip.svg";
import { useLocation } from "react-router-dom";
// import MyModal from "../../../../../components/UI/Modal";
import ChatWindowComponent from "containers/Candidates/ChatWindow";
import ModalComponent from "../../../../../components/UI/Modal/index";
import { useSelector } from "react-redux";
import { MatchsState } from "store/matchs/types";
import { removeMultipleNotifications } from "components/TopBar/partials/NotificationDropdown/notifications";
import {
  useGetCompanyDetailFromJobQuery,
  useRecruiterActionMutation,
} from "graphql/generated/graphql";
import Buttons from "components/UI/Buttons";
import MatchModal from "components/MatchModal/MatchModal";
import { DeleteAllNotifications } from "store/notifications/actions";
import { useMutation } from "react-apollo";
import { notificationsActionTypes } from "store/notifications/types";
import { useDispatch } from "react-redux";
import { from } from "apollo-boost";

interface PropsType {
  candidate: any;
  match: any;
  jobData?: any;
}
interface Notification {
  _id: string;
  userId: string;
  companyId: string;
  jobId: string;
  matchId: string;
  createdAt: any;
  updatedAt: any;
  isRead: boolean;
  type: string;
  notificationHeader: string;
  notificationBody: string;
}
interface PropsDislike {
  from: String;
}

const Styles = {
  flexDefault: {
    display: "flex",
    gap: "15px",
    // marginTop: '-52px',
    // marginLeft: '10px',
  },
};

let status = "null";
const Status: React.FC<PropsType> = ({ candidate, match, jobData }) => {
  const dispatch = useDispatch();
  const [user, setUser]: any = useState(null);
  const location: any = useLocation();
  const [candidateIndex, setCandidateIndex]: any = useState(null);
  const candidateArray = location?.state?.from;
  const [isMatchModal, setIsMatchModal] = useState<any>(false);
  const [selectedCandidate, setSelectedCandidate] = useState({
    firstName: "",
    profilePicture: "",
    lastName: "",
    company: {
      logoUrl: "",
      name: "",
    },
    _id: "",
  });
  useEffect(() => {
    setSelectedCandidate({
      firstName: candidate?.firstName,
      profilePicture: candidate?.profilePicture,
      lastName: candidate?.lastName,
      company: {
        logoUrl: jobData?.getJobDetails?.company?.logoUrl,
        name: candidate?.company,
      },
      _id: candidate?._id,
    });
  }, [candidate]);
  useEffect(() => {
    getUser();
  }, []);

  //
  const {
    data,
    loading: companyLoading,
    error,
  } = useGetCompanyDetailFromJobQuery({
    variables: {
      jobId: location?.state?.jobId,
    },
  });

  //click on notify
  const [setMultipleNotificationsToRead, { data: data1 }] = useMutation(
    removeMultipleNotifications
  );
  const clickOnNotification = (matchId: string, type: string) => {
    const foundNotification = notifications
      .filter(
        (notif: Notification) => notif.type == type && notif.matchId == matchId
      )
      .map((notif: Notification) => notif._id);

    if (foundNotification.length) {
      setMultipleNotificationsToRead({
        variables: {
          notificationsArray: [...foundNotification],
        },
      })
        .then((data: any) => {
          dispatch(DeleteAllNotifications(foundNotification));
        })
        .catch((err) => {
          console.log("An error occured while deleting all notifications");
          console.log({ err });
        });
    }
  };

  const redirectedDataObject = {
    _id: location.state?.matchId,
    jobListing: { title: location.state?.jobName, _id: location.state?.jobId },
    company: location.state?.company,
  };
  const machesCandidatesFromStore = useSelector(
    (state: MatchsState) => state.matchs?.matchedCandidates
  );
  const selectedCandidatesFromStore = useSelector(
    (state: MatchsState) => state.matchs?.selectedCandidates
  );
  const dislikedCandidatesFromStore = useSelector(
    (state: MatchsState) => state.matchs?.disLikedCandidates
  );
  const getUser = async () => {
    const remembered: any = await localStorage.getItem("user");
    setUser(JSON.parse(remembered));
    if (location?.state?.from) {
      const index = candidateArray.findIndex(
        (cand) => cand._id == location?.state?.matchId
      );
      setCandidateIndex(index);
    }
  };

  useEffect(() => {
    const { recruiterAction, candidateAction } = match;
    if (
      (recruiterAction == null || recruiterAction == "null") &&
      (candidateAction == "like" || candidateAction == null)
    ) {
      status = NewCandidateIcon;
    }
    if (recruiterAction == "dislike") status = DislikedIcon;
    if (recruiterAction == "like") status = LikeIcon;
    if (recruiterAction == "like" && candidateAction == "like")
      status = MatchedIcon;
    if (recruiterAction == "selected" && candidateAction == "like")
      status = SelectedIcon;
    if (recruiterAction == "rejected" && candidateAction == "like")
      status = RejectedIcon;
    if (recruiterAction == "hired" && candidateAction == "like")
      status = HiredIcon;
  }, [match]);

  // THIS CODE IS TO REMOVE BLIP ICON
  /** THIS IS NEW CODE AS WELL */
  const { notifications } = useSelector((state: any) => state.notifications);
  const [chatNotifications, setchatNotifications] = useState([]); //this will contain all the notifications regarding the chat
  const [addRecruiterActionMutation, { loading }] =
    useRecruiterActionMutation();
  useEffect(() => {
    const chatNotifications = notifications.filter(
      (notification: Notification) => notification.type === "chat"
    );
    setchatNotifications([...chatNotifications]);
    // we will only save the match notifications in this arrray

    return () => {};
  }, [notifications]);

  const candidateLikeOrDisLikeHandler = (
    matchId: any,
    action: any,
    from: any,
    lastAction?: any
  ) => {
    let actionCondition = action;
    addRecruiterActionMutation({
      variables: {
        matchId: matchId,
        action: action,
        from: from,
        lastAction,
      },
    })
      .then(({ data }: any) => {
        let actionMessage = "";
        switch (action) {
          case "like":
            actionMessage = "liked";
            setIsMatchModal(true);
            break;
          case "dislike":
            actionMessage = "disliked";
            break;
          case "selected":
            actionMessage = "selected";
            break;
          case "rejected":
            actionMessage = "rejected";
            break;
          case "hired":
            actionMessage = "hired";
            break;
          default:
            break;
        }
        notification["success"]({
          message: `You have ${actionMessage} ${data?.recruiterAction.candidateListing.firstName} ${data?.recruiterAction.candidateListing.lastName}`,
        });
      })
      .catch((err) => {
        console.log(err);
        const errors = err.graphQLErrors.map((el: any) => el.message);
        notification["error"]({
          message:
            actionCondition == "dislike"
              ? "Unable to dislike"
              : "Unable to create job",
          description:
            actionCondition == "dislike"
              ? "This candidate has already been disliked"
              : errors.map((er: any) => <div key={er}>{er}</div>),
          duration: 3,
        });
      });
  };

  const DislikeAction: React.FC<PropsDislike> = ({ from }) => {
    return (
      <Space>
        {/* <Buttons
          type="btn-dislike mt-1"
          onClick={() =>
            candidateLikeOrDisLikeHandler(match._id, "dislike", "fromLiked")
          }
          toolTipTitle="Dislike candidate"
        /> */}
        <Tooltip title="Dislike">
          <img
            // className="ant-btn-red"
            style={{
              border: null,
              width: 32,
              // marginRight: 15,
              // marginLeft: 15,
              cursor: "pointer",
              boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
              borderRadius: 30,
            }}
            onClick={() =>
              candidateLikeOrDisLikeHandler(
                match._id,
                "dislike",
                "fromLiked",
                from
              )
            }
            src={DislikedIcon}
          />
        </Tooltip>
      </Space>
    );
  };

  const LikeAction = () => {
    return (
      <Space>
        {/* <Buttons
          type="btn-like mt-1"
          onClick={() =>
            candidateLikeOrDisLikeHandler(match._id, "like", null)
          }
          toolTipTitle="Like candidate"
        /> */}

        <Tooltip title="Like">
          <img
            // className="ant-btn-red"
            style={{
              border: null,
              width: 32,
              // marginRight: 15,
              // marginLeft: 15,
              cursor: "pointer",
              boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
              borderRadius: 30,
            }}
            onClick={() =>
              candidateLikeOrDisLikeHandler(match._id, "like", null)
            }
            src={LikeIcon}
          />
        </Tooltip>
      </Space>
    );
  };

  const ReSelectAction = () => {
    return (
      <Space>
        <Tooltip title="Reselect">
          <img
            // className="ant-btn-red"
            style={{
              border: null,
              width: 32,
              // marginRight: 15,
              // marginLeft: 15,
              cursor: "pointer",
              boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
              borderRadius: 30,
            }}
            onClick={() =>
              candidateLikeOrDisLikeHandler(match._id, "selected", null)
            }
            src={SelectedIcon}
          />
        </Tooltip>
      </Space>
    );
  };
  const SelectAction = () => {
    return (
      <Space>
        <Tooltip title="Select">
          <img
            // className="ant-btn-red"
            style={{
              border: null,
              width: 32,
              // marginRight: 15,
              // marginLeft: 15,
              cursor: "pointer",
              boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
              borderRadius: 30,
            }}
            onClick={() =>
              candidateLikeOrDisLikeHandler(match._id, "selected", null)
            }
            src={SelectedIcon}
          />
        </Tooltip>
      </Space>
    );
  };

  const HiredAction = () => {
    return (
      <Space>
        <Tooltip title="Hire">
          <img
            // className="ant-btn-red"
            style={{
              border: null,
              width: 32,
              // marginRight: 15,
              // marginLeft: 15,
              cursor: "pointer",
              boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
              borderRadius: 30,
            }}
            onClick={() =>
              candidateLikeOrDisLikeHandler(match._id, "hired", null)
            }
            src={HiredIcon}
          />
        </Tooltip>
      </Space>
    );
  };

  const RejectedAction = () => {
    return (
      <Space>
        <Tooltip title="Reject">
          <img
            // className="ant-btn-red"
            style={{
              border: null,
              width: 32,
              // marginRight: 15,
              // marginLeft: 15,
              cursor: "pointer",
              boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
              borderRadius: 30,
            }}
            onClick={() =>
              candidateLikeOrDisLikeHandler(match._id, "rejected", null)
            }
            src={RejectedIcon}
          />
        </Tooltip>
      </Space>
    );
  };

  const ChatAction = () => {
    return (
      <ModalComponent
        titleType="button"
        enableCustomBtnIcon={true}
        customBtnIcon={
          chatNotifications.find(
            (notification: Notification) =>
              notification.matchId === location?.state?.matchId
          ) ? (
            <Tooltip title="Chat">
              <span>
                <img
                  style={{
                    cursor: "pointer",
                    width: 32,
                    boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
                    borderRadius: "15px",
                    borderTopRightRadius: "2px",
                    // marginLeft: 15,
                    // marginRight: 15,
                  }}
                  src={ChatIconBlip}
                />
              </span>
            </Tooltip>
          ) : (
            <Tooltip title="Chat">
              <span>
                <img
                  style={{
                    cursor: "pointer",
                    width: 32,
                    boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
                    borderRadius: 30,
                    // marginLeft: 15,
                    // marginRight: 15,
                  }}
                  src={ChatIcon}
                />
              </span>
            </Tooltip>
          )
        }
        onOK={() => {}}
        width={500}
        footer={null}
        ModalTytle="huu"
        titleBtnClass="ant-btn-info"
        record={
          // candidateIndex == -1
          //   ? redirectedDataObject
          //   : candidateArray[candidateIndex]
          location?.state?.selectedCandidate
            ? location?.state?.selectedCandidate
            : redirectedDataObject
        }
        matchId={
          location?.state?.selectedCandidate
            ? location?.state?.selectedCandidate
            : location?.state?.matchId
        }
        candidate={
          location?.state?.selectedCandidate
            ? location?.state?.selectedCandidate
            : candidate
        }
        recruiter={user}
      >
        <div
          style={{
            paddingTop: 35,
            paddingBottom: 35,
          }}
        ></div>
      </ModalComponent>
    );
  };
  const handleCancel = () => {
    setIsMatchModal(false);
  };
  return (
    <>
      {isMatchModal && (
        <MatchModal
          showMatchModal={isMatchModal}
          handleCancel={handleCancel}
          selectedCandidatesFromStore={selectedCandidatesFromStore}
          dislikedCandidatesFromStore={dislikedCandidatesFromStore}
          machesCandidatesFromStore={machesCandidatesFromStore}
          selectedCandidate={selectedCandidate}
          showChatModal={true}
          chatNotifications={chatNotifications}
          data={data}
          clickOnNotification={clickOnNotification}
          jobId={jobData?._id}
          match={match}
        />
      )}
      <div style={{ ...Styles.flexDefault, marginTop: 15 }}>
        <div style={Styles.flexDefault}>
          {match.recruiterAction == null && match.candidateAction == "like" && (
            <>
              <LikeAction />
              <DislikeAction from={"new-candidate-disliked"} />
              {/* <ChatAction /> */}
            </>
          )}
          {match.recruiterAction == "like" &&
            match.candidateAction == "like" && (
              <>
                <DislikeAction from={"match-disliked"} />
                <SelectAction />
                <ChatAction />
              </>
            )}
          {match.recruiterAction == "rejected" && (
            <>
              <ReSelectAction />
              <ChatAction />
            </>
          )}
          {match.recruiterAction == "dislike" && (
            <>
              <LikeAction />
              {match.lastAction == "match-disliked" && <ChatAction />}
            </>
          )}
          {match.recruiterAction == "selected" && (
            <>
              <HiredAction />
              <RejectedAction />
              <ChatAction />
            </>
          )}
          {match.recruiterAction == "hired" && (
            <>
              <ChatAction />
            </>
          )}
          {/* {candidate && match?.lastAction != null && <ChatAction />} */}
        </div>
      </div>
    </>
  );
};

export default Status;
