import { useGetCandidateQuery } from "graphql/generated/graphql";
import React, { useState } from "react";
import { useParams } from "react-router-dom";

type EditType = {
  edit: Boolean;
  editProfile: () => void;
  cancelEditProfile: () => void;
  setData: (data: any) => void;
  candidate: any;
  setCandidateMatch: (data: any) => void;
  match: any;
  updatedData: any;
  setUpdatedData: (data: any) => void;
  validationForms: any;
  setValidationForms: any;
};

export const EditContext = React.createContext<EditType | null>(null);

const EditProvider: React.FC<React.ReactNode> = ({ children }) => {
  const [edit, setEdit] = React.useState<boolean>(false);
  const [candidate, setCandidate] = React.useState<any>();
  const [match, setMatch] = React.useState<any>();
  const [updatedData, setUpdateData] = React.useState<any>({});
  const [validationForms, setValidationForms] = useState([]);

  const editProfile = () => {
    setEdit(true);
  };

  const cancelEditProfile = () => {
    setEdit(false);
  };

  const setData = (data: any) => {
    setCandidate(data);
  };
  const setUpdatedData = (data: any) => {
    setUpdateData(data);
  };
  const setCandidateMatch = (data: any) => {
    setMatch(data);
  };

  return (
    <EditContext.Provider
      value={{
        edit,
        editProfile,
        match,
        setCandidateMatch,
        cancelEditProfile,
        candidate,
        setData,
        updatedData,
        setUpdatedData,
        validationForms,
        setValidationForms,
      }}
    >
      {children}
    </EditContext.Provider>
  );
};

export default EditProvider;
