import { LoadingOutlined, PlusOutlined } from "@ant-design/icons";
import { Button, Card, Col, message, Row, Skeleton } from "antd";
import ErrorCard from "components/UI/ErrorCard/ErrorCard";
import PageTitle from "components/UI/PageTitle/PageTitle";
import React, { useContext, useEffect, useState } from "react";
import { Fade } from "react-awesome-reveal";
import {
  useGetSingleRecruiterQuery,
  useUpdateRecruiterMutation,
} from "../../../graphql/generated/graphql";
import ActivityLogDrawer from "./partials/ActivityLogDrawer";
import GeneralFormDrawer from "./partials/GeneralFormDrawer";
import ResetPasswordFormDrawer from "./partials/ResetPasswordFormDrawer";
import { Education, Experience } from "containers/Candidates/Profile";

import "./styles.scss";
import ProfileCard from "./partials/ProfileCard";
import SummaryCard from "./partials/SummaryCard";
import EDIT_ICON from "assets/icons/DetailedProfile/editP.svg";
import CROSS_ICON from "assets/icons/DetailedProfile/cross.svg";
import TICK_ICON from "assets/icons/DetailedProfile/tick.svg";
import { EditContext } from "containers/Candidates/Profile/Context/EditContext";

interface ViewRecruiterProps {}

const ViewRecruiter: React.FC<ViewRecruiterProps> = () => {
  const { data, loading, error, refetch }: any = useGetSingleRecruiterQuery({
    fetchPolicy: "network-only",
  });

  const [isEditing, setIsEditing] = useState(false);
  const [Recruiter, setRecruiter]: any = useState(null);
  const [summary, setSummary] = useState("");
  const [displayGeneralDrawer, setDisplayGeneralDrawer] = useState(false);
  const [displayActivityDrawer, setDisplayActivityDrawer] = useState(false);
  const [updatedData, setUpdatedData] = useState<any>(Recruiter);
  const [displayResetPasswordDrawer, setDisplayResetPasswordDrawer] =
    useState(false);

  const [candidateData, setCandidateData] = useState<any | null | undefined>(
    null
  );
  const editContext = useContext(EditContext);
  const [upload, setUpload] = useState({
    loading: false,
    url: Recruiter?.profilePicture
      ? Recruiter?.profilePicture
      : "https://st3.depositphotos.com/4111759/13425/v/600/depositphotos_134255532-stock-illustration-profile-placeholder-male-default-profile.jpg",
    error: "",
  });

  useEffect(() => {
    if (editContext?.candidate) setCandidateData(editContext?.candidate);
  }, [editContext?.candidate]);
  const [updateRecruiterMutation, { loading: loading1 }] =
    useUpdateRecruiterMutation();

  useEffect(() => {
    if (data && data.getSingleRecruiter) {
      setRecruiter(data.getSingleRecruiter);
      editContext.setData(data.getSingleRecruiter);
      editContext.setUpdatedData(data.getSingleRecruiter);
      setSummary(data.getSingleRecruiter?.description);
      setUpload({
        loading: false,
        error: "",
        url: data.getSingleRecruiter?.profilePic
          ? data.getSingleRecruiter?.profilePic
          : "https://st3.depositphotos.com/4111759/13425/v/600/depositphotos_134255532-stock-illustration-profile-placeholder-male-default-profile.jpg",
      });
    }
  }, [data]);

  useEffect(() => {
    if (
      !upload.loading &&
      upload.url !== Recruiter?.profilePic &&
      Recruiter !== null
    ) {
      updateRecruiter({ profilePic: upload.url });
    }
  }, [upload.url]);

  useEffect(() => {
    if (Recruiter) {
      const firstName = Recruiter?.fullName?.split(" ")[0];
      const lastName = Recruiter?.fullName?.split(" ")[1];
      Recruiter.firstName = firstName;
      Recruiter.lastName = lastName;
      setUpdatedData(Recruiter);
    }
  }, [Recruiter]);

  useEffect(() => {
    editContext.setUpdatedData((s: any) => ({ ...s, description: summary }));
  }, [summary]);

  const updateRecruiter = (update: any) => {
    const updatedExp = update?.experiences?.map((exp: any) => {
      return {
        position: exp?.position,
        company: exp?.company,
        startYear: exp?.startYear,
        endYear: exp?.endYear,
        current: exp?.current,
        location: {
          city: exp?.location?.city,
          country: exp?.location?.country,
        },
        logo: exp?.logo,
      };
    });
    const updatedEdu = update?.educations?.map((edu: any) => {
      return {
        schoolName: edu?.schoolName,
        degree: edu?.degree,
        startYear: edu?.startYear,
        endYear: edu?.endYear,
        current: edu?.current,
        location: {
          city: edu?.location?.city,
          country: edu?.location?.country,
        },
        logo: edu?.logo,
      };
    });
    updateRecruiterMutation({
      variables: {
        recruiterId: Recruiter._id,
        recruiter: {
          fullName: update?.fullName,
          profilePic: update?.profilePic,
          title: update?.title,
          description: update?.description,
          company: update?.company,
          location: {
            country: update?.location?.country,
            city: update?.location?.city,
          },
          facebook: update?.facebook,
          twitter: update?.twitter,
          github: update?.github,
          linkedIn: update?.linkedIn,
          user: { email: update?.email || update?.user?.email },
          experiences: updatedExp,
          educations: updatedEdu,
        },
      },
    })
      .then(({ data }) => {
        setRecruiter({ ...data?.updateRecruiter });
        setDisplayGeneralDrawer(false);
        refetch();
        setIsEditing(false);
        editContext.cancelEditProfile();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleSave = async () => {
    const updatedData = editContext.updatedData;

    let updatedObj: any;

    if (updatedData.currentLocation) {
      const {
        currentLocation: { city, country },
        ...rest
      } = updatedData;

      updatedObj = {
        city,
        country,
        ...rest,
      };
    } else {
      updatedObj = updatedData;
    }
    updatedObj.experiences = updatedObj?.experiences?.map((exp: any) => {
      const { commentData, ...rest } = exp;
      if (rest?._id?.includes(".")) {
        const { _id, ...restexp } = exp;
        return restexp;
      } else {
        return rest;
      }
    });

    updatedObj.education = updatedObj?.education?.map((edu: any) => {
      if (edu?._id?.includes(".")) {
        const { _id, ...rest } = edu;
        return rest;
      } else {
        return edu;
      }
    });

    let errors: any = [];
    const validations = editContext?.validationForms?.map(async (form: any) => {
      try {
        await form.validateFields();
      } catch (res: any) {
        if (res?.errorFields?.length) {
          errors = [...errors, ...res.errorFields];
        }
      }
    });

    // Wait for all validations to finish
    await Promise.all(validations);

    if (errors?.length > 0) {
      console.log(errors);
      message.error({
        content: errors[0]?.errors[0] || "Something went wrong!",
        // key,
        duration: 5,
      });
      return;
    } else {
      updateRecruiter(updatedObj);
    }
  };

  return (
    <>
      <PageTitle
        back
        title="My profile"
        btnText="Edit profile"
        btnAddionalText="Next"
        btnActive={false}
        btnAddional={false}
      />

      {!loading && !data ? (
        <ErrorCard />
      ) : (
        <Row>
          <div
            style={{
              display: "flex",
              justifyContent: "end",
              alignItems: "center",
              width: "100%",
              marginTop: "-70px",
            }}
          >
            <div style={{ display: isEditing ? "none" : "block" }}>
              <Button
                type="primary"
                shape="round"
                size="middle"
                style={{
                  fontSize: "16px",
                  fontWeight: "600",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  width: "108px",
                  height: "40px",
                }}
                onClick={() => {
                  setIsEditing(true);
                  editContext.editProfile();
                }}
              >
                Edit{" "}
                <img
                  src={EDIT_ICON}
                  alt="EDIT_ICON"
                  style={{ marginLeft: "8px", width: "14px", height: "17px" }}
                />
              </Button>
            </div>
            <div style={{ display: !isEditing ? "none" : "flex", gap: "18px" }}>
              <Button
                style={{
                  color: "white",
                  backgroundColor: "#990000",
                  borderRadius: "10px",
                  padding: "6px 16px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  width: "110px",
                  height: "42px",
                }}
                onClick={() => {
                  setIsEditing(false);
                  editContext.setUpdatedData(data.getSingleRecruiter);
                  editContext.setData(data.getSingleRecruiter);
                  editContext.cancelEditProfile();
                }}
              >
                Cancel{" "}
                <img
                  src={CROSS_ICON}
                  alt="CROSS_ICON"
                  style={{ marginLeft: "8px", width: "14px", height: "14px" }}
                />
              </Button>
              <Button
                style={{
                  color: "white",
                  backgroundColor: "#53946f",
                  borderRadius: "10px",
                  padding: "6px 16px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  width: "110px",
                  height: "42px",
                }}
                loading={loading1}
                onClick={handleSave}
              >
                Save{" "}
                <img
                  src={TICK_ICON}
                  alt="TICK_ICON"
                  style={{ marginLeft: "8px", width: "16px", height: "14px" }}
                />
              </Button>
            </div>
          </div>
          <Col span={24}>
            <Fade>
              <Card
                style={{
                  paddingLeft: "38px",
                  paddingRight: "58px",
                  paddingTop: "20px",
                }}
              >
                <Skeleton
                  active
                  paragraph={{ rows: 20 }}
                  className="p-4"
                  loading={loading}
                >
                  {Recruiter !== null && (
                    <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                      <Col
                        md={8}
                        lg={8}
                        xl={8}
                        style={{
                          paddingLeft: "8px",
                          paddingRight: "8px",
                          marginLeft: "-8px",
                          marginRight: "14px",
                        }}
                      >
                        <ProfileCard
                          recruiter={Recruiter}
                          setDisplayGeneralDrawer={setDisplayGeneralDrawer}
                          isEditing={isEditing}
                          data={editContext?.updatedData}
                        />
                      </Col>
                      <Col
                        span={16}
                        style={{
                          paddingLeft: "8px",
                          paddingRight: "8px",
                          marginRight: "-7px",
                        }}
                      >
                        <SummaryCard
                          recruiter={Recruiter}
                          setSummary={setSummary}
                          summary={summary}
                          isEditing={isEditing}
                        />
                        <Card>
                          <div style={{ marginTop: 0 }}>
                            <Experience
                              nocomments={false}
                              isEditing={isEditing}
                              candidate={candidateData}
                              isRecruiterPage={true}
                            />
                          </div>
                        </Card>
                        <Card>
                          {/* Education */}
                          <div style={{ marginTop: 0 }}>
                            <Education
                              candidate={candidateData}
                              isRecruiterPage={true}
                              nocomments={false}
                            />
                          </div>
                        </Card>
                      </Col>
                    </Row>
                  )}
                </Skeleton>
              </Card>
            </Fade>
          </Col>
        </Row>
      )}

      <GeneralFormDrawer
        recruiter={Recruiter}
        data={editContext?.updatedData}
        setData={editContext.setUpdatedData}
        show={displayGeneralDrawer}
        loading={loading1}
        hide={() => setDisplayGeneralDrawer(false)}
      />
      <ActivityLogDrawer
        show={displayActivityDrawer}
        hide={() => setDisplayActivityDrawer(false)}
      />
      <ResetPasswordFormDrawer
        show={displayResetPasswordDrawer}
        hide={() => setDisplayResetPasswordDrawer(false)}
      />
    </>
  );
};

export default ViewRecruiter;
