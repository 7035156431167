import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AuthState } from "../../store/auth/types";
import { auth } from "../../store/auth/actions";
import { Link, Redirect, useLocation } from "react-router-dom";
import Logo from "../../components/UI/Logo/Logo";
import Footer from "../../components/Footer/Footer";
import { Fade } from "react-awesome-reveal";
import Signup from "assets/signup.svg";

import {
  Card,
  Row,
  Col,
  Form,
  Input,
  Button,
  Checkbox,
  notification,
  Affix,
} from "antd";
import { LockOutlined } from "@ant-design/icons";
import { useLoginMutation } from "../../graphql/generated/graphql";
import { EyeInvisibleOutlined, EyeTwoTone } from "@ant-design/icons";
import RegistrationSuccess from "./Registration/partials/RegistrationSuccess";
const Login = () => {
  const location: any = useLocation();
  //error state to control form
  const [hasError, setHasError] = useState(false);
  const [loginMutation, { loading }] = useLoginMutation();
  const [passwordVisible, setPasswordVisible] = React.useState(false);
  const remembered = JSON.parse(localStorage.getItem("remember") || "{}");
  const [isVerify, setIsVerify] = useState<any>();
  //check authentication
  const isAuthenticated = useSelector(
    (state: AuthState) => state.auth.accessToken !== null
  );

  const { authRedirectPath } = useSelector((state: AuthState) => state.auth);

  // http actions
  const dispatch = useDispatch();

  //on submit login form
  const onFinish = (values: any) => {
    if (values.remember) {
      const rememberMe = {
        email: values.emailAddress,
        password: values.password,
      };
      localStorage.setItem("remember", JSON.stringify(rememberMe));
    }

    loginMutation({
      variables: {
        email: values.emailAddress.toLowerCase(),
        password: values.password,
      },
    })
      .then(({ data }) => {
        const accessToken: string = data?.recruiterLogin?.token || "";
        const fullName: string = data?.recruiterLogin?.fullName || "";
        const userEmail: string = data?.recruiterLogin?.email || "";
        dispatch(auth(accessToken, fullName, userEmail));
        localStorage.setItem("user", JSON.stringify(data?.recruiterLogin));
      })
      .catch((err) => {
        console.log(err.graphQLErrors);
        const errors = err.graphQLErrors.map((el: any) => el);
        // const parseData = JSON.parse(errors);
        setIsVerify(errors[0] || {});
        notification["error"]({
          message: "Unable to Login",
          description: errors[0]?.message,
          // description: errors.map((er: any) => <div key={er}>{er}</div>),
          duration: 3,
          onClose: () => setHasError(false),
        });
      });
  };
  useEffect(() => {
    if (location?.state?.isVerified == true) {
      setIsVerify("");
    }
  }, [location]);
  // const onFinish = async (values: any) => {
  //   if (values.remember) {
  //     const rememberMe = {
  //       email: values.emailAddress,
  //       password: values.password,
  //     };
  //     localStorage.setItem("remember", JSON.stringify(rememberMe));
  //   }
  //   const token =
  //     "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6IjVlOWYzM2VkYTYyMmIzNDYyZmI2YjI5NSIsImlhdCI6MTU5Mjk5MzIwMH0.IEqZmbTZUHyI6ep4b5dV5DWYQq3WU9tN2TpgDCQpXOM";
  //   const fullName = "Waqas Mumtaz";
  //   dispatch(auth(token, fullName));
  // };

  return (
    <>
      {isAuthenticated && <Redirect to={authRedirectPath} />}
      {isVerify?.UID ? (
        <div className="bg-primary" style={{ minHeight: "100vh" }}>
          <div className="wrapper">
            <Row>
              <Col span={12}>
                <Affix offsetTop={40}>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      marginTop: "20%",
                      marginRight: "10%",
                    }}
                  >
                    <img src={Signup} alt="Registration" />
                  </div>
                </Affix>
              </Col>
              <Col span={12}>
                <Card
                  style={{
                    minHeight: "100vh",
                    marginBottom: "0px",
                    borderRadius: "0",
                  }}
                >
                  <div className="pb-4">
                    <div className="p-3">
                      {
                        <RegistrationSuccess
                          fullName={isVerify.fullName}
                          userId={isVerify?.UID}
                        />
                      }
                    </div>
                  </div>
                  <Footer footer={false} />
                </Card>
              </Col>
            </Row>
          </div>
        </div>
      ) : (
        <div className="bg-primary" style={{ minHeight: "100vh" }}>
          <div className="wrapper">
            <Row>
              <Col span={12}>
                <div className="text-center m-t-40">
                  <img
                    src="http://blonk.co/demo/SOBlonk/assets/images/login-image.png"
                    className="img-fluid m-t-10"
                    alt=""
                  />
                </div>
              </Col>
              <Col span={12}>
                <Card
                  style={{
                    minHeight: "100vh",
                    marginBottom: "0px",
                    borderRadius: "0",
                    padding: "50px 50px 0 50px",
                  }}
                >
                  <div className="pb-4">
                    <h3 className="text-center m-t-40">
                      <Logo height="40" />
                    </h3>
                    <div className="p-2">
                      {location?.state?.isVerified ? (
                        <>
                          <p
                            className="text-center pt-0 mtp0 fs-account"
                            style={{
                              color: "#008D8B",
                              fontSize: 14,
                              fontWeight: 400,
                            }}
                          >
                            Your account is verified. Please enter your email
                            and password to login.
                          </p>
                        </>
                      ) : (
                        <p className="text-muted text-center pt-0 mtp0 fs-account">
                          Don't have an account ?{" "}
                          <Link
                            to="register"
                            className="text-primary fs-register"
                          >
                            Register now
                          </Link>
                        </p>
                      )}
                      <Fade>
                        <Form
                          name="normal_login"
                          className="login-form"
                          layout="vertical"
                          onFieldsChange={() => setHasError(false)}
                          initialValues={{
                            remember: true,
                            emailAddress: remembered && remembered.email,
                            password: remembered && remembered.password,
                          }}
                          onFinish={onFinish}
                        >
                          <Form.Item
                            name="emailAddress"
                            label={
                              <span style={{ fontSize: "14px" }}>
                                Email address
                              </span>
                            }
                            rules={[
                              {
                                type: "email",
                                message:
                                  "The input is not a valid email address!",
                              },
                            ]}
                          >
                            <Input
                              prefix={<i className="far fa-envelope font-14" />}
                              placeholder="Enter your email address"
                            />
                          </Form.Item>
                          <Form.Item
                            name="password"
                            label={
                              <span style={{ fontSize: "14px" }}>Password</span>
                            }
                            rules={[
                              {
                                required: true,
                                message: "Please enter your password!",
                              },
                              {
                                whitespace: true,
                                message: "No whitespaces allowed",
                              },
                            ]}
                          >
                            <Input.Password
                              prefix={
                                <LockOutlined className="site-form-item-icon" />
                              }
                              type="password"
                              placeholder="Enter your password"
                              iconRender={(visible) =>
                                visible ? (
                                  <EyeTwoTone />
                                ) : (
                                  <EyeInvisibleOutlined />
                                )
                              }
                            />
                          </Form.Item>
                          <Form.Item>
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                              }}
                            >
                              <Form.Item
                                name="remember"
                                valuePropName="checked"
                                noStyle
                              >
                                <Checkbox
                                  style={{
                                    fontSize: "14px",
                                    fontWeight: 500,
                                    color: "#012653",
                                    marginTop: 5,
                                  }}
                                >
                                  Remember me
                                </Checkbox>
                              </Form.Item>
                              <Form.Item>
                                <Button
                                  htmlType="submit"
                                  className="ant-btn-amber"
                                  shape="round"
                                  disabled={hasError}
                                  loading={loading}
                                  style={{
                                    padding: "0 37px",
                                    height: "34px",
                                    fontSize: "14px",
                                  }}
                                >
                                  Log in
                                </Button>
                              </Form.Item>
                            </div>
                            <div
                              style={{
                                textAlign: "end",
                                textDecoration: "underline",
                              }}
                            >
                              <Link
                                to="recover-password"
                                className="text-muted"
                              >
                                {/* className="mdi mdi-lock" */}
                                <span
                                  style={{
                                    color: "#012653",
                                    fontSize: "14px",
                                    fontWeight: "500",
                                  }}
                                >
                                  Forgot your password?
                                </span>
                              </Link>
                            </div>
                          </Form.Item>
                        </Form>
                      </Fade>
                    </div>
                  </div>
                  <Footer footer={false} />
                </Card>
              </Col>
            </Row>
          </div>
        </div>
      )}
    </>
  );
};

export default Login;
