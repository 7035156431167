import React from "react";
import "./PageTitle.scss";
import { Row, Col, Button, Typography } from "antd";
import { Link } from "react-router-dom";
import { useHistory, useLocation } from "react-router-dom";
import { ArrowLeftOutlined } from "@ant-design/icons";
import BackIcon from "../../../assets/icons/icons/backIcon.svg";

const { Title } = Typography;

interface Props {
  back?: boolean;
  backHandler?: any;
  title: string;
  btnText?: string;
  to?: string;
  btnActive?: boolean;
  btnAddional?: boolean;
  belowTitle?: boolean;
  btnTextDisabled?: boolean;
  btnAddionalTextDisabled?: boolean;
  btnAddionalText?: string;
  btnAddionalTextHandler?: any;
  tabActiveKey?: string;
  btnRight?: boolean;
  btnRightHandler?: any;
  fromProfile?: boolean;
  backURL?: any;
}
const PageTitle: React.FC<Props> = (props) => {
  let history = useHistory();
  let location = useLocation();
  if (location?.pathname?.includes("undefined")) {
    history.push("/companies");
  }

  const goBack = () => {
    history.goBack();
  };
  return (
    <div className={props.fromProfile ? "page-header-box" : "page-title-box"}>
      <Row justify="center" gutter={10}>
        <Col span={props.fromProfile ? 24 : 4}>
          {props.back &&
            (!props.to ? (
              <Button
                className="ant-btn-teal"
                shape="round"
                size="middle"
                icon={
                  <img
                    src={BackIcon}
                    style={{ marginRight: "6px", marginLeft: "-8px" }}
                  />
                }
                onClick={props.backHandler ? props.backHandler : goBack}
                style={{
                  fontSize: "14px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                Back
              </Button>
            ) : (
              <Link to={props.backURL || props.to}>
                <Button
                  className="ant-btn-teal"
                  shape="round"
                  size="middle"
                  icon={
                    <img
                      src={BackIcon}
                      style={{ marginRight: "6px", marginLeft: "-8px" }}
                    />
                  }
                  style={{
                    fontSize: "14px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  Back
                </Button>
              </Link>
            ))}
        </Col>
        <Col span={16}>
          <div className="text-center">
            {!props?.belowTitle && (
              <Title level={4} className="text-primary">
                {props.title}
              </Title>
            )}
            {props.children && (
              <h4 className="font-14 text-info">{props.children}</h4>
            )}
          </div>
        </Col>
        <Col span={4}>
          <div className="title-btn-alignment">
            <div className="text-right">
              {props.btnActive && (
                <>
                  {!props.btnAddionalTextHandler && (
                    <Link to={props.to || ""}>
                      <Button
                        className="ant-btn-teal"
                        shape="round"
                        size="middle"
                        style={{
                          fontSize: "14px",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        {props.btnText}
                      </Button>
                    </Link>
                  )}
                  {props.btnAddionalTextHandler && (
                    <Button
                      className="ant-btn-teal"
                      shape="round"
                      size="middle"
                      style={{ fontSize: "14px" }}
                      onClick={() => props.btnAddionalTextHandler("prev")}
                      disabled={props.btnTextDisabled}
                    >
                      {props.btnText}
                    </Button>
                  )}
                </>
              )}
            </div>
            <div className="text-right" style={{ marginLeft: 10 }}>
              {props.btnRight && (
                <>
                  <props.btnRightHandler />
                </>
              )}
              {props.btnAddional && (
                <>
                  {!props.btnAddionalTextHandler && (
                    <Link to={props.to || ""}>
                      <Button
                        className="ant-btn-teal"
                        shape="round"
                        size="middle"
                      >
                        {props.btnAddionalText}
                      </Button>
                    </Link>
                  )}
                  {props.btnAddionalTextHandler && (
                    <Button
                      className="ant-btn-teal"
                      shape="round"
                      size="middle"
                      onClick={() => props.btnAddionalTextHandler("next")}
                      disabled={props.btnAddionalTextDisabled}
                    >
                      {props.btnAddionalText}
                    </Button>
                  )}
                </>
              )}
            </div>
          </div>
        </Col>
      </Row>
      {props.belowTitle && (
        <Row justify="center" gutter={16}>
          <div className="row-title">
            <Title level={4} className="text-primary">
              {props.title}
            </Title>
          </div>
        </Row>
      )}
    </div>
  );
};

export default PageTitle;
