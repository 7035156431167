import React, { useEffect, useState, useRef } from "react";
import { Redirect, useHistory, useLocation, useParams } from "react-router-dom";
import { Row, Col, Card, notification, Spin } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { StoreJob, StoreJobs } from "store/jobs/actions";
import { JobsState } from "store/jobs/types";
import JobForm from "./partials/JobForm";
import PageTitle from "../../../components/UI/PageTitle/PageTitle";
import {
  useAddJobMutation,
  useGetSingleJobLazyQuery,
  useCompanyJobsLazyQuery,
  useCreateCountryMutation,
} from "../../../graphql/generated/graphql";
import useCompanies from "../../../hooks/useCompanies";
import useCurrencies from "../../../hooks/useCurrencies";
import useContractTypes from "../../../hooks/useContractTypes";
import useCountries from "../../../hooks/useCountries";
import useSkills from "../../../hooks/useSkills";
import usejobFields from "../../../hooks/usejobFields";
import useInspiringCompanies from "../../../hooks/useInspiringCompanies";
import _ from "lodash";
import JobDescription from "../ViewJob/partials/JobDescription";
// import { Switch, Space, Typography } from "antd";
// import Icon from "@ant-design/icons";

// import "./PostJob.scss";

// const { Title } = Typography;

interface AssessmentItem {
  title: string;
  description: string;
}

interface Assessments {
  experience: AssessmentItem[] | any;
  traits: AssessmentItem[] | any;
}

const PostJob: React.FC = () => {
  let history = useHistory();
  const dispatch = useDispatch();
  const location: any = useLocation();
  const params: any = useParams();
  // console.log("PARAM: ", params);

  const [getSingleJob, { called, loading: loading1, data }] =
    useGetSingleJobLazyQuery();

  // const [getCompanyJobs, { data: companyJobs, loading: loading2, error }] =
  //   useCompanyJobsLazyQuery();

  const [loader, setLoader] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [hideSalary, setHideSalary] = useState(false);
  const [isAnonymous, setisAnonymous] = useState(false);
  const [salaryRange, setSalaryRange] = useState(30000);
  const [recruiters, setRecruiters]: any = useState([]);
  const [job, setJob]: any = useState(null);
  const [jobDescription, setJobDescription] = useState("");
  const [compensationComments, setCompensationsComments] = useState("");
  const [AnonymousCompanyDescription, setAnonymousCompanyDescription] =
    useState("");
  const [imageData, setImageData] = useState(null);
  const [uploadMobileBanner, setUploadMobileBanner] = useState(null);
  const [uploadBanner, setUploadBanner] = useState(null);

  const jobs = useSelector((state: JobsState) => state.jobs?.jobs);
  const [jobData, setJobData] = useState(Object);
  const [addJobMutation, { loading }] = useAddJobMutation();
  const [draftLoading, setDraftLoading] = useState(false);
  const [saveLoading, setSaveLoading] = useState(false);
  const [createCountryMutation, { loading: loadingCountries }] =
    useCreateCountryMutation();
  const companies = useCompanies();
  const currencies = useCurrencies();
  const contractTypes = useContractTypes();
  const countries = useCountries();
  const skills = useSkills();
  const jobFields = usejobFields();
  const inspiringCompanies = useInspiringCompanies();
  const editMode = location.pathname.includes("edit");

  // const [isConfidentialJob, setConfidentialJob] = useState<any>(false);
  // const [companyValue, setCompany] = useState<string | null | undefined>(null);

  const initialAssessments: Assessments = {
    experience: [],
    traits: [],
  };

  const [assessments, setAssessments] =
    useState<Assessments>(initialAssessments);

  const addAssessment = (
    item: "experience" | "traits",
    data: AssessmentItem[]
  ) => {
    setAssessments((prevAssessments) => {
      if (item === "experience" || item === "traits") {
        return {
          ...prevAssessments,
          [item]: data.map((itemData) => {
            return {
              title: itemData.title || "",
              description: itemData.description || "",
            };
          }),
        };
      } else {
        console.error("Invalid assessment type");
        return prevAssessments; // Return the unchanged state
      }
    });
  };

  useEffect(() => {
    if (Object.keys(jobData).length !== 0) {
      const filtereData = removeEmptyStringProperties(jobData);
      // console.log("DATA BEFORE MUTATION OF PPRE EXISTING JOB :", filtereData);//////////////////////////////
      addJobMutation({
        variables: {
          ...filtereData,
          jobId: params.jobId,
        },
      })
        .then(({ data }) => {
          // console.log(data,"----- job Data")
          setLoader(false);
          setHasError(false);
          setDraftLoading(false);
          if (data?.createJob == null) {
            notification["error"]({
              message: "Unable to create job",
              description: (
                <div>{"Something went wrong. Please try again"}</div>
              ),
              duration: 3,
              onClose: () => setHasError(false),
            });
            return;
          }

          if (editMode) {
            dispatch(StoreJob({ ...data?.createJob }));
          } else dispatch(StoreJobs([...jobs, { ...data?.createJob }]));
          const action = editMode ? "edited" : "created";

          if (editMode) {
            notification["success"]({
              message: "Job updated successfully.",
              onClose: () =>
                history.push(`/job-listings/view/${data?.createJob?._id}`),
              description: ``,
            });
          } else {
            notification["success"]({
              message: "Job created successfully.",
              onClose: () =>
                history.push(`/job-listings/view/${data?.createJob?._id}`),
              description: ``,
            });
          }
        })
        .catch((err) => {
          setLoader(false);
          setHasError(true);
          setDraftLoading(false);
          const errors = err.graphQLErrors.map((el: any) => el.message);
          notification["error"]({
            message: "Unable to create job",
            description: errors.map((er: any) => <div key={er}>{er}</div>),
            duration: 3,
            onClose: () => setHasError(false),
          });
        });
    }
  }, [jobData]);

  // useEffect(() => {
  //   if (companies && companyValue) {
  //     if (isConfidentialJob) {
  //       const companyObj = companies.find(
  //         (company: any) => company.name == companyValue
  //       );
  //       if (!companyObj.confidentialdescription) {
  //         notification["error"]({
  //           message: "Confidential company description is missing",
  //           description:
  //             "Please click to update the company's confidential description",
  //           onClick: () => history.push(`/companies/edit/${companyObj._id}`),
  //           duration: 3,
  //         });
  //       }
  //     }

  //     if (!isConfidentialJob) {
  //       const companyObj = companies.find(
  //         (company: any) => company.name == companyValue
  //       );
  //       if (!companyObj.description) {
  //         notification["error"]({
  //           message: "Standard company description is missing",
  //           description:
  //             "Please click to update the company's standard description",
  //           onClick: () => history.push(`/companies/edit/${companyObj._id}`),
  //           duration: 3,
  //         });
  //       }
  //     }
  //   }
  // }, [isConfidentialJob, companyValue]);

  // useEffect(() => {
  //   if (companyJobs?.getJobs) {
  //     const jobObj = companyJobs?.getJobs.find(
  //       (companyJob: any) =>
  //         companyJob.title == job.title &&
  //         companyJob.isConfidential == isConfidentialJob
  //     );
  //     console.log(jobObj);
  //     if (!jobObj) {
  //       history.push(`/job-listings/add-job`);
  //     } else {
  //       history.push(`/job-listings/edit/${jobObj._id}`);
  //     }
  //   }
  // }, [isConfidentialJob, companyJobs]);

  useEffect(() => {
    if (params.jobId) {
      getSingleJob({
        variables: {
          jobId: params.jobId,
        },
      });
    }
  }, [params, jobs]);

  useEffect(() => {
    if (params.jobId) {
      // if (data?.getJobDetails) {
      //   setConfidentialJob(data?.getJobDetails?.isConfidential);
      //   setCompany(data?.getJobDetails?.company?.name);
      //   const companyId: any = data?.getJobDetails?.company?._id;
      //   getCompanyJobs({
      //     variables: {
      //       companyId: companyId,
      //     },
      //   });
      // }
      setJob(data?.getJobDetails);
      // console.log(data?.getJobDetails?.assessment.experience[0],'assessments are here ')
      // setAssessments(data?.getJobDetails?.assessment);
      setRecruiters(data?.getJobDetails?.company?.users);

      let experience = [];
      data?.getJobDetails?.assessment?.experience?.forEach(
        (ex) =>
          (experience = [
            ...experience,
            { title: ex.title, description: ex.description },
          ])
      );

      let traits = [];
      data?.getJobDetails?.assessment?.traits?.forEach(
        (ex) =>
          (traits = [
            ...traits,
            { title: ex.title, description: ex.description },
          ])
      );

      setAssessments({ experience, traits });
    }
  }, [data]);

  function removeEmptyStringProperties(obj) {
    for (let key in obj) {
      if (
        obj.hasOwnProperty(key) &&
        obj[key]?.length === 0 &&
        key !== "description"
      ) {
        delete obj[key];
      }
    }
    return obj;
  }

  const onFinishHandler = (values: any, isSaveDraft = false) => {
    // console.log("VALUES IN ONFINISH : " , values);
    if (isSaveDraft) {
      setDraftLoading(true);
    } else {
      setLoader(true);
      setHasError(true);
    }
    let newCountries: any = [];
    let addNewCountries: any = [];
    const updatedCountries = values?.countries
      ? values?.countries
          .map((c: any) => {
            let countryInfo = countries.find(
              (country: any) => country.name == c
            );
            if (countryInfo == null) {
              newCountries.push(c);
            }
            return countryInfo;
          })
          .filter((d: any) => d && d._id)
          .map((d: any) => d._id)
      : [];

    const updatedrequiredSkills = values?.requiredSkills
      ? values?.requiredSkills
          .map((c: any) => {
            return skills.find((skill: any) => skill.title == c);
          })
          .filter((d: any) => d && d._id)
          .map((d: any) => d._id)
      : [];

    const filterCustomSkills = values?.filteredCustomSKills
      ? values?.filteredCustomSKills
          .map((c: any) => {
            return skills.find((skill: any) => skill.title == c);
          })
          .filter((d: any) => d && d._id)
          .map((d: any) => d.title)
      : [];

    const updatedcustomSkills = values?.requiredSkills
      ? values?.requiredSkills.filter((c: any) => {
          return filterCustomSkills.indexOf(c) == -1;
        })
      : [];

    const updatedrecruiters = values?.recruiters
      ? values?.recruiters
          .map((c: any) => {
            return recruiters.find((recruiter: any) => recruiter.email == c);
          })
          .filter((d: any) => d && d._id)
          .map((d: any) => d && d._id)
      : [];

    const updatedfields = values?.fields
      ? values?.fields
          .map((c: any) => {
            return jobFields.find((jobField: any) => jobField.title == c);
          })
          .filter((d: any) => d && d._id)
          .map((d: any) => d._id)
      : [];

    const updatedTargetCompanies = values?.targetCompanies
      ? values?.targetCompanies
          .map((c: any) => {
            return inspiringCompanies.find(
              (inspiringCompany: any) => inspiringCompany.name == c
            );
          })
          ?.map((d: any) => d._id)
      : [];

    let jobData: any = null;
    jobData = {
      title: values.title,
      isDraft: isSaveDraft,
      banner: uploadBanner ? uploadBanner.url : job?.banner || "",
      mobileBanner: uploadMobileBanner ? uploadMobileBanner : "",
      description: jobDescription ? jobDescription : values?.description || "",
      isAnonymous: isAnonymous,
      AnonymousCompanyTitle: values?.AnonymousCompanyTitle || "",
      AnonymousCompanyDescription: AnonymousCompanyDescription
        ? AnonymousCompanyDescription
        : values?.AnonymousCompanyDescription || "",
      hideSalary: hideSalary,
      countries: [updatedCountries],
      // salaryPackage: salaryRange.toString(),
      salaryPackage: "5000",
      requiredSkills: updatedrequiredSkills,
      customSkills: updatedcustomSkills,
      experience: values?.experiance,
      currencyId: currencies?.find((c: any) => c?.name == values?.currencyId)
        ._id,
      recruiters: updatedrecruiters,
      leadRecruiter:
        recruiters?.length > 0
          ? recruiters.find(
              (recruiter: any) =>
                recruiter?.email == values.leadRecruiter ||
                recruiter?._id == values.leadRecruiter
            )?._id
          : "",
      fields: updatedfields,
      // contractType: contractTypes.find(
      //   (contractType: any) => contractType.name == values?.contractType
      // )._id,
      contractType: "5fc683350930f4b350b18052",
      companyId:
        values?.companyId && companies.length > 0
          ? companies.find((company: any) => company?.name == values?.companyId)
              ?._id
          : "",
      yearlyCompensation: {
        currency: currencies?.find((c: any) => c?.name == values?.currencyId)
          .name,
        total: values?.total || "",
        base: values?.base || "",
        onTargetEarnings: values?.onTargetEarnings || "",
        shortTermIncentives: values?.shortTermIncentives || "",
        longTermIncentives: values?.longTermIncentives || "",
        other: values?.other || "",
        comments: compensationComments
          ? compensationComments
          : values?.comments || "",
      },
      assessment: assessments,
      // assessment : {},
      targetCompanies: updatedTargetCompanies,
      language: values.language,
      // isConfidential: isConfidentialJob,
    };
    if (newCountries.length > 0) {
      createCountryMutation({
        variables: {
          name: newCountries,
        },
      }).then(({ data }) => {
        // console.log(data);
        if (data?.createCountry && data?.createCountry.length > 0) {
          data?.createCountry.map((newCountry: any) => {
            // console.log(newCountry._id);
            addNewCountries.push(newCountry._id);
          });
          addNewCountries = addNewCountries.concat(updatedCountries);
          jobData["countries"] = addNewCountries;
          setJobData(jobData);
        }
      });
    } else {
      jobData["countries"] = updatedCountries;

      setJobData(jobData);
      // setJobData({ ...jobData });
    }
  };
  const setHideSalaryHanler = (e: any) => {
    setHideSalary(e.target.checked);
  };
  const setisAnonymousHanler = (e: any) => {
    setisAnonymous(e.target.checked);
  };

  // function onChange(checked: any) {
  //   setConfidentialJob(!isConfidentialJob);
  // }

  // const ConfidentialJobSvg = () => (
  //   <svg
  //     width="18"
  //     height="20"
  //     viewBox="0 0 18 20"
  //     fill="none"
  //     xmlns="http://www.w3.org/2000/svg"
  //   >
  //     <path
  //       fill-rule="evenodd"
  //       clip-rule="evenodd"
  //       d="M9.00001 0C8.13751 0 6.69626 0.33125 5.34001 0.7C3.95251 1.075 2.55376 1.51875 1.73126 1.7875C1.38737 1.90106 1.0825 2.10933 0.851653 2.38838C0.620811 2.66742 0.473366 3.00592 0.426257 3.365C-0.318743 8.96125 1.41001 13.1088 3.50751 15.8525C4.39698 17.0262 5.45754 18.0598 6.65376 18.9188C7.13626 19.26 7.58376 19.5212 7.96376 19.7C8.31376 19.865 8.69001 20 9.00001 20C9.31001 20 9.68501 19.865 10.0363 19.7C10.4944 19.4773 10.9326 19.216 11.3463 18.9188C12.5425 18.0598 13.6031 17.0262 14.4925 15.8525C16.59 13.1088 18.3188 8.96125 17.5738 3.365C17.5267 3.00575 17.3793 2.66705 17.1485 2.38779C16.9177 2.10853 16.6127 1.90004 16.2688 1.78625C15.0748 1.39477 13.8715 1.03216 12.66 0.69875C11.3038 0.3325 9.86251 0 9.00001 0ZM9.00001 6.25C9.44281 6.24934 9.87155 6.40541 10.2103 6.69058C10.5491 6.97574 10.776 7.37159 10.8508 7.80801C10.9257 8.24444 10.8437 8.69328 10.6193 9.07503C10.3949 9.45678 10.0427 9.74681 9.62501 9.89375L10.1063 12.3812C10.1238 12.4717 10.1211 12.565 10.0983 12.6543C10.0756 12.7436 10.0334 12.8267 9.9747 12.8978C9.91605 12.9689 9.8424 13.0261 9.75904 13.0654C9.67568 13.1047 9.58466 13.125 9.49251 13.125H8.50751C8.41546 13.1248 8.32458 13.1043 8.24138 13.065C8.15817 13.0256 8.08468 12.9684 8.02617 12.8973C7.96765 12.8263 7.92555 12.7432 7.90288 12.654C7.88021 12.5647 7.87752 12.4716 7.89501 12.3812L8.37501 9.89375C7.9573 9.74681 7.60507 9.45678 7.38071 9.07503C7.15636 8.69328 7.07434 8.24444 7.1492 7.80801C7.22406 7.37159 7.45096 6.97574 7.78971 6.69058C8.12846 6.40541 8.55721 6.24934 9.00001 6.25Z"
  //       fill="#1A838C"
  //     />
  //   </svg>
  // );

  // const ConfidentialJobIcon = (props: any) => (
  //   <Icon component={ConfidentialJobSvg} {...props} />
  // );

  // const confidentialJobSwitch = () => {
  //   return (
  //     <>
  //       <Space>
  //         <Icon component={ConfidentialJobIcon} />
  //         <Title level={5} className="text-primary">
  //           Confidential job
  //         </Title>
  //         <Switch
  //           className="toggle-btn"
  //           size="default"
  //           checkedChildren="On"
  //           unCheckedChildren="Off"
  //           checked={isConfidentialJob}
  //           onChange={onChange}
  //         />
  //       </Space>
  //     </>
  //   );
  // };

  return (
    <>
      {companies && companies.length == 0 ? (
        <Redirect to="/companies/new" />
      ) : (
        <>
          <PageTitle
            title={
              location.pathname.includes("edit") ||
              location?.state?.operation == "edit"
                ? "Edit job"
                : "Job details"
            }
            back
            btnRight={false}
            // btnRightHandler={confidentialJobSwitch}
          />
          <Row>
            <Col span={24}>
              <Card className="mb-4">
                <JobForm
                  job={job}
                  imageData={(data: any) => setImageData(data)}
                  editMode={editMode}
                  companies={companies}
                  currencies={currencies}
                  contractTypes={contractTypes}
                  countries={countries}
                  skills={skills}
                  jobFields={jobFields}
                  submitting={loader}
                  inspiringCompanies={inspiringCompanies}
                  hideSalary={hideSalary}
                  isAnonymous={isAnonymous}
                  recruiters={recruiters}
                  isLoading={loading}
                  hasError={hasError}
                  draftLoader={draftLoading}
                  setDraftLoader={setDraftLoading}
                  setAnonymousCompanyDescription={
                    setAnonymousCompanyDescription
                  }
                  // setCompany={setCompany}
                  setJobDescription={setJobDescription}
                  setComments={setCompensationsComments}
                  setHideSalary={setHideSalaryHanler}
                  setisAnonymous={setisAnonymousHanler}
                  onFinish={onFinishHandler}
                  salaryRange={salaryRange}
                  setSalaryRange={setSalaryRange}
                  setRecruiters={setRecruiters}
                  setUploadMobileBanner={setUploadMobileBanner}
                  uploadMobileBanner={uploadMobileBanner}
                  setUploadBanner={setUploadBanner}
                  uploadBanner={uploadBanner}
                  addAssessment={addAssessment}
                  handleSaveJobAsDraft={onFinishHandler}
                />
              </Card>
            </Col>
          </Row>
        </>
      )}
    </>
  );
};

export default PostJob;
