import { PlusCircleOutlined } from "@ant-design/icons";
import { Col, DatePicker, Form, Input, Row, Select, Switch } from "antd";
import moment from "moment";
import React, { useContext, useEffect, useState } from "react";
import HandleLogo from "../../../../../Auth/Profile/partials/HandleLogo";
import useGoogle from "react-google-autocomplete/lib/usePlacesAutocompleteService";
import EXP_PLACEHOLDER from "assets/icons/DetailedProfile/experiencePlaceholder.svg";
import DELETE_ICON from "assets/icons/DetailedProfile/delete.svg";
import { Option } from "antd/lib/mentions";
import { EditContext } from "../../../Context/EditContext";
import _ from "lodash";

interface EducationFormProps {
  education: any;
  candidateId?: any;
  isNestedEdu?: any;
}

const EducationForm = ({
  education,
  candidateId,
  isNestedEdu,
}: EducationFormProps) => {
  const [values, setValues] = useState<any>({});
  const [form] = Form.useForm();
  const [current, setCurrent] = useState(false);
  const [uploadPicture, setUploadPicture] = useState("");
  const editContext = useContext<any>(EditContext);
  const { setData, setUpdatedData } = editContext;
  const [location, setLocation] = useState(education?.location);

  const { placePredictions, getPlacePredictions, isPlacePredictionsLoading } =
    useGoogle({
      apiKey: "AIzaSyACXA9qapZNvq51WpF69eIH908sX_efNMs",
      options: {
        types: ["geocode"],
      },
    });
  useEffect(() => {
    education?.current !== "" &&
      education?.current !== null &&
      education?.current !== undefined &&
      setCurrent(education?.current);
  }, [education?.current]);
  useEffect(() => {
    const educationData = {
      ...education,
      startYear: education?.startYear && moment(education.startYear),
      endYear: education?.endYear && moment(education.endYear),
      current: !!education?.current,
    };
    setValues(educationData);
    form.setFieldsValue(educationData);
  }, [education.index, education]);

  useEffect(() => {
    setLocation(education?.location);
  }, [education]);
  useEffect(() => {
    setUploadPicture(education?.logo);
  }, [education?.logo]);

  if (current) {
    form.setFieldsValue({ endYear: "" }); // Set endYear to an empty string
  }
  const updateSchool = (oldSchoolName: any, schoolName: any, edu: any) => {
    edu?.map((e: any) => {
      if (e?.schoolName == oldSchoolName) {
        e.schoolName = schoolName;
      }
      return e;
    });

    setUpdatedData({
      ...editContext?.updatedData,
      educations: edu,
    });
    setData({
      ...editContext?.candidate,
      educations: edu,
    });
  };

  const handleSave = (v: any) => {
    const newVal = { ...values, ...v };
    const edu = _.cloneDeep(editContext?.candidate.educations);
    const newEdu = {
      _id: education?._id,
      degree: newVal?.degree,
      schoolName: newVal?.schoolName,
      startYear: newVal?.startYear?._d
        ? new Date(newVal?.startYear?._d)?.getFullYear()?.toString()
        : null,
      endYear: newVal?.endYear?._d
        ? new Date(newVal?.endYear?._d)?.getFullYear()?.toString()
        : null,
      current: newVal?.current,
      location: newVal?.location,
      logo: newVal?.logo,
    };
    // if (education?.schoolName != newEdu?.schoolName) {
    //   updateSchool(education?.schoolName, newEdu?.schoolName, edu);
    // }
    const updatedData = edu?.map((e: any) => {
      if (
        isNestedEdu &&
        e?.schoolName === education?.schoolName &&
        e?._id === education?._id
      ) {
        return newEdu;
      } else if (!isNestedEdu && e?._id === education?._id) {
        return newEdu;
      } else {
        return e;
      }
    });

    setUpdatedData({
      ...editContext?.updatedData,
      educations: updatedData,
    });
    setData({
      ...editContext?.candidate,
      educations: updatedData,
    });
  };

  const handleDelete = () => {
    let edu = editContext?.candidate.educations || [];
    edu = edu?.filter((e: any) => {
      if (
        e?.degree == education?.degree &&
        e?.schoolName == education?.schoolName &&
        e?._id == education?._id
      ) {
        // null;
      } else {
        return e;
      }
    });
    setUpdatedData({
      ...editContext?.updatedData,
      educations: edu,
    });
    setData({
      ...editContext?.candidate,
      educations: edu,
    });
  };

  const addNew = () => {
    let edu = editContext?.candidate.educations || [];
    edu = [
      ...edu,
      { schoolName: education?.schoolName, _id: Math.random().toString() },
    ];
    setUpdatedData({
      ...editContext?.updatedData,
      educations: edu,
    });
    setData({
      ...editContext?.candidate,
      educations: edu,
    });
  };

  useEffect(() => {
    editContext.setValidationForms((s: any) => [...s, form]);
  }, [form]);
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "space-between",
        width: "100%",
        gap: "20px",
      }}
    >
      <div
        style={{ width: "100%", gap: "12px" }}
        className="experience-container"
      >
        {!isNestedEdu && (
          <div>
            <HandleLogo
              candidateId={candidateId}
              uploadPicture={
                uploadPicture?.length ? uploadPicture : EXP_PLACEHOLDER
              }
              handleSave={handleSave}
              setUploadPicture={setUploadPicture}
              form={form}
            />
          </div>
        )}
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            width: "100%",
            gap: "10px",
            maxWidth: "400px",
          }}
        >
          <Form
            form={form}
            layout="vertical"
            name="recruiter-experience"
            onValuesChange={(v) => {
              setValues({ ...values, ...v });
              handleSave(v);
            }}
            style={{ width: "100%" }}
          >
            <Row
              justify="center"
              className="pb-0"
              gutter={16}
              style={{ marginRight: "-18px", marginLeft: "-6px" }}
            >
              <Col span={12}>
                <Form.Item
                  style={{ marginBottom: "6px" }}
                  label={
                    <span
                      style={{
                        fontSize: "14px",
                        fontWeight: "400",
                        color: "#495057",
                      }}
                    >
                      Start
                    </span>
                  }
                  name="startYear"
                  rules={[
                    {
                      required: true,
                      validator: (_, value) => {
                        if (!value) {
                          return Promise.reject("Start date is required!");
                        }

                        if (moment(value).isAfter(moment())) {
                          return Promise.reject(
                            "Start date cannot be a future date!"
                          );
                        }
                        return Promise.resolve();
                      },
                    },
                  ]}
                >
                  <DatePicker
                    size="small"
                    picker="year"
                    placeholder="Select date"
                    style={{ height: "26px" }}
                  />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  style={{ marginBottom: "6px" }}
                  label={
                    <span
                      style={{
                        fontSize: "14px",
                        fontWeight: "400",
                        color: "#495057",
                      }}
                    >
                      End
                    </span>
                  }
                  name="endYear"
                  dependencies={["current", "startYear"]}
                  rules={[
                    {
                      required: current == false,
                      message: "End date is required",
                    },
                    ({ getFieldValue }) => ({
                      validator(_, value) {
                        const startDate = getFieldValue("startYear");
                        if (
                          value &&
                          startDate &&
                          moment(value).isBefore(moment(startDate))
                        ) {
                          return Promise.reject(
                            new Error("End date cannot be before start date!")
                          );
                        }
                        return Promise.resolve();
                      },
                    }),
                  ]}
                >
                  <DatePicker
                    size="small"
                    picker="year"
                    placeholder="Select date"
                    style={{ height: "26px" }}
                    disabled={values.current ? true : false}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row justify="space-between" className="pb-0" gutter={16}>
              <Col span={16}>
                <Form.Item
                  style={{ marginBottom: "0px", marginLeft: "4px" }}
                  label={
                    <span
                      style={{
                        fontSize: "14px",
                        fontWeight: "400",
                        color: "#495057",
                      }}
                    >
                      Currently studying there
                    </span>
                  }
                />
              </Col>
              <Col span={5}>
                <Form.Item style={{ marginBottom: "0px" }} name="current">
                  <Switch
                    size="small"
                    style={{ marginLeft: "22px" }}
                    onChange={(e) => setCurrent(e)}
                    checked={!!values?.current}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Form.Item
              style={{
                marginBottom: "6px",
                display: isNestedEdu ? "none" : "block",
                marginTop: "-22px",
              }}
              name="schoolName"
              required
              rules={[
                {
                  required: true,
                  message: "Institude name is required",
                },
              ]}
            >
              <Input
                size="small"
                style={{ height: "28px" }}
                placeholder="Enter your institude name"
              />
            </Form.Item>
            <Form.Item
              style={{
                marginBottom: "6px",
                marginTop: isNestedEdu ? "-22px" : "0px",
              }}
              name="degree"
              required
              rules={[
                {
                  required: true,
                  message: "Degree name is required",
                },
              ]}
            >
              <Input
                size="small"
                placeholder="Enter your degree name"
                style={{ height: "28px" }}
              />
            </Form.Item>
            <Form.Item
              name="locations"
              style={{
                // marginTop: '-18px',
                color: "black",
                height: "28px",
                maxHeight: "28px",
                minHeight: "28px",
              }}
              rules={[
                {
                  required: true,
                  message: "Please select a location!",
                },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    const loc = getFieldValue("locations");
                    if (loc?.length < 2) {
                      return Promise.reject(
                        new Error("Location field is required")
                      );
                    }
                    return Promise.resolve();
                  },
                }),
              ]}
              className="myprofile-location myprofile-location-input min-h-28px"
              initialValue={
                education?.location?.city || education?.location?.country
                  ? education?.location?.city +
                    " " +
                    education?.location?.country
                  : undefined
              }
            >
              <Select
                size="small"
                style={{ height: "28px", maxHeight: "28px", minHeight: "28px" }}
                showSearch
                placeholder={
                  <span style={{ fontSize: "14px" }}>
                    Indicate your location
                  </span>
                }
                onSearch={(value: string) => {
                  getPlacePredictions({ input: value });
                }}
                onChange={(value) => {
                  const city = value?.split(", ")[0];
                  const country = value?.split(", ")[1];
                  const updatedLocation = { country, city };
                  form.setFieldValue("location", updatedLocation);
                  setLocation(updatedLocation);
                  handleSave({ location: updatedLocation });
                }}
                loading={isPlacePredictionsLoading}
                filterOption={(input, option: any) =>
                  option.children
                    ?.toLowerCase()
                    .indexOf(input?.toLowerCase()) >= 0
                }
              >
                {placePredictions &&
                  placePredictions.map((company: any) => {
                    return (
                      <Option
                        key={company.description}
                        value={company.description}
                      >
                        {company.description}
                      </Option>
                    );
                  })}
              </Select>
            </Form.Item>
          </Form>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            marginLeft: "auto",
          }}
        >
          {!isNestedEdu && (
            <span onClick={addNew} style={{ cursor: "pointer" }}>
              <PlusCircleOutlined
                style={{ fontSize: "18px", marginLeft: "1px" }}
              />
            </span>
          )}
          <span onClick={handleDelete} style={{ cursor: "pointer" }}>
            <img
              src={DELETE_ICON}
              alt="DELETE_ICON"
              style={{ height: "20px", width: "20px" }}
            />
          </span>
        </div>
      </div>
    </div>
  );
};

export default EducationForm;
