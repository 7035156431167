import React from "react";
import EducationForm from "./EducationForm";

interface SameEducationEditFormProps {
  education: any;
  index: any;
}
const SameEducationEditForm = ({
  education,
  index,
}: SameEducationEditFormProps) => {
  return (
    <div style={{ marginLeft: "60px", display: "flex", gap: "18px" }}>
      <div style={{ marginTop: "10px" }}>
        <div
          style={{
            height: "11px",
            width: "11px",
            backgroundColor: "#418394",
            borderRadius: "100%",
          }}
        />
        <div
          style={{
            height: "133px",
            width: "1.2px",
            backgroundColor: "#418394",
            marginTop: "-4px",
            marginBottom: "-4px",
            marginLeft: "5px",
          }}
        />
        <div
          style={{
            height: "11px",
            width: "11px",
            backgroundColor: "#418394",
            borderRadius: "100%",
          }}
        />
      </div>
      <EducationForm education={education} isNestedEdu={true} />
    </div>
  );
};

export default SameEducationEditForm;
