import React, { useState, useEffect, useContext } from "react";
import { Avatar } from "antd";
import EXP_PLACEHOLDER from "assets/icons/DetailedProfile/experiencePlaceholder.svg";
import ExperienceForm from "../../../../../Auth/Profile/partials/ExperienceForm";
import SameExperienceEditForm from "./SameExperienceEditForm";
import _ from "lodash";
import { EditContext } from "containers/Candidates/Profile/Context/EditContext";

interface PropsType {
  experience: any;
  isRecruiterPage?: boolean;
  candidateId: any;
}

const convertToYear = (year: string | null): number => {
  if (!year) return new Date().getFullYear();
  if (year === "Present") return new Date().getFullYear();
  return parseInt(year.split("-")[0], 10);
};

const SingleExperience: React.FC<PropsType> = ({
  experience,
  candidateId,
  isRecruiterPage,
}) => {
  const [imgError, setImgError] = useState(false);
  const editContext = useContext<any>(EditContext);
  const [logo, setLogo] = useState(experience?.logo);
  const calculateTotalYearsSpent = (experience: any): any => {
    const experiences = [experience, ...(experience?.mergedData || [])];
    if (!editContext?.edit) {
      experiences.sort(
        (a: any, b: any) =>
          convertToYear(a.startYear) - convertToYear(b.startYear)
      );
    }

    const earliestStartYear = convertToYear(experiences[0].startYear);
    const latestEndYear = convertToYear(
      experiences[experiences.length - 1].endYear || "Present"
    );

    const val = latestEndYear - earliestStartYear;
    return val == 0 ? "" : val;
  };

  useEffect(() => {
    if (!experience?.logo) {
      setImgError(true);
    }
  }, [experience]);

  useEffect(() => {
    if (experience?.logo?.length > 4) {
      setImgError(false);
      setLogo(experience?.logo);
    }
  }, [experience?.logo]);

  const handleImageError = () => {
    setImgError(true);
    return false;
  };

  const Styles = {
    root: {
      paddingLeft: 0,
      display: "inline-block",
      maxWidth: 500,
      flex: "85%",
    },
    wrapper: {
      display: "flex",
      justifyContent: "space-between",
      marginBottom: 26,
      marginTop: "-8px",
    },
    year: {
      fontSize: 14,
      marginTop: 0,
      marginBottom: 0,
      color: "#495057",
    },
    position: {
      fontWeight: 500,
      fontSize: 14,
      marginTop: 0,
      marginBottom: 0,
      color: "rgb(0 0 0 / 85%)",
    },
    company: {
      fontWeight: 600,
      fontSize: 16,
      marginTop: 0,
      marginBottom: 0,
      color: "black",
    },
    location: {
      fontWeight: 400,
      fontSize: 14,
      marginTop: 0,
      marginBottom: 0,
      color: "#495057",
    },
    avatar: {
      backgroundColor: "#F5F7F9",
    },
    totalYears: {
      fontSize: "14px",
      fontWeight: "400",
      color: "rgba(0,0,0,.5)",
      marginTop: "-1px",
    },
    placeholderAvatar: {
      backgroundColor: "#ccc",
      marginRight: "2px",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
  };

  const totalYearsSpent: any = calculateTotalYearsSpent(experience);

  if (experience?.current) {
    experience.endYear = "Present";
  }

  if (experience?.mergedData && !editContext?.edit) {
    experience.mergedData = experience.mergedData.sort((s: any) =>
      s?.current ? -1 : 1
    );
  }

  experience = _.cloneDeep(experience);

  return (
    <div
      style={{
        ...Styles.wrapper,
        flexDirection: editContext?.edit ? "column" : "row",
        alignItems: editContext?.edit ? "start" : "end",
      }}
    >
      {!editContext?.edit && (
        <div style={{ ...Styles.root, wordWrap: "break-word" }}>
          <div
            style={{ display: "flex", justifyContent: "start", gap: "16px" }}
          >
            {!imgError ? (
              <Avatar
                style={{
                  ...Styles.avatar,
                  backgroundImage: `url(${logo})`,
                  backgroundSize: "contain",
                  backgroundPosition: "center",
                  backgroundRepeat: "no-repeat",
                  objectFit: "cover",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  marginTop: "3px",
                  margin: "5px",
                  flexShrink: "0",
                }}
                size={48}
                // src={logo}
                onError={handleImageError}
              />
            ) : (
              <span
                style={{
                  ...Styles.placeholderAvatar,
                  flexShrink: 0,
                  height: "48px",
                  width: "48px",
                  margin: "5px",
                  backgroundColor: "#f9f6f7",
                  borderRadius: "50%",
                }}
              >
                <img
                  style={{
                    height: "30px",
                    width: "30px",
                    background: "#f9f6f7",
                  }}
                  src={EXP_PLACEHOLDER}
                  alt="placeholder"
                />
              </span>
            )}
            <div style={{ marginLeft: "-8px", marginTop: "6px" }}>
              <p style={Styles.company}>{experience?.company}</p>
              {!experience?.mergedData && (
                <>
                  <span
                    style={{
                      color: "rgba(0,0,0,0.5)",
                      fontSize: "14px",
                      fontWeight: "400",
                    }}
                  >
                    {experience.startYear} {experience.endYear && "-"}{" "}
                    {experience?.endYear}
                  </span>
                  <p style={{ ...Styles.position }}>{experience?.position}</p>
                  <div className="">
                    <span
                      style={{
                        color: "#424952",
                        fontSize: "14px",
                        fontWeight: "400",
                      }}
                    >
                      {experience?.location?.city &&
                      experience?.location?.country
                        ? `${experience?.location?.city}, ${experience?.location?.country}`
                        : experience?.location?.city &&
                          !experience?.location?.country
                        ? `${experience?.location?.city}`
                        : ""}
                    </span>
                  </div>
                </>
              )}

              {totalYearsSpent &&
                !isNaN(totalYearsSpent) &&
                experience?.mergedData && (
                  <p style={Styles.totalYears}>{totalYearsSpent} years</p>
                )}

              {experience?.mergedData?.length > 0 &&
                experience.mergedData.map((d: any, i: any) => {
                  if (d?.current) {
                    d.endYear = "Present";
                  }
                  if (d.position !== experience.position || true) {
                    return (
                      <div
                        style={{
                          display: "flex",
                          gap: "10px",
                          marginTop: "20px",
                          marginLeft: "-40px",
                        }}
                        key={i}
                      >
                        <div style={{ marginTop: "10px" }}>
                          <div
                            style={{
                              height: "9px",
                              width: "9px",
                              backgroundColor: "#418394",
                              borderRadius: "100%",
                            }}
                          />
                          <div
                            style={{
                              height: "36px",
                              width: "1.2px",
                              backgroundColor: "#418394",
                              marginTop: "-4px",
                              marginBottom: "-4px",
                              marginLeft: "4.1px",
                            }}
                          />
                          <div
                            style={{
                              height: "9px",
                              width: "9px",
                              backgroundColor: "#418394",
                              borderRadius: "100%",
                            }}
                          />
                        </div>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            gap: "2px",
                            marginTop: "1px",
                            marginLeft: "16px",
                          }}
                        >
                          <span
                            style={{
                              color: "rgba(0,0,0,0.5)",
                              fontSize: "14px",
                              fontWeight: "400",
                            }}
                          >
                            {d.startYear} - {d.endYear}
                          </span>
                          <div style={Styles.position}>{d.position}</div>
                          <div className="">
                            <span
                              style={{
                                color: "#424952",
                                fontSize: "14px",
                                fontWeight: "400",
                              }}
                            >
                              {d?.location?.city && d?.location?.country
                                ? `${d?.location?.city}, ${d?.location?.country}`
                                : d?.location?.city && !d?.location?.country
                                ? `${d?.location?.city}`
                                : ""}
                            </span>
                          </div>
                        </div>
                      </div>
                    );
                  }
                  // return null;
                })}
            </div>
          </div>
        </div>
      )}
      {editContext?.edit ? (
        <>
          <ExperienceForm experience={experience} candidateId={candidateId} />
          {experience?.mergedData?.length > 0 &&
            experience.mergedData.map((d: any, i: any) => {
              d = _.cloneDeep(d);
              if (d?._id == experience?._id) {
                return null;
              }
              return (
                <SameExperienceEditForm key={i} experience={d} index={i} />
              );
            })}
        </>
      ) : (
        <></>
      )}
      <div style={{ flex: 1 }} />
    </div>
  );
};

export default SingleExperience;
