import React, { useEffect, useState } from "react";
import {
  Row,
  Col,
  Form,
  Input,
  Select,
  Button,
  Upload,
  message,
  Modal,
} from "antd";
import ImgCrop from "antd-img-crop";
import { Editor } from "@tinymce/tinymce-react";
import "./CompanyForm.scss";
import { compressImage } from "utils";

const { Option } = Select;
const { TextArea } = Input;

interface Props {
  onFinish?: any;
  imageData?: any;
  company?: any;
  sectors?: any;
  hasError?: boolean;
  isLoading?: boolean;
  setCompanyDescription?: any;
  // setCompanyConfidentailDescription?: any;
  setUploadLogo: any;
  uploadLogo: any;
  uploadBanner: any;
  setUploadBanner: any;
}

const CompanyForm: React.FC<Props> = ({
  onFinish,
  imageData,
  isLoading,
  hasError,
  company,
  sectors,
  setCompanyDescription,
  // setCompanyConfidentailDescription,
  setUploadLogo,
  uploadLogo,
  uploadBanner,
  setUploadBanner,
}) => {
  const [form] = Form.useForm();
  const [imgLoad, setImgLoad] = useState(false);
  const [imagePreview, setImagePreview] = useState("");
  const [imagePreviewModel, setImagePreviewModal] = useState(false);
  const [imagePreviewBanner, setImagePreviewBanner] = useState("");
  const [imagePreviewModelBanner, setImagePreviewModalBanner] = useState(false);
  const [fileList, setFileList] = useState<any>([]);
  const [fileListBanner, setFileListBanner] = useState<any>([]);

  const beforeUpload = (file: any) => {
    setImgLoad(true);

    const img = [
      "image/gif",
      "image/jpeg",
      "image/png",
      "image/jpg",
      "image/svg",
      "image/svg+xml",
    ];
    const isImg = img.includes(file.type);
    if (!isImg) {
      setImgLoad(false);
      message.error("You can only upload gif, jpeg, png, svg file!");
    }
    const isLt10M = file.size / 1024 / 1024 < 10;
    if (!isLt10M) {
      setImgLoad(false);
      message.error("Image must be smaller than 10MB!");
      return false;
    }
    return isImg;
  };
  const MIN_IMAGE_SIZE = 3 * 1024 * 1024; // 3MB
  const MAX_IMAGE_SIZE = 10 * 1024 * 1024; // 10MB

  const beforeUploadBanner = (file: any) => {
    setImgLoad(true);

    const img = [
      "image/gif",
      "image/jpeg",
      "image/png",
      "image/jpg",
      "image/svg",
      "image/svg+xml",
    ];

    const isImg = img.includes(file.type);
    if (!isImg) {
      setImgLoad(false);
      message.error("You can only upload gif, jpeg, png, svg files!");
      return false;
    }

    if (file.size < MIN_IMAGE_SIZE) {
      setImgLoad(false);
      message.error("Image must be larger than 3MB!");
      return false; // Prevent file from being uploaded
    }

    if (file.size > MAX_IMAGE_SIZE) {
      setImgLoad(false);
      message.error("Image must be smaller than 10MB!");
      return false; // Prevent file from being uploaded
    }

    return true; // Allow file upload
  };

  const onChange = async (e: any) => {
    if (imgLoad) {
      const latestFile = e.file.originFileObj;

      if (latestFile) {
        try {
          const compressedFile = await compressImage(latestFile);

          const updatedFileList = e.fileList.map((file: any) =>
            file.uid === e.file.uid
              ? { ...file, originFileObj: compressedFile }
              : file
          );

          setFileList(updatedFileList);

          if (updatedFileList.length !== 0) {
            imageData(compressedFile);
          }
        } catch (error) {
          console.error("Image compression failed:", error);
        }
      }
    }

    if (e.file.status === "done") {
      setImagePreview(e.file.response.secure_url);
      setUploadLogo({
        ...uploadLogo,
        loading: false,
        url: e.file.response.secure_url,
      });
    }
  };

  const onChangeBanner = async (e: any) => {
    if (imgLoad) {
      const latestFile = e.file.originFileObj;

      if (latestFile) {
        try {
          const compressedFile = await compressImage(latestFile);

          const updatedFileList = e.fileList.map((file: any) =>
            file.uid === e.file.uid
              ? { ...file, originFileObj: compressedFile }
              : file
          );

          setFileListBanner(updatedFileList);

          if (updatedFileList.length !== 0) {
            imageData(compressedFile);
          }
        } catch (error) {
          console.error("Image compression failed:", error);
        }
      }
    }

    if (e.file.status === "done") {
      setImagePreviewBanner(e.file.response.secure_url);
      setUploadBanner({
        ...uploadBanner,
        loading: false,
        url: e.file.response.secure_url,
      });
    }
  };

  const onRemove = (file: any) => {
    // console.log(file);
    if (file) {
      setFileList([]);
      setUploadLogo((prev: any) => ({ ...prev, url: null }));
    }
  };
  const onRemoveBanner = (file: any) => {
    // console.log(file);
    if (file) {
      setFileListBanner([]);
      setUploadBanner((prev: any) => ({ ...prev, url: null }));
    }
  };

  const onPreview = async (file: any) => {
    setImagePreviewModal(true);
  };
  const onPreviewBanner = async (file: any) => {
    setImagePreviewModalBanner(true);
  };

  useEffect(() => {
    if (company) {
      // console.log("Found Company in the form : ", company);
      form.setFieldsValue({
        companyName: company.name,
        description: company.description,
        confidentialdescription: company.confidentialdescription,
        sector: company.sector.title,
        companySize: company.companySize,
        companyWebsite: company.website ? company.website : null,
        facebook: company.facebook ? company.facebook : null,
        twitter: company.twitter ? company.twitter : null,
        linkedIn: company.linkedIn ? company.linkedIn : null,
      });
      if (company.logoUrl) {
        setFileList([
          {
            uid: company._id,
            name: company.name,
            status: "done",
            url: company.logoUrl ? company.logoUrl : "",
          },
        ]);
        setUploadLogo((prev: any) => ({ ...prev, url: company.logoUrl }));
        setImagePreview(company.logoUrl);
      }
      if (company.banner) {
        setFileListBanner([
          {
            uid: company._id,
            name: "No image",
            status: "done",
            url: company.banner ? company.banner : "",
          },
        ]);
        setUploadBanner((prev: any) => ({ ...prev, url: company.banner }));
        setImagePreviewBanner(company.banner);
      }
    }
  }, [company]);

  const handleEditorChange = (content: any, editor: any) => {
    // console.log("Content was updated:", content);
    setCompanyDescription(content);
  };

  // const handleConfidentialEditorChange = (content: any, editor: any) => {
  //   setCompanyConfidentailDescription(content);
  // };

  return (
    <>
      <Form
        name="registerCompanyForm"
        layout="vertical"
        form={form}
        onFinish={onFinish}
        scrollToFirstError
      >
        <Row justify="center" gutter={16}>
          <Col span={12}>
            <Form.Item
              name="companyName"
              label="Company name"
              rules={[
                {
                  required: true,
                  message: "Please input your Company Name!",
                },
              ]}
              hasFeedback
            >
              <Input
                type="text"
                placeholder="Enter Company Name"
                defaultValue={company && company.name}
              />
            </Form.Item>
            <div style={{ display: "flex" }}>
              <Form.Item name="companyLogo" label="Company logo">
                <ImgCrop beforeCrop={beforeUpload} rotate grid>
                  <Upload
                    listType="picture-card"
                    fileList={fileList}
                    onChange={onChange}
                    onRemove={onRemove}
                    action={`${process.env.REACT_APP_BASE_REST_URL}/upload/companies/assets`}
                    // action="https://api.cloudinary.com/v1_1/blonk-group/image/upload"
                    data={{
                      upload_preset: "cqdbrmkw",
                    }}
                    onPreview={onPreview}
                    accept="image/*"
                    multiple={false}
                    beforeUpload={beforeUpload}
                  >
                    {fileList.length < 1 && "+ Upload"}
                  </Upload>
                </ImgCrop>
              </Form.Item>
              <Form.Item
                name="companyBanner"
                label="Company banner image"
                style={{ marginLeft: "7%" }}
              >
                <ImgCrop
                  beforeCrop={beforeUploadBanner}
                  rotate
                  grid
                  aspect={5.91 / 1}
                  minZoom={0}
                  cropperProps={{
                    restrictPosition: false,
                  }}
                >
                  <Upload
                    listType="picture-card"
                    fileList={fileListBanner}
                    onChange={onChangeBanner}
                    onRemove={onRemoveBanner}
                    action={`${process.env.REACT_APP_BASE_REST_URL}/upload/companies/assets`}
                    data={{
                      upload_preset: "cqdbrmkw",
                    }}
                    onPreview={onPreviewBanner}
                    accept="image/*"
                    multiple={false}
                  >
                    {fileListBanner && fileListBanner.length < 1 && "+ Upload"}
                  </Upload>
                </ImgCrop>
              </Form.Item>
            </div>
            <Form.Item
              name="description"
              label="Company description"
              hasFeedback
              rules={[
                {
                  required: true,
                  message: "Please input your Company description!",
                },
              ]}
            >
              <Editor
                apiKey="bgow6d7d89zjmvftzkn8p8gzzhkzyd551egy09ik6j400toe"
                init={{
                  max_chars: 50000, // max. allowed chars
                  height: 500,
                  menubar: true,
                  plugins: [
                    "advlist autolink link image lists charmap print preview hr anchor pagebreak spellchecker",
                    "searchreplace wordcount visualblocks visualchars code fullscreen insertdatetime media nonbreaking",
                    "save table contextmenu directionality emoticons template paste textcolor",
                  ],
                  toolbar:
                    "insertfile undo redo | styleselect | bold italic | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | link image | print preview media fullpage | forecolor backcolor emoticons",
                }}
                onEditorChange={handleEditorChange}
              />
            </Form.Item>
            {/* <Form.Item
              name="confidentialdescription"
              label="Confidential company description"
              hasFeedback
            >
              <Editor
                apiKey="bgow6d7d89zjmvftzkn8p8gzzhkzyd551egy09ik6j400toe"
                init={{
                  max_chars: 50000, // max. allowed chars
                  height: 500,
                  menubar: true,
                  plugins: [
                    "advlist autolink link image lists charmap print preview hr anchor pagebreak spellchecker",
                    "searchreplace wordcount visualblocks visualchars code fullscreen insertdatetime media nonbreaking",
                    "save table contextmenu directionality emoticons template paste textcolor",
                  ],
                  toolbar:
                    "insertfile undo redo | styleselect | bold italic | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | link image | print preview media fullpage | forecolor backcolor emoticons",
                }}
                onEditorChange={handleConfidentialEditorChange}
              />
            </Form.Item> */}
          </Col>
          <Col span={12}>
            <Form.Item
              name="sector"
              label="Sector"
              className="company-sector-field"
              rules={[
                {
                  required: true,
                  message: "Please select sector!",
                },
              ]}
              hasFeedback
            >
              <Select
                showSearch
                placeholder="Select sector"
                // optionFilterProp="children"
                // onChange={onChange}
                // onFocus={onFocus}
                // onBlur={onBlur}
                // onSearch={onSearch}
                filterOption={(input, option: any) =>
                  option.children
                    ?.toLowerCase()
                    .indexOf(input?.toLowerCase()) >= 0
                }
              >
                {sectors &&
                  sectors.map((sector: any) => {
                    if (sector?.title)
                      return <Option value={sector._id}>{sector.title}</Option>;
                    else {
                      return null;
                    }
                  })}
              </Select>
            </Form.Item>
            <Form.Item
              name="companySize"
              label="Company size"
              className="company-size-field "
              rules={[
                {
                  required: true,
                  message: "Please select sector!",
                },
              ]}
              hasFeedback
            >
              <Select
                showSearch
                placeholder="Select company size"
                filterOption={(input, option: any) =>
                  option.children
                    ?.toLowerCase()
                    .indexOf(input?.toLowerCase()) >= 0
                }
              >
                <Option value="1-10">1-10</Option>

                <Option value="11-100">11-100</Option>

                <Option value="101-500">101-500</Option>

                <Option value="501-1000">501-1000</Option>

                <Option value="1001-5000">1001-5000</Option>

                <Option value="5001+">5001+</Option>
              </Select>
            </Form.Item>
            <Form.Item
              name="companyWebsite"
              label="Company website"
              rules={[
                {
                  required: true,
                  // type: "url",
                  message: "Please enter valid company URL including https://",
                },
              ]}
              hasFeedback
            >
              <Input
                // required
                type="text"
                placeholder="Add company URL including https://"
              />
            </Form.Item>
            <Form.Item
              name="facebook"
              label="Facebook"
              rules={[
                {
                  type: "url",
                  message: "The input is not valid URL including https://",
                },
              ]}
              hasFeedback
            >
              <Input type="text" placeholder="Add URL including https://" />
            </Form.Item>
            <Form.Item
              name="twitter"
              label="Twitter"
              rules={[
                {
                  type: "url",
                  message: "The input is not valid URL including https://",
                },
              ]}
              hasFeedback
            >
              <Input type="text" placeholder="Add URL including https://" />
            </Form.Item>
            <Form.Item
              name="linkedIn"
              label="LinkedIn"
              rules={[
                {
                  type: "url",
                  message: "The input is not valid URL including https://",
                },
              ]}
              hasFeedback
            >
              <Input type="text" placeholder="Add URL including https://" />
            </Form.Item>
            <Form.Item>
              <div className="text-right">
                <Button
                  className="ant-btn-amber"
                  htmlType="submit"
                  shape="round"
                  disabled={hasError}
                  loading={isLoading}
                >
                  {company ? "Save" : "Create"}
                </Button>
              </div>
            </Form.Item>
          </Col>
        </Row>
        <Modal
          visible={imagePreviewModel}
          title={"Preview image"}
          footer={null}
          onCancel={() => {
            setImagePreviewModal(false);
          }}
        >
          <img
            alt="example"
            style={{ width: 475, height: 475, marginBottom: "16px" }}
            src={imagePreview}
          />
        </Modal>
        <Modal
          className="preview-modal"
          centered={true}
          visible={imagePreviewModelBanner}
          title={"Preview image"}
          footer={null}
          onCancel={() => {
            setImagePreviewModalBanner(false);
          }}
        >
          <img
            alt="example"
            style={{ width: "100%", height: 475, marginBottom: "16px" }}
            src={imagePreviewBanner}
          />
        </Modal>
      </Form>
    </>
  );
};

export default CompanyForm;
