import React, { useRef, useState, useEffect, useContext } from "react";
import { SearchOutlined } from "@ant-design/icons";
import {
  LoadHiredCandidates,
  LoadRejectedCandidates,
  StoreDislikedCandidates,
  StoreLikedCandidates,
  StoreMatchedCandidates,
  StoreNewCandidates,
  StoreSelectedCandidatesLoad,
} from "store/matchs/actions";
import { useDispatch } from "react-redux";
import type { InputRef } from "antd";
import { useMatchesForAJobLazyQuery } from "graphql/generated/graphql";
import { TabActvContext } from "../context/tabActvContext";
import { Row, Col, Card, Form, Input, Select, Button } from "antd";
import { Fade } from "react-awesome-reveal";
import "./Searchbar.scss";
const { Option } = Select;

export default function Searchbar(param: any) {
  const { setSearchData } = useContext(TabActvContext);
  const searchTextRef = useRef<InputRef>(null);
  const searchFormRef = useRef<any>(null);
  const [form] = Form.useForm();
  let dispatch = useDispatch();
  const [matchs, setMatchs]: any = useState(null);
  const [searchOptions, setSearchOptions] = useState<any>({
    searchText: "",
    searchType: "All",
  });
  const [loadMatches, { data, loading, error }] = useMatchesForAJobLazyQuery({
    variables: {
      jobId: param.jobId,
      searchParams: {
        searchText: searchOptions.searchText ? searchOptions.searchText : "",
        searchType: searchOptions.searchField
          ? searchOptions.searchField
          : "All",
      },
    },
    fetchPolicy: "network-only",
  });
  const onFinish = (values: any) => {
    loadMatches({ variables: { jobId: param.jobId } });
    setSearchOptions({
      ...searchOptions,
      searchText: values.searchText ? values.searchText : "",
      searchType: values.searchField ? values.searchField : "All",
    });
  };

  useEffect(() => {
    if (data) {
      setMatchs(data.matchesForAJob);
      dispatch(
        LoadHiredCandidates(
          data?.matchesForAJob
            .filter((job) => job.recruiterAction === "hired")
            .reverse()
        )
      );
      dispatch(
        LoadRejectedCandidates(
          data?.matchesForAJob
            .filter((job) => job.recruiterAction === "rejected")
            .reverse()
        )
      );
      dispatch(
        StoreDislikedCandidates(
          data?.matchesForAJob
            .filter((job) => job.recruiterAction === "dislike")
            .reverse()
        )
      );
      dispatch(
        StoreSelectedCandidatesLoad(
          data?.matchesForAJob
            .filter((job) => job.recruiterAction === "selected")
            .reverse()
        )
      );
      dispatch(
        StoreMatchedCandidates(
          data?.matchesForAJob
            .filter((job) => job.recruiterAction === "like")
            .reverse()
        )
      );
      dispatch(
        StoreNewCandidates(
          data?.matchesForAJob
            .filter(
              (job) => job.recruiterAction === null && job.activationScore === 2
            )
            .reverse()
        )
      );

      setSearchData(data.matchesForAJob);
    }
  }, [data]);

  const onKeyDown = (e: any) => {
    if (e.key === "Enter") {
      e.preventDefault();
      form.submit();
    }
  };
  const onReset = (values: any) => {
    setSearchOptions({
      ...searchOptions,
      searchText: values.searchText ? values.searchText : "",
      searchType: values.searchField ? values.searchField : "All",
    });
  };
  return (
    <Row justify="center" gutter={16} className="antd-card">
      <Col span={24}>
        <Fade>
          <Card>
            <Form
              ref={searchFormRef}
              form={form}
              name="control-hooks"
              onFinish={onFinish}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                }}
                className="container-Search"
              >
                <div style={{ display: "flex" }}>
                  <Form.Item name="searchText" style={{ minHeight: "50px" }}>
                    <Input
                      style={{ minHeight: "50px" }}
                      placeholder="Enter key words"
                      allowClear
                      ref={searchTextRef}
                      prefix={<SearchOutlined />}
                      onChange={onReset}
                      onPressEnter={() => form.submit()}
                      className="filter-search"
                    />
                  </Form.Item>
                  <Form.Item
                    name="searchField"
                    className="search-type search-select"
                  >
                    <Select
                      placeholder="Select field"
                      allowClear
                      onKeyDown={onKeyDown}
                      defaultValue={searchOptions.searchType}
                      onChange={(value) =>
                        setSearchOptions({
                          ...searchOptions,
                          searchField: value,
                        })
                      }
                      className="tiltle-select"
                    >
                      <Option value="All" selected="selected">
                        All
                      </Option>
                      <Option value="firstName">First name</Option>
                      <Option value="lastName">Last name</Option>
                      <Option value="title">Title</Option>
                      <Option value="companyName">Company</Option>
                      <Option value="position">Position</Option>
                    </Select>
                  </Form.Item>
                  <Button
                    className="search-button"
                    htmlType="submit"
                    onClick={() => form.submit()}
                  >
                    Search
                  </Button>
                </div>
              </div>
            </Form>
          </Card>
        </Fade>
      </Col>
    </Row>
  );
}
