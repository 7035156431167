import React, { useRef, useState, useEffect, useContext } from "react";
import { SearchOutlined } from "@ant-design/icons";
import type { InputRef } from "antd";
import { useMatchesForAJobLazyQuery } from "graphql/generated/graphql";
import { Row, Col, Card, Form, Input, Select, Button } from "antd";
import { Fade } from "react-awesome-reveal";
// import "./Searchbar.scss";
const { Option } = Select;

export default function JobSearchbar(param: any) {
  const searchTextRef = useRef<InputRef>(null);
  const searchFormRef = useRef<any>(null);
  const [form] = Form.useForm();
  const [matchs, setMatchs]: any = useState(null);
  const [searchOptions, setSearchOptions] = useState<any>({
    searchText: "",
    searchType: "All",
  });

  const onFinish = (values: any) => {
    setSearchOptions({
      ...searchOptions,
      searchText: values.searchText ? values.searchText : "",
      searchType: values.searchField ? values.searchField : "All",
    });
    param.handleSearch(searchOptions);
  };

  const onKeyDown = (e: any) => {
    if (e.key === "Enter") {
      e.preventDefault();
      form.submit();
      param.handleClick(searchOptions);
    }
  };
  const onReset = (values: any) => {
    if (values.target?.value.length <= 0) {
      param.setIsSearchFieldEmpty(true);
    } else {
      param.setIsSearchFieldEmpty(false);
    }
    setSearchOptions({
      ...searchOptions,
      searchText: values.target?.value ? values.target?.value : "",
      // searchType: values.searchField ? values.searchField : "All",
    });
    param.handleSearch({
      searchText: values.target?.value ? values.target?.value : "",
      searchField: searchOptions.searchType,
    });
  };

  return (
    <Row justify="center" gutter={16} className="antd-card">
      <Col span={24}>
        <Fade>
          <Card style={{ padding: "6px" }}>
            <Form
              ref={searchFormRef}
              form={form}
              name="control-hooks"
              onFinish={onFinish}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  minHeight: "50px",
                }}
                className="container-Search"
              >
                <div
                  style={{ display: "flex", minHeight: "50px" }}
                  className="searchBar search-select"
                >
                  <Form.Item name="searchText" style={{ minHeight: "50px" }}>
                    <Input
                      placeholder="Enter key words"
                      allowClear
                      ref={searchTextRef}
                      prefix={<SearchOutlined />}
                      onChange={(val: any) => {
                        onReset(val);
                      }}
                      onPressEnter={() => form.submit()}
                      className="filter-search"
                      style={{ borderColor: "lightgray", minHeight: "50px" }}
                    />
                  </Form.Item>
                  <Form.Item
                    name="searchField"
                    className="search-type select-search-type"
                    style={{ minHeight: "50px" }}
                  >
                    <Select
                      style={{ minHeight: "50px" }}
                      placeholder="Select field"
                      allowClear
                      onKeyDown={onKeyDown}
                      defaultValue={searchOptions.searchType}
                      onChange={(value) =>
                        setSearchOptions({
                          ...searchOptions,
                          searchField: value,
                        })
                      }
                      className="tiltle-select"
                    >
                      <Option value="All" selected="selected">
                        All
                      </Option>
                      <Option value="title">Title</Option>
                      <Option value="companyName">Company</Option>
                      <Option value="location">Location</Option>
                    </Select>
                  </Form.Item>
                  <Button
                    className="search-button"
                    htmlType="submit"
                    style={{ fontSize: "16px" }}
                    onClick={() => {
                      form.submit();
                      param.handleClick(searchOptions);
                    }}
                  >
                    Search
                  </Button>
                </div>
              </div>
            </Form>
          </Card>
        </Fade>
      </Col>
    </Row>
  );
}
