import { message, Modal, Upload } from "antd";
import Dragger from "antd/lib/upload/Dragger";
import React, { useState } from "react";
import uploadSvg from "../../../../assets/icons/icons/upload.svg";

interface ProfilePictureProps {
  uploadPicture: string;
  setUploadPicture: any;
  recruiter: any;
  form: any;
  setIsDisabled: any;
}

const MAX_IMAGE_SIZE = 2 * 1024 * 1024;

const ProfilePicture = ({
  uploadPicture,
  setUploadPicture,
  recruiter,
  form,
  setIsDisabled,
}: ProfilePictureProps) => {
  const [imagePreviewModel, setImagePreviewModal] = useState(false);

  const onPreviewProfilePic = async () => {
    setImagePreviewModal(true);
  };

  const props = {
    name: "file",
    multiple: false,
    accept: "image/png, image/jpeg,image/jpg",
    maxCount: 1,
    action: `${process.env.REACT_APP_BASE_REST_URL}/upload/recrutier/${recruiter?._id}`,
    onChange(info: any) {
      setIsDisabled(false);
      const { status } = info.file;
      if (status) {
        if (status !== "uploading") {
          setUploadPicture(info?.file?.response?.secure_url);
          form.validateFields(["profilePic"]);
        }
        if (status === "done") {
          message.success(`${info.file.name} file uploaded successfully.`);
        } else if (status === "error") {
          message.error(`${info.file.name} file upload failed.`);
        }
      }
    },
    beforeUpload(file: any) {
      if (file.size > MAX_IMAGE_SIZE) {
        message.error("File size exceeds the limit (2MB)!");
        return Upload.LIST_IGNORE; // Prevent file from being uploaded
      }
      const img = ["image/jpeg", "image/png", "image/jpg"];
      const isImg = img.includes(file.type);
      if (!isImg) {
        message.error("You can only upload images file!");
        return Upload.LIST_IGNORE; // Prevent file from being uploaded
      }
      return true;
    },
  };

  return (
    <div>
      {uploadPicture ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginTop: "10px",
          }}
        >
          <div className="hover-img">
            <div className="image-wrapper">
              <img src={uploadPicture} alt="avatar" />
              <span
                className="view-icon z-2"
                onClick={() => onPreviewProfilePic()}
              ></span>
              <span
                className="delete-icon z-2"
                onClick={() => setUploadPicture("")}
              ></span>
              <div className="image-overlay"></div>
            </div>
          </div>
        </div>
      ) : // uploadButton
      null}
      {!uploadPicture && (
        <Dragger
          {...props}
          data={{
            upload_preset: "cqdbrmkw",
          }}
        >
          <p className="ant-upload-drag-icon">
            <img src={uploadSvg} alt="" />
            <br />
            <span className="large-text-drawer-color">
              Click to upload
            </span>{" "}
            <span className="small-text-drawer-color">or drag and drop</span>
          </p>
          <p className="small-text-drawer-color">
            Authorized formats: PNG, JPG, or GIF - max size 2MB min 256KB
          </p>
        </Dragger>
      )}
      <Modal
        className="preview-modal"
        centered
        visible={imagePreviewModel}
        title={"Profile picture preview"}
        footer={null}
        onCancel={() => {
          setImagePreviewModal(false);
        }}
      >
        <img
          alt="example"
          style={{ width: "100%", height: 475, marginBottom: "20px" }}
          src={uploadPicture}
        />
      </Modal>
    </div>
  );
};

export default ProfilePicture;
