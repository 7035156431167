import { CloseOutlined, PlusOutlined } from "@ant-design/icons";
import { Tag, Tooltip } from "antd";
import { TagsIcon } from "assets/icons/DetailedProfile";
import React, { useContext, useState } from "react";

import { SectionTitle } from "../../Shared";
import "../../styles.scss";

interface PropsType {
  candidate: any;
  onAdd?: any;
}

const Tags: React.FC<PropsType> = ({ candidate, onAdd }) => {
  const [isSeeMore, setIsSeeMore] = useState(false);

  const SingleTag = ({ title, color }: any) => {
    return (
      <Tag
        closeIcon={<CloseOutlined style={{ color: "white" }} />}
        style={{
          border: "none",
          marginRight: 10,
          marginBottom: 15,
          backgroundColor: color,
          borderRadius: 20,
          color: "white",
          paddingTop: 5,
          paddingBottom: 5,
          paddingLeft: 10,
          paddingRight: 10,
          fontSize: 14,
          fontWeight: 500,
          fontFamily: "poppins",
        }}
      >
        {title}
      </Tag>
    );
  };

  const SeeMore = () => {
    return (
      <Tag onClick={() => setIsSeeMore(!isSeeMore)} className="see-more-btn">
        {isSeeMore ? "See less" : "See more"}
      </Tag>
    );
  };

  return (
    <div style={{ width: "326px" }}>
      {candidate?.tags.length > 0 && (
        <>
          <SectionTitle showstyle={false} title={"Tags"} fontSize={16} />
          <div
            style={{
              // paddingLeft: 25,
              paddingTop: 20,
              display: "flex",
              flexWrap: "wrap",
              alignItems: "center",
            }}
          >
            {candidate?.tags != null &&
              candidate?.tags.map(
                (tag: any, index: any) => (
                  // index < 2 || isSeeMore ? (
                  <SingleTag color={tag.color} title={tag.name} />
                )
                // ) : null
              )}
            {/* {candidate?.tags.length > 2 && <SeeMore />} */}
          </div>
        </>
      )}
    </div>
  );
};

export default Tags;
