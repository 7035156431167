import React from "react";
import ExperienceForm from "../../../../../Auth/Profile/partials/ExperienceForm";

interface SameExperienceEditFormProps {
  experience: any;
  index: any;
}
const SameExperienceEditForm = ({
  experience,
  index,
}: SameExperienceEditFormProps) => {
  return (
    <div style={{ marginLeft: "60px", display: "flex", gap: "18px" }}>
      <div style={{ marginTop: "10px" }}>
        <div
          style={{
            height: "11px",
            width: "11px",
            backgroundColor: "#418394",
            borderRadius: "100%",
          }}
        />
        <div
          style={{
            height: "133px",
            width: "1.2px",
            backgroundColor: "#418394",
            marginTop: "-4px",
            marginBottom: "-4px",
            marginLeft: "5px",
          }}
        />
        <div
          style={{
            height: "11px",
            width: "11px",
            backgroundColor: "#418394",
            borderRadius: "100%",
          }}
        />
      </div>
      <ExperienceForm experience={experience} isNestedExp={true} />
    </div>
  );
};

export default SameExperienceEditForm;
