import React, { useContext, useEffect, useState } from "react";
import { EditContext } from "../../../Context/EditContext";
import _ from "lodash";

interface PropsType {
  experience: any;
  mergedData?: any;
}

const Styles = {
  root: {},
  commentBox: {
    paddingLeft: 25,
    paddingRight: 25,
    paddingTop: 8,
    paddingBottom: 8,
    backgroundColor: "rgba(188, 150, 35, 0.03)",
    marginTop: 10,
    borderRadius: 9,
    marginBottom: 17,
    boxShadow: "0px 0px 9px rgba(0, 0, 0, 0.14)",
    border: "solid 0.5px #DADADA",
  },
  body: {
    margin: 0,
    color: "#495057",
    fontSize: 14,
    fontWeight: 400,
    fontStyle: "italic",
  },
  heading: {
    margin: 0,
    color: "#012653",
    fontSize: 14,
    fontWeight: 500,
  },
};

const ExperienceComment: React.FC<PropsType> = ({ experience, mergedData }) => {
  const comments = experience?.commentData?.comments;
  const reasonForChange = experience?.commentData?.reasonForChange;
  //\n replace with br tag
  const formattedComment = comments?.replace(/\n/g, "<br />");
  const formattedReasaonFroChange = reasonForChange?.replace(/\n/g, "<br />");

  const [commentValue, setCommentValue] = useState(formattedComment);
  const [reasonValue, setReasonValue] = useState(formattedReasaonFroChange);
  const editContext = useContext(EditContext);

  const handleChangeComment = (event: any) => {
    setCommentValue(event.target.value);
    const exp = _.cloneDeep(
      editContext?.updatedData?.experiences?.length
        ? editContext?.updatedData?.experiences
        : editContext?.candidate?.experiences
    );

    const updatedExp = exp?.map((e: any) => {
      if (e?.company == experience?.company) {
        e?.mergedData?.map((m: any) => {
          m.comments = event.target.value;
        });

        e.comments = event.target.value;
      }

      return e;
    });
    editContext?.setUpdatedData({
      ...editContext?.updatedData,
      experiences: updatedExp,
    });
  };

  const handleChangeReason = (e: any) => {
    setReasonValue(e.target.value);
    const exp = _.cloneDeep(
      editContext?.updatedData?.experiences?.length
        ? editContext?.updatedData?.experiences
        : editContext?.candidate?.experiences
    );
    const updatedExp = exp?.map((exp: any) => {
      if (exp?.company == experience?.company) {
        exp.reasonForChange = e.target.value;
      }
      return exp;
    });
    editContext?.setUpdatedData({
      ...editContext?.updatedData,
      experiences: updatedExp,
    });
  };

  return (
    <div
      style={
        (comments != null && comments?.length > 0) ||
        (reasonForChange != null && reasonForChange?.length > 0) ||
        editContext?.edit
          ? Styles.commentBox
          : {}
      }
    >
      {((comments != null && comments?.length > 0) || editContext?.edit) && (
        <>
          <p style={Styles.heading}>Comments:</p>
          {/* <p style={Styles.body}>{comments}</p> */}
          {editContext?.edit ? (
            <textarea
              name=""
              id=""
              value={commentValue}
              placeholder="comments..."
              style={{
                width: "100%",
                fontStyle: "italic",
                fontSize: "14px",
                border: "none",
                resize: "none",
                backgroundColor: "transparent",
                outline: "none",
              }}
              onChange={handleChangeComment}
            ></textarea>
          ) : (
            <div
              style={Styles.body}
              dangerouslySetInnerHTML={{ __html: formattedComment }}
            />
          )}
        </>
      )}
      {((reasonForChange != null && reasonForChange?.length > 0) ||
        editContext?.edit) && (
        <>
          {reasonForChange == null || reasonForChange == "" ? null : (
            <span
              style={{
                display: "flex",
                border: "1px #00000040 dashed",
                margin: 2,
              }}
            ></span>
          )}
          <p style={Styles.heading}>Reasons for change:</p>
          {editContext?.edit ? (
            <textarea
              name=""
              id=""
              placeholder="reasons..."
              value={reasonValue}
              style={{
                width: "100%",
                fontStyle: "italic",
                fontSize: "14px",
                border: "none",
                resize: "none",
                backgroundColor: "transparent",
                outline: "none",
              }}
              onChange={handleChangeReason}
            ></textarea>
          ) : (
            <div
              style={Styles.body}
              dangerouslySetInnerHTML={{ __html: formattedReasaonFroChange }}
            />
          )}
          {/* <p style={Styles.body}>{reasonForChange}</p> */}
        </>
      )}
    </div>
  );
};

export default ExperienceComment;
