/* eslint-disable react/prop-types */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import React, { useEffect, useState, useRef } from "react";
import { Modal, Drawer, Button, Input, Row, Col } from "antd";
import db from "../../../firebase";
import moment from "moment-timezone";
import firebase from "firebase";
import _ from "lodash";
import {
  useGetSingleRecruiterQuery,
  useSendMessageToCandidateMutation,
} from "../../../graphql/generated/graphql";
import "../Buttons/Buttons.scss";
import { useMutation } from "react-apollo";
import { removeMultipleNotifications } from "components/TopBar/partials/NotificationDropdown/notifications";
import { useDispatch, useSelector } from "react-redux";
import { DeleteAllNotifications } from "store/notifications/actions";
import { CloseOutlined } from "@ant-design/icons";

interface Props {
  onOK: () => void;
  title?: string;
  width: number;
  titleType?: string;
  ModalTytle: string;
  btnIcon?: JSX.Element;
  footer?: null;
  titleBtnClass?: string;
  headerTitle?: string;
  candidate: any;
  record: any;
  enableCustomBtnIcon?: boolean;
  customBtnIcon?: JSX.Element;
  recruiter?: any;
  company?: any;
  clickOnNotification?: any;
  matchId?: any;
}
interface Notification {
  _id: string;
  userId: string;
  companyId: string;
  jobId: string;
  matchId: string;
  createdAt: any;
  updatedAt: any;
  isRead: boolean;
  type: string;
  notificationHeader: string;
  notificationBody: string;
}

const ModalComponent: React.FC<Props> = (props) => {
  const divRef = useRef(null);
  const [visible, setVisible] = useState(false);
  const { notifications } = useSelector((state: any) => state.notifications);
  const [messages, setmessages] = useState([]);
  const [inputVal, setinputVal] = useState("");
  const { data, loading, error, refetch }: any = useGetSingleRecruiterQuery({
    fetchPolicy: "network-only",
  });
  const dispatch = useDispatch();
  const [sendMessageMutation] = useSendMessageToCandidateMutation();
  const { TextArea } = Input;

  const getMessages = (roomId: any, recruiter: any) => {
    if (roomId && recruiter) {
      db.collection("new-chats")
        .doc(roomId)
        .collection(recruiter?.getSingleRecruiter?._id)
        .orderBy("timestamp", "asc")
        .onSnapshot((snapshot: any) => {
          const msgData = snapshot?.docs?.map((s: any) => s.data());
          setmessages(msgData ?? []);
        });
    }
  };

  const [setMultipleNotificationsToRead, { data: data5 }] = useMutation(
    removeMultipleNotifications
  );

  const clickOnNotification = (matchId: string, type: string) => {
    const foundNotification = notifications
      .filter(
        (notif: Notification) => notif.type == type && notif.matchId == matchId
      )
      .map((notif: Notification) => notif._id);

    if (foundNotification.length) {
      setMultipleNotificationsToRead({
        variables: {
          notificationsArray: [...foundNotification],
        },
      })
        .then((data: any) => {
          dispatch(DeleteAllNotifications(foundNotification));
        })
        .catch((err) => {
          console.log("An error occured while deleting all notifications");
          console.log({ err });
        });
    }
  };

  useEffect(() => {
    if (visible) {
      getMessages(
        props?.record?.matchId
          ? props.record?.matchId
          : props?.record?._id
          ? props?.record?._id
          : props?.matchId,
        data
      );
    }
  }, [visible]);
  useEffect(() => {
    divRef.current?.scrollIntoView({ behavior: "smooth" });
  }, [messages]);

  const sendMessage = (e: any) => {
    e?.preventDefault(); //////causing error
    if (inputVal) {
      // recruiterId is _id of recruiter in recruiters table
      //senderId is candidate's _id in candidateListing table

      // we're taking the timestamp of our last message
      const messagesOfRecruiter = messages.filter(
        (message: any) => message.senderId === data?.getSingleRecruiter?._id
      );
      const lengthOfMessages = messagesOfRecruiter.length;

      const payload = {
        type: "chat",
        source: "web",
        matchId: props.record?.matchId
          ? props.record?.matchId
          : props.record._id
          ? props.record._id
          : props.matchId,
        recruiterId: data?.getSingleRecruiter?._id,
        senderId: data?.getSingleRecruiter?._id,
        jobId: props?.record?.jobListing?._id
          ? props?.record?.jobListing?._id
          : props?.record?.jobData?._id,
        candidateId: props?.candidate?._id,
        companyId: props?.company?._id,
        timeStampOfMyLastMessage:
          messagesOfRecruiter[lengthOfMessages - 1]?.timestamp || null,
      };

      sendMessageMutation({
        variables: {
          sendTo: props?.candidate?._id,
          myRole: "recruiter",
          message: inputVal,
          payload,
        },
        // context: {
        //   headers: {
        //     authorization: `Bearer ${token}`,
        //   },
        // },
      })
        .then((res) => {
          setinputVal("");
          console.log("🚀 ~ Chat message sent successful");
        })
        .catch((err) => {
          console.log({ err });

          console.log("🚀 ~ Chat message error", err);
        });
    }
    setinputVal("");
  };

  const messageHandler = (e: any) => {
    setinputVal(e.target.value);
  };

  const renderMessages = (candidate: any, matchRecord: any) => {
    return (
      <div>
        <div className="chat-header">
          <Row>
            <Col span={22}>
              <div>
                <div
                  // href="candidate-profile.html"
                  // target="_blank"
                  className="media"
                  style={{ display: "flex" }}
                >
                  <div className="chat-icon-candidate">
                    <img
                      src={candidate.profilePicture}
                      style={{
                        width: 70,
                        borderRadius: "50%",
                        height: 70,
                        backgroundSize: "cover",
                        objectFit: "cover",
                      }}
                      alt="user"
                      className="rounded-circle"
                    />
                  </div>

                  <div className="media-body">
                    <div>
                      <h6
                        className="m-0"
                        style={{
                          fontWeight: 500,
                          fontSize: "20px",
                          color: "#FFFFFF",
                        }}
                      >
                        {candidate.firstName + " " + candidate.lastName}
                      </h6>
                      <p
                        className="mb-0"
                        style={{
                          fontWeight: 400,
                          fontSize: "16px",
                          color: "#FFFFFF",
                        }}
                      >
                        {props?.candidate?.position
                          ? " " + props?.candidate?.position
                          : props?.record?.jobData?.title}
                        {props?.candidate?.company
                          ? props?.candidate?.company?.name?.length
                            ? " @ " + props?.candidate?.company?.name
                            : " @ " + props?.candidate?.company
                          : " @ " + props?.record?.companyData?.name}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </Col>
            <Col span={2}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "right",
                  color: "#f9f9f9",
                  fontSize: "20px",
                  marginTop: "10px",
                }}
              >
                <CloseOutlined
                  onClick={handleCancel}
                  style={{ paddingRight: "5px" }}
                />
              </div>
            </Col>
          </Row>
        </div>
        {messages && messages.length == 0 && (
          <div
            className="chat-body chat-popup"
            style={{
              display: "flex",
              alignItems: "center",
              textAlign: "center",
              fontWeight: 500,
              fontStyle: "italic",
              fontSize: "110%",
              minHeight: "90vh",
              maxHeight: "45vh",
            }}
          >
            <p style={{ margin: "auto", fontWeight: 400 }}>
              Start a chat with the candidate ...
            </p>
          </div>
        )}
        {messages && messages.length > 0 && (
          <div
            className="chat-body chat-popup"
            style={{
              height: "calc(100vh - 174px)",
            }}
          >
            {messages &&
              messages.map((msg: any, key: any) => {
                const userTimeZone =
                  Intl.DateTimeFormat().resolvedOptions().timeZone;

                return (
                  <>
                    <div className="chat">
                      {/* style={{ marginBottom: 20 }} */}
                      <div>
                        {msg.senderId == data?.getSingleRecruiter?._id ? (
                          <>
                            {/* <p
                          style={{
                            display: "flex",
                            justifyContent: "flex-end",
                            lineHeight: 0,
                            fontSize: 12,
                          }}
                        >
                          {data?.getSingleRecruiter?.fullName}
                        </p> */}
                            <div
                              className="inbox-item-author text-amber"
                              style={{
                                display: "flex",
                                justifyContent: "center",
                                fontSize: 14,
                              }}
                            >
                              <p
                                style={{
                                  color: "#00000040",
                                }}
                              >
                                {moment(
                                  new Date(
                                    messages[key - 1]?.timestamp?.toDate()
                                  ).toUTCString()
                                ).format("DD/MM/YY") ==
                                moment(
                                  new Date(
                                    msg?.timestamp?.toDate()
                                  ).toUTCString()
                                ).format("DD/MM/YY")
                                  ? ""
                                  : moment(
                                      new Date(
                                        msg?.timestamp?.toDate()
                                      ).toUTCString()
                                    ).format("DD/MM/YY")}
                                {/* at{" "} */}
                                {/* {moment(
                            new Date(msg?.timestamp?.toDate()).toUTCString()
                          ).format("HH:MM")} */}
                              </p>
                            </div>
                            <div
                              className="box sent"
                              style={{ marginRight: 0 }}
                            >
                              <div
                                style={{
                                  margin: "5px",
                                  fontWeight: 400,
                                  fontSize: "14px",
                                }}
                              >
                                {msg.message}
                              </div>
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "end",
                                  fontSize: "11px",
                                  paddingRight: "5px",
                                }}
                              >
                                {moment(
                                  new Date(
                                    msg?.timestamp?.toDate()
                                  ).toUTCString()
                                )
                                  .tz(userTimeZone)
                                  .format("HH:mm")}
                              </div>
                            </div>
                          </>
                        ) : (
                          <>
                            <p
                              style={{
                                display: "flex",
                                justifyContent: "flex-start",
                                lineHeight: 0,
                                fontSize: "14px",
                                color: "#012653",
                                fontWeight: 400,
                                marginTop: "17px",
                              }}
                            >
                              {messages[key - 1]?.senderId ==
                                messages[key]?.senderId &&
                              moment(
                                new Date(
                                  messages[key - 1]?.timestamp?.toDate()
                                ).toUTCString()
                              ).format("HH:MM") ===
                                moment(
                                  new Date(
                                    msg?.timestamp?.toDate()
                                  ).toUTCString()
                                )
                                  .tz(userTimeZone)
                                  .format("HH:MM")
                                ? ""
                                : `${candidate.firstName} ${candidate.lastName}`}
                            </p>
                            {/* <p
                          className="inbox-item-author text-amber"
                          style={{
                            display: "flex",
                            justifyContent: "flex-start",
                            fontSize: 12,
                          }}
                        >
                          {moment(
                            new Date(msg?.timestamp?.toDate()).toUTCString()
                          ).format("DD/MM/YY")}{" "}
                          at{" "} */}
                            {/* {moment(
                            new Date(msg?.timestamp?.toDate()).toUTCString()
                          ).format("HH:MM")}
                        </p> */}
                            <div
                              className="box1 received"
                              style={{ marginLeft: 0 }}
                            >
                              <div
                                style={{
                                  margin: "5px",
                                  fontWeight: 400,
                                  fontSize: "14px",
                                }}
                              >
                                {msg.message}
                              </div>
                              <div
                                style={{ fontSize: "11px", paddingLeft: "5px" }}
                              >
                                {moment(
                                  new Date(
                                    msg?.timestamp?.toDate()
                                  ).toUTCString()
                                )
                                  .tz(userTimeZone)
                                  .format("HH:mm")}
                              </div>
                            </div>
                          </>
                        )}
                      </div>
                    </div>
                  </>
                );
              })}
            <div ref={divRef} />
          </div>
        )}
      </div>
    );
  };

  const showModal = () => {
    setVisible(true);
  };

  const handleCancel = () => {
    setmessages([]);
    setVisible(false);
  };
  return (
    <>
      {props.titleType === "button" ? (
        <>
          {props.enableCustomBtnIcon ? (
            <div>
              <div
                onClick={() => {
                  showModal();
                  clickOnNotification(
                    props.record ? props.record._id : props.matchId,
                    "chat"
                  );
                }}
              >
                {props.customBtnIcon}
              </div>
            </div>
          ) : (
            <Button
              className={props.titleBtnClass}
              shape="circle"
              icon={props.btnIcon}
              onClick={showModal}
            >
              {props.title}
            </Button>
          )}
        </>
      ) : props.titleType === "customButton" ? (
        <button className={props.titleBtnClass} onClick={showModal}>
          {props.btnIcon}
        </button>
      ) : (
        <a
          className="text-primary"
          style={{ fontSize: 14 }}
          onClick={showModal}
        >
          {props.title}
        </a>
      )}

      <Drawer
        title={props.headerTitle}
        width={props.width}
        visible={visible}
        style={{ zIndex: "10000" }}
        onClose={() => {
          props.onOK();
          setmessages([]);
          setVisible(false);
        }}
        // onCancel={handleCancel}
        footer={props.footer}
      >
        <div className="row" style={{ height: "100%" }}>
          <div className="col-md-12">
            <div className="chat-box-right">
              {visible == true && renderMessages(props.candidate, props.record)}
            </div>
          </div>
          <div className="chat-footer">
            <div className="row">
              <div
                className="col-12"
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignContent: "center",
                }}
              >
                <form
                  onSubmit={(e) => sendMessage(e)}
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignContent: "center",
                    width: "100%",
                  }}
                  className=""
                >
                  <TextArea
                    value={inputVal}
                    autoSize={{ minRows: 1, maxRows: 2 }}
                    style={{
                      backgroundColor: "#f9f9f9",
                      marginRight: 10,
                      height: "10px",
                      borderColor: "#2E4A79",
                    }}
                    onChange={messageHandler}
                    className="form-control chat-footer-scroll-bar"
                    placeholder="Write your comment"
                  />
                  <button
                    type="submit"
                    className="btn-btn-color"
                    style={{ height: "35px", margin: "auto", fontSize: 14 }}
                  >
                    Send
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </Drawer>
    </>
  );
};

export default ModalComponent;
